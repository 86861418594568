<template>
	<Head title="My Account"></Head>
	<ClientLayout>
		<Breadcrumbs></Breadcrumbs>
		<ImpersonationBanner :impersonating="is_impersonating"></ImpersonationBanner>

		<section id="content-panel" class="my-account py-lg-16 py-10 px-xl-16 px-lg-8 px-4" :class="{impersonating: is_impersonating}">
			<div class="row mb-lg-10 mb-8">
				<div class="col-12">
					<h1 class="h2 text-lg-start text-center mb-0">Your Account</h1>
				</div>
			</div>

			<div class="row mb-lg-12 mb-10">
				<div class="col-12">
					<h4 class="text-secondary fw-extra d-flex align-items-center">
						<i class="fa-duotone fa-user theme-sertus invert-theme me-4 me-lg-6 pb-1"></i>
						Your Info
					</h4>
				</div>
				<div class="col-xxxl-5ths col-xl-4 col-md-6 col-12 mb-4">
					<div class="account-detail">
						<span class="text-primary fw-bold me-2">Name:</span>
						{{ user.name }}
					</div>
				</div>
				<div class="col-xxxl-5ths col-xl-4 col-md-6 col-12 mb-4">
					<div class="account-detail">
						<span class="text-primary fw-bold me-2">Company:</span>
						{{ user.customer }}
					</div>
				</div>
				<div class="col-xxxl-5ths col-xl-4 col-md-6 col-12 mb-4">
					<div class="account-detail">
						<span class="text-primary fw-bold me-2">Email:</span>
						{{ user.email }}
					</div>
				</div>
				<div class="col-xxxl-5ths col-xl-4 col-md-6 col-12 mb-4">
					<div class="account-detail">
						<span class="text-primary fw-bold me-2">Phone Number:</span>
						{{ user.phone }}
					</div>
				</div>
			</div>

			<div class="d-none row mb-lg-12 mb-10 mt-n4">
				<div class="col-12">
					<h4 class="text-secondary fw-extra d-flex align-items-center">
						<i class="fa-duotone fa-briefcase theme-sertus invert-theme me-4 me-lg-6 pb-1"></i>
						Your Credt Account
					</h4>
				</div>

				<div class="col-xxxl-5ths col-xl-4 col-md-6 col-12 mb-4">
					<div class="account-detail">
						<span class="text-primary fw-bold me-2">Status:</span>
						Good
					</div>
				</div>
				<div class="col-xxxl-5ths col-xl-4 col-md-6 col-12 mb-4">
					<div class="account-detail">
						<span class="text-primary fw-bold me-2">Available:</span>
						£XXXXX.xx
					</div>
				</div>
				<div class="col-xxxl-5ths col-xl-4 col-md-6 col-12 mb-4">
					<div class="account-detail">
						<span class="text-primary fw-bold me-2">Account ID:</span>
						XXXXXXXXXXX
					</div>
				</div>
			</div>

			<div class="row mt-n4">
				<div class="col-12">
					<h4 class="text-secondary fw-extra d-flex align-items-center">
						<i class="fa-duotone fa-bullseye-pointer theme-sertus invert-theme me-4 me-lg-6 pb-1"></i>
						Account Actions
					</h4>
				</div>

				<div class="col-md-auto col-12">
					<div class="d-flex flex-md-row flex-column">
						<Link class="d-block flex-fill me-md-4 mb-md-0 mb-4" :href="$route('account.your-orders')">
							<button class="btn-primary text-tertiary w-100">
								<span>
									<i class="fa-duotone fa-boxes-stacked theme-sertus-white invert-theme me-3"></i>
									View Orders
								</span>
							</button>
						</Link>

						<Link class="d-block flex-fill me-md-4 mb-md-0 mb-4" :href="$route('account.your-quotes')">
							<button class="btn-primary text-tertiary w-100">
								<span>
									<i class="fa-duotone fa-envelope theme-sertus-white invert-theme me-3"></i>
									View Quotes
								</span>
							</button>
						</Link>

						<Link class="d-block flex-fill me-md-4 mb-md-0 mb-4" :href="$route('account.addresses.manage')">
							<button class="btn-primary text-tertiary w-100">
								<span>
									<i class="fa-duotone fa-truck theme-sertus-white invert-theme me-3"></i>
									Manage/Add Delivery Addresses
								</span>
							</button>
						</Link>

						<Link class="d-block flex-fill me-md-4 mb-md-0 mb-4">
							<button class="btn-primary text-tertiary w-100">
								<span>
									<i class="fa-duotone fa-rotate theme-sertus-white me-3"></i>
									Reset Password
								</span>
							</button>
						</Link>
					</div>
				</div>
			</div>
		</section>
	</ClientLayout>
</template>

<script setup>
import ClientLayout from '@layouts/ClientLayout.vue';
import Breadcrumbs from '@/Components/Breadcrumbs.vue';
import ImpersonationBanner from "@js/Components/ImpersonationBanner.vue";
import { Head, Link, usePage } from '@inertiajs/vue3';
import { computed, ref } from "vue";

const props= defineProps({
	'user': Object
});
const user = ref(props.user.data);

const is_impersonating = computed(() => usePage().props.global.is_impersonating);
</script>
