import {ref} from 'vue';

export default function useParts() {
	const groups = ref({
		data: [],
		links: {
			first: '',
			last: '',
			next: ''
		},
		meta: {
			current_page: 1,
			from: 1,
			to: 1,
			per_page: 24,
			total: 1
		}
	});
	const currentPage = ref(1);
	const getGroups = async (page) => {
		if(page) {
			currentPage.value = page;
		}

		await axios.get('paginate-groups?page=' + currentPage.value)
		.then(response => {
			groups.value = response.data;
		});
	}

	return {groups, getGroups};
}