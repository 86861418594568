<template>
	<AdminLayout>
		<section id="content-panel" class="py-lg-10 py-8 px-xl-16 px-lg-8 px-4">
			<div class="row mb-md-8 mb-6">
				<div class="col-12">
					<div class="dashboard-title-block">
						<h1 class="h3 text-lg-start text-center mb-lg-0 mb-2">User: <span class="text-secondary">{{ user.name }}</span></h1>
						<p class="text-lg-start text-center ms-lg-auto mb-0">See a user's details and reset their password.</p>
					</div>
				</div>
			</div>

			<div class="row mb-xl-12 mb-lg-10 mb-8">
				<div class="col-12">
					<div class="epicor-details p-6">
						<div class="bottom-divider">
							<div class="row">
								<div class="col-xxl-2 col-xl-3 col-lg-4 col-6 mb-0">
									<h4 class="text-start mb-0">{{ user.name }}</h4>
								</div>
								<div class="col-xxl-2 col-xl-3 col-lg-4 col-6 ms-auto">
									<div class="d-flex align-items-start justify-content-end mx-auto h-100">
										<i class="fa-duotone fa-user-plus theme-sertus me-4"></i>
										<span class="mb-n1">Role: {{ user_role.name }}</span>
									</div>
								</div>
								<div class="d-none col-lg col-md-6 col-12 offset-lg-0 offset-md-6 mt-lg-0 mt-6">
									<div class="d-flex align-items-start">
										<p class="text-xxl-start text-md-end fs-6 ms-lg-auto mb-lg-n1 mb-2">
											These fields are from Epicor and cannot be changed here. They are presented for review purposes only.
										</p>
										<i class="fa-solid fa-lock fs-4 ms-4 pb-2"></i>
									</div>
								</div>
							</div>
						</div>

						<div class="row mt-md-8 mt-6">
							<div class="col-lg-auto col-md-6 col-12 mb-md-0 mb-4">
								<div class="user-detail"><span class="text-primary fw-bold me-2">Email:</span>{{ user.email }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="row mb-xl-16 mb-lg-12 mb-10" v-if="$can('reset user passwords') || $can('delete admins')">
				<div class="col-12">
					<h3 class="h5 text-lg-start text-center mb-4">User Actions:</h3>
				</div>

				<div class="col-12" v-if="$can('reset user passwords')">
					<div class="d-flex flex-md-row flex-column align-items-center">
						<button class="btn-primary text-tertiary d-flex align-items-center me-xl-10 me-lg-8 me-md-6 mb-md-0 mb-4">
							<i class="fa-duotone fa-arrows-rotate theme-sertus-white me-4"></i>
							<span class="mb-n1"> Reset Password </span>
						</button>
						<p class="text-md-start text-center mb-0">Send a password reset link via email to this user.</p>
					</div>
				</div>

				<div class="col-12" v-if="$can('delete admins')">
					<div class="d-flex flex-md-row flex-column align-items-center mt-4">
						<button class="btn-primary text-tertiary d-flex align-items-center me-xl-10 me-lg-8 me-md-6 mb-md-0 mb-4" @click="deleteUser">
							<span>
								<i class="fa-duotone fa-trash theme-sertus-white pb-1 me-4"></i>
								Delete Admin
							</span>
						</button>
						<p class="text-md-start text-center mb-0">Delete this administrator's account from the system!</p>
					</div>
				</div>

				<div class="col-xl-6 col-lg-8 col-md-10 col-12" v-if="$can('change admin roles')">
					<div class="d-flex flex-md-row flex-column align-items-center mt-4">
						<button class="btn-primary text-tertiary d-flex align-items-center me-xl-10 me-lg-8 me-md-6 mb-md-0 mb-4" @click="showRoleForm = true">
							<span>
								<i class="fa-duotone fa-user-pen theme-sertus-white pb-1 me-4"></i>
								Change Role
							</span>
						</button>
						<p class="text-md-start text-center mb-0">Change this user's role to alter their permission levels!</p>
					</div>

					<form class="py-lg-8 py-4" id="role-form" method="POST" @submit.prevent="changeRole" v-if="showRoleForm">
						<h5 class="text-start mb-2">Current role: <span class="text-secondary">{{ user_role.name }}</span></h5>

						<div class="d-flex align-items-end">
							<select name="roles" id="roles" v-model="role_form.role_id">
								<template v-if="allowed_roles.length > 0">
									<option value="" selected disabled>Please select a role ...</option>
									<option v-for="role in allowed_roles" :key="role.id" :value="role.id">
										{{ role.name }}
									</option>
								</template>
								<template v-else>
									<option value="" selected disabled>No roles to show ...</option>
								</template>
							</select>

							<button class="btn-outline-secondary text-secondary ms-4" :class="{'disabled' : allowed_roles.length === 0}">
								<span>Save</span>
							</button>
						</div>
						<InputError :message="role_form.errors.role_id" class="text-secondary mt-2"></InputError>
						<p v-if="allowed_roles.length === 0" class="text-quaternary mt-2 mb-0">There are no roles visible to you since there are no roles currently below your permission tier!</p>
						<div class="d-flex justify-content-end mt-2">
							<button class="btn-outline-primary text-primary" type="button" @click="cancelRoleChange">
								<span>Cancel</span>
							</button>
						</div>
					</form>
				</div>

				<div class="col-xl-8 col-md-10 col-12" v-if="$can('edit admin details')">
					<div class="d-flex flex-md-row flex-column align-items-center mt-4">
						<button class="btn-primary text-tertiary d-flex align-items-center me-xl-10 me-lg-8 me-md-6 mb-md-0 mb-4" @click="showEditForm = true">
							<span>
								<i class="fa-duotone fa-pen theme-sertus-white pb-1 me-4"></i>
								Edit Details
							</span>
						</button>
						<p class="text-md-start text-center mb-0">Change this user's name or email!</p>
					</div>

					<form class="py-lg-8 py-4" id="edit-form" method="POST" @submit.prevent="changeDetails" v-if="showEditForm">
						<div class="row justify-content-end">
							<div class="col-md-6 col-12">
								<label for="email" class="text-secondary fw-bold mb-2">Email</label>
								<TextInput name="email" id="email" type="text" :placeholder-value="edit_form.email" v-model="edit_form.email"></TextInput>
								<InputError class="mt-2" :message="edit_form.errors.email"></InputError>
							</div>
							<div class="col-md-6 col-12">
								<label for="name" class="text-secondary fw-bold mb-2">Name</label>
								<TextInput name="name" id="name" type="text" :placeholder-value="edit_form.name" v-model="edit_form.name"></TextInput>
								<InputError class="mt-2" :message="edit_form.errors.name"></InputError>
							</div>

							<div class="col-auto mt-4">
								<button class="btn-outline-primary text-primary" type="button" @click="cancelEdit">
									<span>Cancel</span>
								</button>

								<button class="btn-outline-secondary text-secondary ms-4">
									<span>Save</span>
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<div class="d-flex">
						<Link v-bind:href="$route('admin.all-users')">
							<button class="btn-underline-secondary d-flex align-items-center py-0">
								<i class="fa-regular fa-chevron-left text-primary pb-0 me-4"></i>
								<span>Back to users list</span>
							</button>
						</Link>
					</div>
				</div>
			</div>
		</section>
	</AdminLayout>
</template>

<script setup>
import AdminLayout from '@layouts/AdminLayout.vue';
import { Link, router, useForm } from '@inertiajs/vue3';
import { useStore } from "vuex";
import { computed, onMounted, ref } from "vue";
import InputError from "@js/Components/InputError.vue";
import TextInput from "@js/Components/TextInput.vue";

const store = useStore();
const props = defineProps({
	'user' : Object,
	'user_role' : Object,
	'roles' : Object
});

const showRoleForm = ref(false);
const showEditForm = ref(false);
const role_form = useForm({
	'user_id' : props.user.id,
	'role_id' : ""
});
const edit_form = useForm({
	'user_id' : props.user.id,
	'name' : props.user.name,
	'email' : props.user.email
})

const allowed_roles = computed(() => props.roles.data.filter(role => {
	return role.tier !== props.user_role.tier
}));

const deleteUser = () => {
	let userId = props.user.id;
	console.log(userId);

	Swal.fire({
		icon: 'question',
		title: 'Are you sure?',
		text: 'Are you sure you would like to delete this admin user?',
		showConfirmButton: true,
		showDenyButton: true,
		confirmButtonText: 'Delete',
		denyButtonText: 'Cancel'
	})
	.then(response => {
		if(response.isConfirmed) {
			Swal.fire({
				icon: 'info',
				title: 'Deleting Admin User',
				text: 'Please wait whilst we delete this user for you ...',
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
				showConfirmButton: false
			});

			router.delete(route('admin.delete-admin', {'user' : userId}), {
				onSuccess: () => {
					Swal.close();
					window.Toast.fire({
						icon: 'success',
						title: 'Admin user archived!',
						timer: 3000
					});
				},
				onError: error => {
					Swal.close();
					if(error.response.status === 403) {
						window.Toast.fire({
							icon: 'error',
							title: 'User not deleted!',
							text: 'You are not authorized to perform that action.',
							timer: 3000
						});
					}
					else {
						window.Toast.fire({
							icon: 'error',
							title: 'User not deleted!',
							timer: 3000
						});
					}
				}
			})
		}
	});
}

const changeRole = () => {
	Swal.fire({
		icon: 'info',
		title: 'Changing role ...',
		text: 'Please wait whilst we change this user\'s role for you ...',
		allowOutsideClick: false,
		allowEscapeKey: false,
		allowEnterKey: false,
		showConfirmButton: false
	})
	role_form.post(route('admin.change-admin-role'), {
		onSuccess: () => {
			Swal.close();
			window.Toast.fire({
				icon: 'success',
				title: 'Role updated!',
				timer: 3000
			});
			role_form.reset();
			showRoleForm.value = false;
		},
		onError: error => {
			Swal.close();
			window.Toast.fire({
				icon: 'error',
				title: 'Role update failed!',
				timer: 3000
			});
		}
	});
}

const changeDetails = () => {
	Swal.fire({
		icon: 'info',
		title: 'Updating details ...',
		text: 'Please wait whilst we change this user\'s details ...',
		allowOutsideClick: false,
		allowEscapeKey: false,
		allowEnterKey: false,
		showConfirmButton: false
	})
	edit_form.post(route('admin.change-admin-details'), {
		onSuccess: () => {
			Swal.close();
			window.Toast.fire({
				icon: 'success',
				title: 'Details updated!',
				timer: 3000
			});
			edit_form.reset();
			showEditForm.value = false;
		},
		onError: error => {
			console.log(error);
			Swal.close();
			window.Toast.fire({
				icon: 'error',
				title: 'Details update failed!',
				timer: 3000
			});
		}
	});
}

const cancelEdit = () => {
	edit_form.reset();
	showEditForm.value = false;
	return;
}

const cancelRoleChange = () => {
	role_form.reset();
	showRoleForm.value = false;
	return;
}
</script>
