<template>
	<section id="order-summary-container">
		<div id="order-summary">
			<div class="row gx-0 checkout-row py-6" v-for="item in data.items">
				<div class="col-3 col-md-2 col-xl-1">
					<Thumbnail :images="item.images" basket></Thumbnail>
				</div>
				<div class="col-9 col-md-10 col-lg-6 col-xl-7">
					<div class="d-flex flex-column justify-content-lg-between justify-content-center h-100 ms-lg-6 ms-4">
						<h2 class="h5 text-start text-primary fw-normal mb-2">{{ item.name }}</h2>
						<h3 class="h6 text start text-secondary text-uppercase mb-md-0 mb-2">{{ item.prod_code }}</h3>

						<div class="d-lg-none d-flex flex-column">
							<div class="d-flex align-items-center justify-content-end mb-2">
								<h5 class="mb-0">Qty : <span class="text-secondary ms-2">{{ item.quantity }}</span></h5>
							</div>
							<div class="d-flex align-items-center">
								<Price :item="item"></Price>
								<span class="tax-label ms-2 mb-n1">ExVAT</span>
							</div>
						</div>
					</div>
				</div>
				<div class="d-lg-block d-none col-4">
					<div class="d-flex flex-column align-items-end justify-content-lg-between justify-content-center h-100">
						<div class="d-flex align-items-center mb-2">
							<p class="text-lg-large fw-bold mb-n1">QTY: <span class="text-secondary ms-2">{{ item.quantity }}</span></p>
						</div>
						<div class="d-flex align-items-center mb-1">
							<Price :item="item" :show-discount="current_customer.show_discount"></Price>
							<span class="tax-label ms-2 mb-n1">ExVAT</span>
						</div>

						<ul class="labels d-none">
							<li class="label">User defined label</li>
						</ul>
					</div>
				</div>
			</div>
			<template v-if="data.charges !== undefined">
				<div class="row gx-0 checkout-row py-6" v-for="charge in data.charges">
					<div class="col-3 col-md-2 col-xl-1">
						<div class="img-block misc-charge">
							<div class="bg-square"></div>
						</div>
					</div>
					<div class="col-9 col-md-10 col-lg-6 col-xl-7">
						<div class="d-flex flex-column justify-content-lg-between justify-content-center h-100 ms-lg-6 ms-4">
							<h2 class="h5 text-start text-primary fw-normal mb-2">{{ charge.charge_description }}</h2>

							<div class="d-lg-none d-flex flex-column">
								<div class="d-flex align-items-center justify-content-end mb-2">
									<h5 class="mb-0">Qty : <span class="text-secondary ms-2">1</span></h5>
								</div>
								<div class="d-flex align-items-center">
									<div class="item-price fw-bold text-primary mb-n1 ms-auto">
										<span class="h6 mb-0">£{{ charge.charge_amt }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="d-lg-block d-none col-4">
						<div class="d-flex flex-column align-items-end justify-content-lg-between justify-content-center h-100">
							<div class="d-flex align-items-center mb-2">
								<p class="text-lg-large fw-bold mb-n1">QTY: <span class="text-secondary ms-2">1</span></p>
							</div>
							<div class="d-flex align-items-center mb-1">
								<div class="item-price fw-bold text-primary mb-n1 ms-auto">
									<span class="h6 mb-0">£{{ charge.charge_amt }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
		</div>
		<div id="order-total" class="row justify-content-end mt-lg-8 mt-6">
			<div class="col-auto">
				<h4 class="text-end text-primary mb-0">Total Balance: <span class="text-secondary ms-4">£{{ data.total }}</span></h4>
			</div>
		</div>
	</section>
</template>

<script setup>
import Price from "@js/Components/Price.vue";
import { computed, ref } from 'vue';
import Thumbnail from "@js/Components/Thumbnail.vue";
import { usePage } from "@inertiajs/vue3";

const props = defineProps({
	'data' : Object
});

const current_customer = computed(() => usePage().props.global.current_customer.data );
const data = ref(props.data);
</script>
