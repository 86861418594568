<template>
	<Head :title="title"></Head>
	<section id="error-page" style="background-image: url('/storage/images/Login hero image.jpg')">
		<header class="bg-primary py-4">
			<div class="container">
				<div class="row">
					<div class="col-6">
						<div class="logo">
							<img src="/storage/images/sertus-logo-white.svg" alt="White Sertus Logo" />
						</div>
					</div>
					<div class="col-6">
						<div class="d-flex align-items-center justify-content-end h-100">
							<Link @click="back">
								<button class="btn-outline-tertiary flip-icon fs-5">
									<span>Back to last page</span>
								</button>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</header>
		<section class="content-section py-xl-25 py-lg-29 py-md-16 py-12">
			<div class="container h-100">
				<div class="row align-items-center justify-content-center h-100">
					<div class="col-xxl-6 col-lg-8 col-md-10 col-12">
						<div class="content-box bg-tertiary py-xl-16 py-md-12 py-10 px-xl-12 px-md-10 px-8">
							<h1 class="fw-extra text-center text-primary mb-2">
								<span class="text-secondary">{{ status + ':' }}</span>
								{{ title }}
							</h1>
							<h3 class="fw-normal text-center text-primary mb-4">{{ description }}</h3>
							<div class="wysiwyg text-center text-primary fw-normal">
								<h5 class="border border-1 border-secondary p-2" v-if="message !== ''">{{ message }}</h5>
								<p>Please navigate back to the site using either of the links below:</p>
							</div>
							<div class="d-flex flex-lg-row flex-column align-items-center justify-content-center mt-4">
								<Link :href="$route('welcome')">
									<button class="btn-outline-secondary text-secondary">
										<span>Return to 'Welcome' page</span>
									</button>
								</Link>

								<Link :href="$route('catalogue')" class="ms-lg-4 mt-lg-0 mt-4">
									<button class="btn-outline-quaternary">
										<span>Browse the catalogue</span>
									</button>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</section>
</template>

<script setup>
import { Head, Link } from '@inertiajs/vue3';
import { computed } from 'vue';

const props = defineProps({
	status: Number,
	message: String,
});

const title = computed(() => {
	return {
		503: 'Service Unavailable',
		500: 'Server Error',
		404: 'Page Not Found',
		403: 'Forbidden',
	}[props.status];
});
const description = computed(() => {
	return {
		503: 'Sorry, we are doing some maintenance. Please check back soon!',
		500: 'Whoops, something went wrong on our servers!',
		404: 'Sorry, the page you are looking for could not be found!',
		403: 'Sorry, you are forbidden from accessing this page!',
	}[props.status];
});

const back = () => {
	window.history.back();
};
</script>
