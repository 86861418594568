<template>
	<Head title="Full Basket"></Head>
	<section id="client-portal">
		<Navbar></Navbar>
		<Breadcrumbs></Breadcrumbs>

		<section id="content-panel" class="full-basket-view py-lg-16 py-10 px-xl-16 px-lg-8 px-4">
			<div class="row mb-4">
				<div class="col-12">
					<div class="d-flex flex-md-row flex-column align-items-center">
						<template v-if="editName">
							<form class="d-flex align-items-center justify-content-lg-start justify-content-center" id="basket-name-form" @submit.prevent="updateBasketName" method="PUT">
								<TextInput class="mb-lg-0 mb-4" type="text" :placeholder-value="basketName" v-model="editableBasketName" tabindex="1"></TextInput>
								<button class="btn-outline-primary text-primary cancel ms-lg-4 ms-0 me-2" @click="cancelNameUpdate" tabindex="2">
									<span>Cancel</span>
								</button>

								<button class="btn-outline-quaternary text-quaternary confirm ms-2" tabindex="3">
									<span>Confirm</span>
								</button>
							</form>
						</template>
						<template v-else>
							<h1 class="h2 text-lg-start text-center d-flex align-items-center justify-content-lg-start justify-content-center mb-md-0 mb-4">
								<span class="mb-n1"> {{ basketName }} </span>
								<i class="fa-duotone fa-pen-line theme-sertus fs-2 ms-xl-8 ms-md-6 ms-4"></i>
							</h1>

							<button id="basket-name-edit" class="btn-outline-secondary text-secondary ms-xl-10 ms-lg-8 ms-md-6" @click="editNameStart">
								<span>Change your project's name</span>
							</button>
						</template>

						<button id="clear-project" class="btn-outline-primary ms-md-auto mt-md-0 mt-4" @click="clearProject" :class="{'disabled' : basketCount === 0}">
							<span>
								<i class="fa-duotone fa-cart-minus theme-sertus mb-n1 me-2"></i>
								Clear this project
							</span>
						</button>
					</div>
				</div>
			</div>

			<div class="row align-items-center mt-xxxl-20 mt-xxl-16 mt-lg-12 mt-8" v-if="basketCount === 0">
				<div class="col-auto mx-auto">
					<div class="d-flex flex-column align-items-center justify-content-center px-8 py-md-12 py-10">
						<img class="trolley-icon mb-lg-12 mb-md-10 mb-8" src="/storage/images/trolley.svg" alt="Trolley icon" />
						<h3 class="text-center mb-lg-4 mb-2">Your project is empty</h3>
						<h5 class="text-center text-secondary">
							Add products from the
							<Link :href="route('catalogue')" class="btn-underline-primary d-inline">
								<span>catalogue</span>
							</Link>
							to get started
						</h5>
					</div>
				</div>
			</div>
			<div class="row" v-else>
				<div class="col-12">
					<div id="full-basket-rows" class="mb-n8">
						<div class="row basket-row pt-6 pb-8" v-for="item in basketItems">
							<div class="col-6 col-md-2 col-xxl-1">
								<div class="img-block">
									<div class="bg-square bg-secondary" style="background-image: url('/storage/images/roof-hatch.jpg')"></div>
								</div>
							</div>
							<div class="col-6 col-xxl-5">
								<div class="d-flex flex-column justify-content-xl-center justify-content-start border-right h-100">
									<h2 class="h5 text-start text-primary mb-2 mt-xl-0 mt-2">{{ item.name}}</h2>
									<div class="d-flex align-items-center mb-md-0 mb-6">
										<h3 class="h6 text-start text-secondary text-uppercase fw-normal mb-0">{{ item.part_num }}</h3>
										<h3 class="h6 text start text-secondary text-uppercase fw-normal ps-2 mb-0">- {{ item.prod_code }}</h3>
									</div>

									<div class="d-md-none d-flex flex-column flex-xxl-row align-items-start align-items-xxl-center justify-content-between h-100">
										<button class="btn-underline-senary mb-xxl-0 mb-4 py-0" @click="removeBasketItem(item)">
											<i class="fa-duotone fa-trash theme-sertus me-2"></i>
											<span>Remove</span>
										</button>

										<div class="d-flex align-items-center justify-content-end">
											<div class="d-flex align-items-center">
												<button class="qty-control down" @click="reduceQty(item)"></button>
												<span class="d-inline-block mx-3 mb-n1 item-qty">{{ item.quantity }}</span>
												<button class="qty-control up" @click="increaseQty(item)"></button>
											</div>
										</div>

										<div class="d-flex align-items-center">
											<Price :item="item"></Price>
											<span class="tax-label ms-2 mb-n1">ExVAT</span>
										</div>
									</div>
								</div>
							</div>
							<div class="col-4 col-xxl-6 d-md-block d-none">
								<div class="d-flex flex-column flex-xxl-row align-items-start align-items-xxl-center justify-content-between h-100">
									<button class="btn-underline-senary mb-xxl-0 mb-4 py-0" @click="removeBasketItem(item)">
										<i class="fa-duotone fa-trash theme-sertus me-2"></i>
										<span>Remove</span>
									</button>

									<div class="d-flex align-items-center justify-content-end">
										<div class="d-flex align-items-center">
											<button class="qty-control down" @click="reduceQty(item)"></button>
											<span class="d-inline-block mx-3 mb-n1 item-qty">{{ item.quantity }}</span>
											<button class="qty-control up" @click="increaseQty(item)"></button>
										</div>
									</div>

									<div class="d-flex align-items-center">
										<Price :item="item"></Price>
										<span class="tax-label ms-2 mb-n1">ExVAT</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<section class="basket-offset"></section>

			<section id="full-basket-actions">
				<div class="row gx-0">
					<div class="col-12">
						<div class="d-flex flex-md-row flex-column justify-content-md-end">
							<div
								class="d-flex align-items-center justify-content-end flex-fill border-top border-primary border-1 p-4 py-md-0 ps-md-0 pe-lg-12 pe-md-10"
							>
								<div class="d-flex align-items-center justify-content-end ps-4">
									<span class="basket-count">
										<span>{{ basketCount }}</span>
									</span>
									<span class="text-end ms-3 mb-n1">
										<span class="d-lg-inline d-none">Total items</span>
										<span class="d-lg-none d-inline-block">Items</span> in basket
									</span>
								</div>

								<h3 class="text-senary text-center fw-normal mb-n1 ms-lg-12 ms-md-10 mx-6">Total:</h3>

								<span class="tax-label me-2 mb-n1">ExVAT</span>
								<h3 class="item-price text-primary mb-n1">
									<span class="h3 mb-0">£{{ calcPrice(total).pounds }}.</span>
									<span>{{ calcPrice(total).pence }}</span>
								</h3>
							</div>
							<div class="d-flex align-items-center">
								<button class="btn-primary text-tertiary hover-primary flex-fill pt-4 pb-2" :class="{'disabled' : basketCount === 0}" @click="newQuote">
									<span>Request Quote</span>
								</button>
								<button class="btn-secondary text-tertiary hover-secondary flex-fill pt-4 pb-2" :class="{'disabled' : basketCount === 0}" @click="checkout">
									<span>Place Order</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</section>
		</section>
	</section>
</template>

<script setup>
import Navbar from '@/Components/Navbar.vue';
import Breadcrumbs from '@/Components/Breadcrumbs.vue';
import TextInput from "@js/Components/TextInput.vue";
import Price from "@js/Components/Price.vue";
import { Head, Link } from '@inertiajs/vue3';
import { ref } from "vue";
import { useStore } from "vuex";
import { useBasket } from "@js/Composables/basket";
import { usePrice } from "@js/Composables/pricing";

/** ============ */
/** Composables  */
/** ============ */
const store = useStore();
const {
	basketName,
	basketItems,
	basketCount,
	total,
	checkout,
	clearProject,
	increaseQty,
	newQuote,
	reduceQty,
	refreshBasket,
	removeBasketItem
} = useBasket();
const {
	calcPrice
} = usePrice();

/** ===== */
/** Refs  */
/** ===== */
const editableBasketName = ref("");
const editName = ref(false);

/** ======= */
/** Methods */
/** ======= */
const editNameStart = () => {
	editableBasketName.value = basketName.value;
	editName.value = true;
}

const cancelNameUpdate = () => {
	editableBasketName.value = basketName.value;
	editName.value = false;
}

const updateBasketName = () => {
	if(editableBasketName.value === basketName.value) {
		Swal.fire({
			icon: 'info',
			title: 'No Changes Made',
			text: 'Your new project name matches the old one. Please make some changes before updatign the name of the Basket Project',
			confirmButtonText: 'Okay'
		});

		return;
	}

	let basketId = store.getters["basket/basketId"]; // get the basket id from the basket store
	Swal.fire({
		icon: 'info',
		title: 'Updating Basket Project Name',
		text: 'Please wait whilst we update the name of your basket project',
		showConfirmButton: false,
		showDenyButton: false,
		allowEscapeKey: false,
		allowEnterKey: false,
		allowOutsideClick: false
	});

	axios.put(route('basket.change-name'), {
		basket_id: basketId,
		new_name: editableBasketName.value,
	})
	.then(response => response.data)
	.then(data => {
		store.dispatch('basket/changeName', data.name);
		Swal.close();
		editName.value = false;

		setTimeout(() => {
			window.Toast.fire({
				icon: 'success',
				title: 'Basket project name updated'
			});
		});
	})
	.catch(error => {
		Swal.close();
		Swal.fire({
			icon: 'error',
			title: 'Name change failed',
			text: 'We were unable to change the name of your basket. Please try again and reach out to tech support if the issue persists.',
			confirmButtonText: 'Okay'
		});
	});
}

/** ============ */
/** Method Calls */
/** ============ */
refreshBasket();
</script>
