<template>
	<Head title="Order Details"></Head>
	<section id="client-portal">
		<Navbar></Navbar>
		<Breadcrumbs></Breadcrumbs>
		<ImpersonationBanner :impersonating="is_impersonating"></ImpersonationBanner>

		<section id="content-panel" class="py-lg-16 py-10 px-xl-16 px-lg-8 px-4" :class="{impersonating: is_impersonating}">
			<div class="row justify-content-center">
				<div class="col-lg-9 col-md-10 col-12 d-xxl-none mb-md-10 mb-8">
					<h1 class="h2 text-lg-start text-tenter mb-0">Order <span class="text-secondary">#{{ order.sertus_order_num }}</span></h1>
				</div>
				<div class="col-xxxl-6 col-xxl-4 col-lg-9 col-md-10 col-12 mb-xxl-0 mb-12">
					<h1 class="h2 d-none d-xxl-block text-xl-start text-center mb-xl-12 mb-md-10 mb-8">
						Order <span class="text-secondary">#{{ order.sertus_order_num }}</span>
					</h1>

					<div class="d-flex flex-xxxl-row flex-xxl-column flex-lg-row flex-column align-items-start">
						<div class="address-container mb-xxxl-0 mb-xxl-6 mb-lg-0 mb-md-6 mb-4">
							<h4 class="address-lbl text-secondary oneline">Delivery Address:</h4>
							<div class="address wysiwyg fs-5 text-start ms-2">
								<p v-html="order.delivery_formatted"></p>
							</div>
						</div>

						<div class="address-container ms-xxxl-6 ms-xxl-0 ms-lg-6">
							<h4  class="address-lbl text-secondary oneline">Billing Address:</h4>
							<div class="address wysiwyg fs-5 text-start ms-2">
								<p v-html="order.billing_formatted"></p>
							</div>
						</div>
					</div>

					<div class="d-xxl-block d-none mt-lg-12 mt-md-10 mt-8">
						<div class="d-flex justify-content-lg-start justify-content-center">
							<Link :href="$route('account.your-orders')">
								<button class="btn-underline-secondary">
									<i class="fa-regular fa-chevron-left text-primary me-2 mb-n1"></i>
									<span>Back to Order list</span>
								</button>
							</Link>
						</div>
					</div>
				</div>

				<div class="col-xxxl-6 col-xxl-8 col-lg-9 col-md-10 col-12">
					<div class="border border-primary px-6 py-lg-12 py-md-10 py-8">
						<div class="d-flex align-items-center mb-xl-10 mb-lg-8 mb-6 w-100">
							<h3 class="d-flex align-items-center justify-content-lg-start justify-content-center text-lg-start text-center mb-0">
								<i class="fa-duotone fa-list theme-sertus theme-invert fs-2 me-lg-6 me-4 pb-1"></i>
								Your Order Details
							</h3>

							<span class="h3 text-senary fw-normal ms-auto mb-0">{{ order.project_name }}</span>
						</div>

						<LineItems :data="summary"></LineItems>
					</div>
				</div>

				<div class="d-xxl-none d-block col-lg-9 col-md-10 col-12 mt-md-8 mt-6">
					<div class="d-flex justify-content-lg-start justify-content-center">
						<Link :href="$route('account.your-orders')">
							<button class="btn-underline-secondary">
								<i class="fa-regular fa-chevron-left text-primary me-2 mb-n1"></i>
								<span>Back to Order list</span>
							</button>
						</Link>
					</div>
				</div>
			</div>
		</section>
	</section>
</template>

<script setup>
import Breadcrumbs from '@/Components/Breadcrumbs.vue';
import Navbar from '@/Components/Navbar.vue';
import LineItems from '@/Components/LineItemsSummary.vue';
import ImpersonationBanner from "@js/Components/ImpersonationBanner.vue";
import { Head, Link, usePage } from '@inertiajs/vue3';
import { computed, ref } from 'vue';

const props = defineProps({
	'order': Object
});
const order = ref(props.order.data);
console.log(order.value);
const summary = ref({
	'items': order.value.items,
	'charges': order.value.charges,
	'total': order.value.total
});
console.log(summary);
const is_impersonating = computed(() => usePage().props.global.is_impersonating);
</script>
