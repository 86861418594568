<template>
	<header id="client-header" class="header bg-primary">
		<div id="account-dd-close" :class="{ active: dropdownToggle }" @click="toggleDropdown"></div>

		<div class="header-inner py-4 px-xl-16 px-lg-8 px-4">
			<div class="row align-items-center">
				<div class="col-xl-8 col-6">
					<div class="d-flex align-items-center">
						<Link :href="$route('welcome')" class="d-block logo me-xxl-10 me-lg-8 me-4">
							<img src="/storage/images/sertus-logo-white.svg" alt="White Sertus Logo" />
						</Link>

						<h4 class="text-tertiary mb-n1 me-xxl-10 me-xl-8 d-xxl-block d-none">Portal</h4>
						<form @submit.prevent="search" class="search-wrapper d-lg-flex d-none me-xxl-10 me-lg-8 me-6">
							<input type="text" class="global-search" placeholder="Search" v-model="searchForm.query"/>
							<i class="fa-duotone fa-magnifying-glass theme-sertus-white"></i>
						</form>
						<Link :href="$route('catalogue')">
							<button class="btn-outline-tertiary d-lg-block d-none fs-5">
								<span class="d-xxl-inline-block d-none">Browse product catalogue</span>
								<span class="d-xxl-none d-inline-block">Browse</span>
							</button>
						</Link>
					</div>
				</div>

				<div class="col-xl-4 col-6">
					<div class="d-flex align-items-center justify-content-end w-100">
						<button class="d-lg-none d-block me-6" id="search-button" @click="toggleSearchModal">
							<i class="fa-duotone fa-magnifying-glass theme-sertus-white"></i>
						</button>

						<div class="account-button-container me-xxl-25 me-xxl-16 me-lg-12 me-6">
							<Link :href="$route('account.overview')" class="d-block me-4">
								<button class="btn-underline-tertiary">
									<i class="fa-duotone fa-user-shield theme-sertus-white me-3"></i>
									<span class="d-md-inline-block d-none">Your Account</span>
								</button>
							</Link>

							<button class="account-dropdown-button" :class="{ active: dropdownToggle }" @click="toggleDropdown"></button>

							<div class="account-dropdown" :class="{ active: dropdownToggle }">
								<ul class="nav" id="dropdown-navigation">
									<li>
										<Link :href="$route('account.addresses.manage')">Manage Addresses</Link>
									</li>
									<li>
										<Link :href="$route('account.your-orders')">Your Orders</Link>
									</li>
									<li>
										<Link :href="$route('account.your-quotes')">Your Quotes</Link>
									</li>
									<li>
										<form @submit.prevent="logout">
											<button type="submit">Logout</button>
										</form>
									</li>
								</ul>
							</div>
						</div>

						<button class="btn-underline-tertiary" @click="toggleCallbackRequest">
							<i class="fa-duotone fa-phone-arrow-down-left theme-sertus-white me-3"></i>
							<span class="d-md-inline-block d-none">Request a callback</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</header>

	<SearchModal :showModal="showSearchModal" @close-search-modal="toggleSearchModal"></SearchModal>
	<CallbackModal :showModal="showCallbackRequest" @close-callback-request="toggleCallbackRequest"></CallbackModal>
</template>

<script setup>
import CallbackModal from '@/Components/CallbackModal.vue';
import SearchModal from '@/Components/SearchModal.vue';
import { Link, useForm } from '@inertiajs/vue3';
import { computed, ref } from 'vue';
import { router } from '@inertiajs/vue3';

const searchForm = useForm({
	query: ''
});

const showSearchModal = ref(false);

const toggleSearchModal = () => {
	showSearchModal.value = !showSearchModal.value;
};

const showCallbackRequest = ref(false);
const toggleCallbackRequest = () => {
	showCallbackRequest.value = !showCallbackRequest.value;
};

const dropdownToggle = ref(false);
const toggleDropdown = () => {
	dropdownToggle.value = !dropdownToggle.value;
};

const search = () => {
	console.log(searchForm.query);
	searchForm.post(route('search-parts'), {
		onFinish: () => searchForm.reset(),
		onError: error => {
			console.log('error');
			console.log(error);
			window.Toast.fire({
				icon: 'error',
				title: error.message,
				text: error.response.data.message,
				timer: 4500
			});
		}
	})
	// axios.post(route('search-parts'), {
	// 	'query': searchForm.query
	// })
	// .then(response => response.data)
	// .then(data => {
	// 	router.get(route('bom-product', {'id' : data.group_id}));
	// })
	// .catch(error => {
	// 	window.Toast.fire({
	// 		icon: 'error',
	// 		title: error.message,
	// 		text: error.response.data.message,
	// 		timer: 4500
	// 	});
	// });
}

const logout = () => {
	router.post(route('logout'));
};
</script>
