<template>
	<Head title="Roles"></Head>
	<section class="py-12">
		<div class="container">
			<div class="row justify-content-center mb-12">
				<div class="col-12 mb-8">
					<h1 class="h2 text-center text-secondary mb-0">Roles</h1>
				</div>
				<div class="col-md-6 col-12">
					<form @submit.prevent="create_role" class="d-flex flex-column">
						<input class="mb-4" type="text" placeholder="Role name" v-model="roles.name" />
						<input class="mb-4" type="text" placeholder="Guard name" v-model="roles.guard_name" />
						<input type="submit" value="Create" class="btn-secondary text-tertiary" />
					</form>
				</div>
			</div>

			<div class="row justify-content-center">
				<div class="col-md-6 col-12">
					<form @submit.prevent="create_permission" class="d-flex flex-column">
						<input class="mb-4" type="text" placeholder="Permission name" v-model="permissions.name" />
						<input class="mb-4" type="text" placeholder="Guard name" v-model="permissions.guard_name" />
						<input type="submit" value="Create" class="btn-secondary text-tertiary" />
					</form>
				</div>
			</div>
		</div>
	</section>
</template>

<script setup>
import { Head, useForm } from '@inertiajs/vue3';

const roles = useForm({
	name: '',
	guard_name: 'admin',
});
const permissions = useForm({
	name: '',
	guard_name: 'admin',
});

const create_role = () => {
	roles.post(route('admin.create-role'), {
		onError: () => roles.reset('name'),
		onFinish: () => roles.reset('name'),
		onSuccess: () => roles.reset('name'),
	});
};
const create_permission = () => {
	console.log(permissions.name);
	permissions.post(route('admin.create-permission'), {
		onError: () => permissions.reset('name'),
		onFinish: () => permissions.reset('name'),
		onSuccess: () => permissions.reset('name'),
	});
};
</script>
