<template>
	<AdminLayout>
		<section id="content-panel" class="py-lg-10 py-8 px-xl-16 px-lg-8 px-4">
			<div class="row mb-md-8 mb-6">
				<div class="col-12">
					<div class="dashboard-title-block">
						<h1 class="h3 text-lg-start text-center mb-0">
							Order #:
							<span class="text-secondary">{{ order.data.sertus_order_num }}</span>
						</h1>
					</div>
				</div>
			</div>

			<div class="row mb-lg-8 mb-6 mx-lg-n8" id="order-details">
				<div class="col-lg-auto col-md-6 col-12 flex-fill px-lg-8 mb-2">
					<div class="d-flex flex-fill align-items-center">
						<i class="fa-duotone fa-user theme-sertus me-2 pb-1"></i>
						<span class="fw-bold">{{ order.data.customer_name }}</span>
					</div>
				</div>

				<div class="d-lg-none offset-md-6"></div>

				<div class="col-lg-auto col-md-6 col-12 flex-fill px-lg-8 mb-2">
					<div class="d-flex flex-fill align-items-center">
						<span class="fw-bold me-2">Date:</span>
						<span class="text-senary text-lg-start text-end ms-auto">{{ order.data.order_date }}</span>
					</div>
				</div>

				<div class="col-lg-auto col-md-6 col-12 flex-fill px-lg-8 mb-2">
					<div class="d-flex flex-fill align-items-center">
						<span class="fw-bold me-2">Value:</span>
						<span class="text-senary text-lg-start text-end ms-auto">£{{ order.data.total }}</span>
					</div>
				</div>

				<div class="col-lg-auto col-md-6 col-12 flex-fill px-lg-8 mb-2">
					<div class="d-flex flex-fill align-items-center">
						<span class="fw-bold me-2">Paid by:</span>
						<span class="text-senary text-lg-start text-end ms-auto" v-if="order.data.payment_method === 'credit'">Account Credit</span>
						<span class="text-senary text-lg-start text-end ms-auto" v-else>Creadit/Debit Card</span>
					</div>
				</div>

				<div class="col-lg-auto col-md-6 col-12 flex-fill px-lg-8 mb-2">
					<div class="d-flex flex-fill align-items-lg-center align-items-start">
						<span class="ws-nowrap fw-bold me-2">Delivery Address:</span>
						<span class="text-senary text-lg-start text-end ms-auto">13 Building Site Lane, BD18 6AD</span>
					</div>
				</div>
			</div>

			<div class="row mb-6">
				<div class="col-12">
					<h3 class="h5 text-lg-start text-center text-secondary fw-extra mb-0">Order Items</h3>
				</div>
			</div>

			<div class="row">
				<div class="col-12 mb-lg-8 mb-6">
					<div class="table-container border-0 d-flex justify-content-center">
						<table id="order-items-table">
							<tbody>
								<tr v-for="line in order.data.line_items">
									<td>
										<div class="d-flex align-items-center mb-n1">
											<span clasS="title">{{ line.part_desc }}</span>
										</div>

										<div class="d-md-none d-flex align-items-center mb-n1 mt-2">
											<span class="me-2">SKU:</span>
											<span class="text-secondary fw-bold ms-auto me-xxxl-4">{{ line.part_num }} | {{ line.prod_code }}</span>
										</div>
									</td>

									<td>
										<div class="d-flex align-items-center mb-n1">
											<span class="me-2">SKU:</span>
											<span class="text-secondary fw-bold ms-auto me-xxxl-4">{{ line.part_num }} | {{ line.prod_code }}</span>
										</div>
									</td>

									<td>
										<div class="d-flex align-items-md-center align-items-end mb-n1">
											<span class="me-2">Price</span>
											<span class="text-secondary fw-bold ms-auto">£{{ line.total_price }} <span class="text-primary fw-light fs-6">({{ line.order_quantity }})</span></span>
										</div>
									</td>
								</tr>

								<tr v-for="charge in order.data.charges">
									<td>
										<div class="d-flex align-items-center mb-n1">
											<span clasS="title">{{ charge.charge_description }}</span>
										</div>

										<div class="d-md-none d-flex align-items-center mb-n1 mt-2">
											<span class="me-2">Charge Code:</span>
											<span class="text-secondary fw-bold ms-auto me-xxxl-4">{{ charge.charge_code }}</span>
										</div>
									</td>

									<td>
										<div class="d-flex align-items-center mb-n1">
											<span class="me-2">Charge Code:</span>
											<span class="text-secondary fw-bold ms-auto me-xxxl-4">{{ charge.charge_code }}</span>
										</div>
									</td>

									<td>
										<div class="d-flex align-items-md-center align-items-end mb-n1">
											<span class="me-2">Price</span>
											<span class="text-secondary fw-bold ms-auto">£{{ charge.charge_amt }} <span class="text-primary fw-light fs-6">(1)</span></span>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="col-md-6 order-md-1 order-2">
					<div class="d-flex align-items-end">
						<Link v-bind:href="$route('admin.orders')">
							<button class="btn-underline-secondary py-0">
								<i class="fa-regular fa-chevron-left text-primary me-2 pb-1"></i>
								<span>Back to orders list</span>
							</button>
						</Link>
					</div>
				</div>
				<div class="col-md-6 order-md-2 order-1 mb-md-0 mb-4">
					<div class="d-flex justify-content-end">
						<h5 class="total mb-0">Order Total: <span class="text-secondary order-cost ms-2">£{{ order.data.total }}</span></h5>
					</div>
				</div>
			</div>
		</section>
	</AdminLayout>
</template>

<script setup>
import AdminLayout from '@layouts/AdminLayout.vue';
import { Link } from '@inertiajs/vue3';

const props = defineProps({
	'order': Object
});

console.log(props.order.data);
</script>
