<template>
	<Head title="New Category"></Head>
	<AdminLayout>
		<section id="content-panel" class="single-category py-lg-10 py-8 px-xl-16 px-lg-8 px-4">
			<div class="row mb-md-8 mb-6">
				<div class="col-12">
					<div class="dashboard-title-block">
						<h1 class="h3 text-lg-start text-center mb-0">
							Create New Category
						</h1>
					</div>
				</div>
			</div>
			<form method="POST" @submit.prevent="save" class="row justify-content-xl-start justify-content-center">
				<div class="col-xxxl-auto col-md-10 col-12 mb-xxxl-0 mb-md-8 mb-6">
					<h5 class="mb-2">Category Icon</h5>
					<div class="icon-upload-container">
						<div class="preview-container mb-2 p-3" v-show="form.cat_icon !== null">
							<img src="" alt="Category icon preview" id="icon-preview">
						</div>

						<label for="cat-icon" class="btn-outline-primary text-primary icon-upload">
							<span>Upload Icon</span>
						</label>
						<input type="file" name="cat-icon" id="cat-icon" accept="image/*" @change="previewImage" ref="icon">
						<InputError class="mt-2" :message="form.errors.cat_icon"></InputError>
					</div>
				</div>

				<div class="col-xxxl-4 col-xl-6 col-md-10 col-12 mb-xl-0 mb-md-8 mb-6">
					<label class="h5 mb-2" for="name">Category name</label>
					<TextInput class="text-field" name="name" placeholder-value="Add a category name (required) ..." v-model="form.name"></TextInput>
					<InputError class="mt-2" :message="form.errors.name"></InputError>
				</div>
				<div class="col-xxxl-4 col-xl-6 col-md-10 col-12">
					<label class="h5 mb-2" for="description">Category snippet</label>
					<textarea class="text-field" name="description" placeholder="Add a custom category description ..." v-model="form.description" rows="10"></textarea>
					<InputError class="mt-2" :message="form.errors.description"></InputError>
				</div>
				<div class="col-12 mt-xl-6 mt-4">
					<div class="d-flex align-items-end border-top border-1 border-senary pt-4 w-100">
						<Link :href="$route('admin.categories')" class="me-4">
							<button class="btn-underline-secondary text-secondary fw-normal" type="button">
								<span>Back to Categories list</span>
							</button>
						</Link>
						<button class="btn-outline-primary text-primary ms-auto" type="submit">
							<span>Create category</span>
						</button>
					</div>
				</div>
			</form>
		</section>
	</AdminLayout>
</template>

<script setup>
import AdminLayout from "@layouts/AdminLayout.vue";
import TextInput from "@js/Components/TextInput.vue";
import InputError from "@js/Components/InputError.vue";
import { Head, Link, useForm } from "@inertiajs/vue3";
import { ref } from "vue";

const icon = ref(null);
const form = useForm({
	'name': "",
	'cat_icon': null,
	'description': "",
})

const save = () => {
	form.post(route('admin.create-category'), {
		onSuccess: (response) => {
			window.Toast.fire({
				icon: 'success',
				title: 'New category created!',
				timer: 3000
			});
		},
		onError: () => {
			console.log(form.errors)
			window.Toast.fire({
				icon: 'error',
				title: 'Category creation failed!',
				timer: 3000
			});
		}
	});
}

const previewImage = (event) => {
	if(event.target.files.length > 0){
		form.cat_icon = icon.value.files[0];
		console.log(form.cat_icon);
		debugger;
		let src = URL.createObjectURL(event.target.files[0]);
		let preview = document.getElementById("icon-preview");
		preview.src = src;
		preview.style.display = "block";
	}
}
</script>