<template>
	<section class="modal-bg" :class="{ show: showModal }" id="search-modal">
		<div class="modal-window p-8 p-md-10">
			<div class="d-flex justify-content-end mb-6">
				<button class="close" @click="$emit('close-search-modal')"></button>
			</div>
			<h2 class="text-primary text-center mb-4">Search</h2>
			<form @submit.prevent="submitSearch">
				<div class="form-row">
					<InputLabel value="Search"></InputLabel>
					<TextInput id="search" type="text" v-model="form.search" placeholder-value="Enter search text ..." required autofocus tabindex="1" />
					<InputError class="mt-2" :message="form.errors.search"></InputError>
				</div>

				<div class="form-row mt-4">
					<button type="submit" class="btn-outline-primary ms-auto" tabindex="2">
						<span>Search</span>
					</button>
				</div>
			</form>
		</div>
	</section>
</template>

<script setup>
import InputLabel from '@/Components/InputLabel.vue';
import InputError from '@/Components/InputError.vue';
import TextInput from '@/Components/TextInput.vue';
import { useForm } from '@inertiajs/vue3';

const props = defineProps({
	showModal: Boolean,
});

const form = useForm({
	search: '',
});
const submitSearch = () => {};
</script>
