<template>
	<div class="help-tag" :class="{ 'slide-out': active }">
		<div class="button-container px-4">
			<button @click="toggle" :class="{ disabled: active }">
				<span class="fa-stack" style="vertical-align: top">
					<i class="fa-regular fa-circle fa-stack-2x text-secondary"></i>
					<i class="fa-solid fa-question fa-stack-1x text-primary"></i>
				</span>
			</button>
		</div>
		<div class="link-wrapper pe-4 py-3" :class="{ hide: !active }">
			<Link :href="$route('catalogue')">Help</Link>
			<Link class="ms-4" :href="$route('catalogue')">How it works</Link>

			<button @click="toggle" class="close ms-5"></button>
		</div>
	</div>
</template>

<script setup>
import { Link } from '@inertiajs/vue3';
import { ref } from 'vue';

const active = ref(false);

const toggle = () => {
	active.value = !active.value;
};
</script>
