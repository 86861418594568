<template>
	<section>
		<div class="row gx-md-0">
			<div class="col-xl-3 col-lg-4 col-md-5 col-12 order-md-1 order-2">
				<div
					class="d-flex flex-column justify-content-center bg-tertiary px-lg-12 px-md-8 px-6 py-lg-25 py-md-20 py-16 w-100 h-100"
				>
					<div class="d-flex justify-content-lg-start justify-content-center mb-auto">
						<img src="/storage/images/Sertus Logo.svg" alt="Black Sertus Logo" class="sertus-logo lg mb-lg-12 mb-md-10 mb-8" />
					</div>

					<h1 class="h4 text-lg-start text-center text-secondary mb-0">Administration</h1>
					<h2 class="text-lg-start text-center mb-0">Sertus Portal</h2>

					<form id="login-form" class="mt-lg-20 mt-md-16 mt-12 mb-auto" @submit.prevent="submit">
						<div class="form-row">
							<label for="role_name" class="text-quaternary mb-2">Role Name</label>
							<input type="text" name="name" v-model="form.name" placeholder="Role name" />
							<InputError class="mt-2" v-bind:message="form.errors.name"></InputError>
						</div>

						<div class="form-row">
							<div class="my-2" v-if="status">
								<p class="fw-bold text-lg-start text-center text-secondary mb-0">{{ status }}</p>
							</div>

							<button class="login-button mt-8 w-100" type="submit" :disabled="form.processing"></button>
						</div>
					</form>
				</div>
			</div>
			<div class="col-xl-9 col-lg-8 col-md-7 col-12 order-md-2 order-1">
				<div class="img-block">
					<div class="login-img" style="background-image: url('/storage/images/Login hero image.jpg')"></div>
				</div>
			</div>
		</div>
	</section>
</template>

<script setup>
import { Link, useForm } from '@inertiajs/vue3';
import InputError from '@/Components/InputError.vue';

// creating the form using the inbuilt inertia form helper
const form = useForm({
	name: null,
});

// override the default form submission action in favour of using inertia to post to the server
const submit = () => {
	console.log(form.name);
	// reset the password input box in the UI if we fail the submission
	form.post(route('admin.add-role'), {
		onError: () => form.reset('name'),
		onSuccess: () => form.reset('name'),
	});
};
</script>
