<template>
	<section class="py-12">
		<div class="container">
			<div class="row">
				<div class="col-12 mb-4">
					<Link :href="$route('admin.dashboard')">back to dashboard</Link>
				</div>
				<div class="col-6">
					<h5 class="text-start mb-4">Roles list</h5>
					<table>
						<tbody>
						<tr v-for="role in roles">
							<td>{{ role.name }}</td>
							<td class="controls text-end">
								<button class="select ms-2" @click="selectRole(role)" v-if="role.name !== 'Super Admin'">
									select
								</button>
								<p class="mb-0" v-else>Super Admin may access all permissions</p>
							</td>
						</tr>
						</tbody>
					</table>
				</div>

				<div class="col-6">
					<h5 class="text-start mb-4">{{ selectedRole.name }} permissions</h5>

					<table v-if="selectedRole.permissions.length > 0">
						<tbody>
						<tr v-for="permission in selectedRole.permissions">
							<td>{{ permission.name }}</td>
							<td class="text-end">
								<button class="remove" @click="removePermission(permission.id)">remove permission</button>
							</td>
						</tr>
						</tbody>
					</table>

					<h5 class="text-secondary mb-0 text-start" v-else>No permissions to show for role: {{ selectedRole.name }}</h5>

					<button v-if="selectedRole.name !== 'Super Admin' && !showAdd" class="add mt-4" @click="showAdd = true">assign permission</button>
				</div>
			</div>

			<div class="row mt-6">
				<div class="col-6">
					<h5 class="text-start mb-4">update role name</h5>
					<form @submit.prevent="saveRole" class="role-form d-flex flex-column align-items-end" method="POST">
						<input type="text" v-model="role_form.name">
						<input type="hidden" v-model="role_form.id">
						<InputError :messag="role_form.id" class="mt-2"></InputError>
						<InputError :messag="role_form.name" class="mt-2"></InputError>
						<button class="save mt-2">
							save
						</button>
					</form>
				</div>

				<div class="col-6 mt-8" v-if="showAdd">
					<form @submit.prevent="addPermission" class="assign-permissions">
						<select name="permissions" id="permissions" v-model="assign_permission_form.permission_id">
							<option value="" selected disabled>please select a permission ...</option>
							<option :value="permission.id" v-for="permission in permissions">{{ permission.name }}</option>
						</select>
						<button class="mt-2" type="submit">add</button>
					</form>
					<div class="d-flex justify-content-end">
						<button class="mt-2" @click="showAdd = false">close</button>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script setup>
import { ref } from "vue";
import { Link, useForm, router } from "@inertiajs/vue3";
import InputError from "@js/Components/InputError.vue";

const props = defineProps({
	'roles' : Object,
	'permissions' : Object
});

const roles = ref(props.roles);
const showAdd = ref(false);
const selectedRole = ref(props.roles[0]);

const role_form = useForm({
	'id' : "",
	'name' : ""
})

const assign_permission_form = useForm({
	'permission_id' : ''
});

const selectRole = role => {
	selectedRole.value = role;
	startRoleEdit(role);
}
const startRoleEdit = role => {
	role_form.id = role.id;
	role_form.name = role.name
}

const saveRole = () => {
	role_form.post(route('admin.update-role-name'), {
		onSuccess: () => {
			window.Toast.fire({
				icon: 'success',
				title: 'Role name updated',
				timer: 3000
			})
			role_form.reset();
		},
		onError: error => {
			console.log(error);
			window.Toast.fire({
				icon: 'error',
				title: 'Name update failed',
				text: error.message,
				timer: 3000
			});
		}
	})
}

const removePermission = permissionId => {
	axios.post(route('admin.remove-permission'), {
		'role_id': selectedRole.value.id,
		'permission_id' : permissionId
	})
	.then(response => response.data)
	.then(data => {
		selectedRole.value.permissions = data.role.permissions;
		window.Toast.fire({
			icon: 'success',
			title: 'Permission removed',
			timer: 3000
		});
	})
	.catch(error => {
		console.log(error);
		window.Toast.fire({
			icon: 'error',
			title: 'Couldn\'t remove permission',
			text: error.message,
			timer: 3000
		});
	});
}

const addPermission = () => {
	axios.post(route('admin.assign-permission'), {
		'role_id' : selectedRole.value.id,
		'permission_id' : assign_permission_form.permission_id
	})
	.then(response => response.data)
	.then(data => {
		selectedRole.value.permissions = data.role.permissions;
		window.Toast.fire({
			icon: 'success',
			title: 'Permission added!',
			timer: 3000
		});
		assign_permission_form.reset();
	})
	.catch(error => {
		console.log(error);
		window.Toast.fire({
			icon: 'error',
			title: 'Couldn\'t add permission',
			text: error.message,
			timer: 3000
		});
	});
}
</script>