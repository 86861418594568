import './bootstrap'; // import the bootrapping js file
import '../scss/app.scss'; // import the application styles for build

// import '/node_modules/dropzone/dist/basic.css';
// import '/node_modules/dropzone/dist/dropzone.css';

// import the global application store that will be used for data sharing
import { store } from './Store/index';
import can from './Plugins/can';

import { computed, createApp, h } from 'vue';
import { createInertiaApp, usePage } from '@inertiajs/vue3';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import LaravelPermissionToVuejs from "laravel-permission-to-vuejs";

import Swal from 'sweetalert2';
window.Swal = Swal.mixin({
	iconColor: '#753fbf',
});
window.Toast = Swal.mixin({
	iconColor: '#753fbf',
	position: 'top-end',
	showConfirmButton: false,
	toast: true,
	timer: 3000,
	timerProgressBar: true,
	didOpen: (toast) => {
		toast.addEventListener('mouseenter', Swal.stopTimer);
		toast.addEventListener('mouseleave', Swal.resumeTimer);
	},
});

createInertiaApp({
	title: title => `${title} | Sertus B2B Portal`,
	resolve: name => {
		const pages = import.meta.glob('./Pages/**/*.vue', { eager: true })
		return pages[`./Pages/${name}.vue`]
	},
	setup({ el, App, props, plugin }) {
		const myApp = createApp({ render: () => h(App, props) })
		.use(plugin)
		.use(ZiggyVue, Ziggy)
		.use(store)
		.use(LaravelPermissionToVuejs)
		.mixin({
			methods: { route }
		});

		const permissions = computed(() => usePage().props.global.permissions);
		myApp.use(can, permissions)

		myApp.config.globalProperties.$permissions = permissions;
		myApp.config.globalProperties.$route = route;

		myApp.mount(el)
	},
	progress: {
		color: '#753fbf'
	}
})

// createInertiaApp({
// 	title: (title) => `${title} | ${appName}`,
// 	resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
// 	setup({ el, app, props, plugin }) {
// 		const myApp = createApp({ render: () => h(app, props) })
// 			.use(plugin)
// 			.use(ZiggyVue, Ziggy)
// 			.use(store)
// 			.mixin({
// 				methods: { route },
// 			});
//
// 		myApp.config.globalProperties.$route = route;
// 		myApp.mount(el);
// 		return myApp;
// 	},
// 	progress: {
// 	 	color: '#753fbf'
// 	}
// });
