<template>
	<section id="basket" :class="{ closed: basketClosed }">
		<button id="basket-toggle" @click="toggleBasket" :class="{ 'slide-out': !basketClosed }">
			<i class="fa-duotone fa-basket-shopping theme-sertus me-4"></i>
			<span class="mb-n1">{{ basketToggleText }}</span>
		</button>

		<div class="bg-quaternary d-flex align-items-center px-4 pt-3 py-2 w-100">
			<span class="h6 text-tertiary mb-0">Customer:</span>
			<h6 class="text-tertiary text-lg-start text-center ms-auto mb-0">{{ current_customer.name }}</h6>
		</div>

		<div id="basket-head" class="pt-6 px-2">
			<div class="d-flex align-items-center ps-2 pr-1 mb-lg-6 mb-4">
				<h2 class="h5 text-left mb-n1">
					{{ basketName }}
					<span class="ms-lg-8 ms-4"> <i class="fa-duotone fa-pen-line theme-sertus"></i> </span>
				</h2>

				<button class="d-lg-none d-block ms-auto" id="basket-collapse" @click="toggleBasket">Collapse</button>
			</div>

			<div id="controls" class="d-flex align-items-center border-top border-bottom border-primary py-3 px-2">
				<div class="d-flex align-items-center">
					<Link :href="$route('basket.view-full')">
						<button class="btn-underline-secondary text-primary d-flex align-items-center p-0 mb-n1">
							<i class="fa-duotone fa-list theme-sertus invert-theme me-4"></i>
							<span> View Full Project </span>
						</button>
					</Link>
				</div>
				<div class="d-flex align-items-center justify-content-end ps-4">
					<span class="basket-count">
						<span>
							{{ basketCount }}
						</span>
					</span>
					<span class="text-end ms-3 mb-n1"> Items in basket </span>
				</div>
			</div>
		</div>

		<div id="basket-body" class="flex-fill">
			<div
				class="d-flex flex-column align-items-center justify-content-center h-100 px-xxxl-20 px-xxl-12 px-lg-10 px-8 py-md-12 py-10"
				v-if="basketCount === 0"
			>
				<img class="trolley-icon mb-lg-12 mb-md-10 mb-8" src="/storage/images/trolley.svg" alt="Trolley icon" />
				<h3 class="text-center mb-lg-4 mb-2">Your project is empty</h3>
				<h6 class="text-center text-secondary">
					Add products from the
					<Link :href="route('catalogue')" class="btn-underline-primary d-inline">
						<span>catalogue</span>
					</Link>
					to get started
				</h6>
			</div>
			<!-- <div class="px-2" v-else="!basketEmpty"> -->
			<div id="basket-items" class="p-2" v-else>
				<div class="basket-item" v-for="item in basketItems" :key="item.id">
					<Thumbnail :images="item.images" basket></Thumbnail>
					<div class="item-details flex-fill">
						<h3 class="fw-normal mb-1">{{ item.name }}</h3>
						<h4 class="fw-normal text-uppercase text-secondary mb-2">{{ item.part_num }}</h4>

						<div class="d-none mb-4">
							<ul class="labels">
								<li class="label">User defined label</li>
							</ul>
						</div>

						<div class="d-flex align-items-center mb-2">
							<button class="btn-underline-senary d-none me-4">
								<i class="fa-light fa-wrench text-primary"></i>
								<span>Reconfigure</span>
							</button>

							<button class="btn-underline-senary" @click="removeBasketItem(item)">
								<i class="fa-duotone fa-trash theme-sertus"></i>
								<span>Remove</span>
							</button>
						</div>

						<div class="d-flex align-items-center justify-content-end mt-auto">
							<div class="d-flex align-items-center">
								<button class="qty-control down" @click="reduceQty(item)"></button>
								<span class="d-inline-block mx-3 mb-n1 item-qty">{{ item.quantity }}</span>
								<button class="qty-control up" @click="increaseQty(item)"></button>
							</div>
						</div>
						<div class="d-flex align-items-center justify-content-end mt-2">
							<Price :item="item" :show-discount="current_customer.show_discount"></Price>
							<span class="tax-label ms-2 mb-n1">ExVAT</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div id="basket-foot" class="mt-auto">
			<div class="border-top border-primary pt-lg-6 pt-4 mx-2">
				<div class="d-flex align-items-center price-wrapper mb-lg-6 mb-4">
					<h3 class="fw-normal me-auto mb-0">Total:</h3>
					<span class="vat-lbl">ExVAT</span>
					<span class="price ms-4">
						<span class="h3 mb-0">£{{ calcPrice(total).pounds }}.</span>
						<span>{{ calcPrice(total).pence }}</span>
					</span>
				</div>
			</div>
			<div class="d-flex align-items-center">
				<button class="btn-primary text-tertiary hover-primary flex-fill" :class="{'disabled' : basketCount === 0}" @click="newQuote">
					<span>Request Quote</span>
				</button>
				<button class="btn-secondary text-tertiary hover-secondary flex-fill" :class="{'disabled' : basketCount === 0}" @click="checkout">
					<span>Place Order</span>
				</button>
			</div>
		</div>
	</section>
</template>

<script setup>
import Price from "@js/Components/Price.vue";
import { Link, usePage } from '@inertiajs/vue3';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useBasket} from "@js/Composables/basket";
import { usePrice} from "@js/Composables/pricing";
import Thumbnail from "@js/Components/Thumbnail.vue";

/** ============ */
/** Composables  */
/** ============ */
const store = useStore();
const {
	basketName,
	basketItems,
	basketCount,
	total,
	checkout,
	increaseQty,
	newQuote,
	reduceQty,
	refreshBasket,
	removeBasketItem
} = useBasket();
const {
	calcPrice
} = usePrice();

/** ===== */
/** Refs  */
/** ===== */
const current_customer = computed(() => usePage().props.global.current_customer.data );
// set the basket to be closed by default
const basketClosed = ref(true);
// compute the value of the basket toggle button text based on the state of the basket (open/closed)
const basketToggleText = computed(() => {
	return basketClosed.value ? 'Show Basket' : 'Collapse basket';
});

/** ======= */
/** Methods */
/** ======= */
// function to toggle the basket open/closed
const toggleBasket = () => {
	basketClosed.value = !basketClosed.value;
};

/** ============ */
/** Method Calls */
/** ============ */
refreshBasket();

</script>