<template>
	<Head title="Welcome"></Head>
	<section id="client-login" class="form-page">
		<div class="bg-container">
			<video autoplay muted loop poster="/storage/images/pod-b-roll-1.jpg" src="/storage/videos/pod-b-roll-2.mp4"></video>
			<div class="bg-poster" style="background-image: url('/storage/images/pod-b-roll-1.jpg')"></div>
		</div>
		<div class="container">
			<div class="row justify-content-lg-start justify-content-center">
				<div class="col-xxl-4 col-xl-5 col-lg-6 col-md-8 col-sm-10 col-12">
					<div class="bg-tertiary py-xl-20 py-md-16 py-12 px-xl-16 px-md-12 px-8">
						<div class="d-flex justify-content-lg-start justify-content-center mb-lg-10 mb-8">
							<ApplicationLogo></ApplicationLogo>
						</div>

						<h1 class="h2 text-lg-start text-center mb-1">Portal</h1>
						<h3 class="text-lg-start text-center mb-3">Welcome <span class="text-secondary" v-if="current_user !== ''">{{ current_user }}</span></h3>

						<Link :href="$route('catalogue')">
							<button class="btn-secondary text-tertiary py-6 mb-4 w-100">
								<span>View our Catalogue</span>
							</button>
						</Link>

						<div class="wysiwyg text-senary text-lg-start text-center pb-6 border-bottom border-1 border-primary">
							<p>View our catelogue, add products to your project, and then either order or request a quote!</p>
						</div>

						<Link :href="$route('account.your-orders')" class="d-block mt-8">
							<button class="btn-secondary text-tertiary py-4 w-100">
								<span>View your previous orders</span>
							</button>
						</Link>
						<Link :href="$route('account.your-quotes')" class="d-block mt-4">
							<button class="btn-secondary text-tertiary py-4 w-100">
								<span>View your raised quotes </span>
							</button>
						</Link>

						<button class="btn-primary btn-icon-slide text-tertiary py-6 w-100 mt-8" @click="toggleCallbackRequest">
							<span>
								<i class="fa-duotone fa-phone-arrow-down-left theme-sertus-white invert-theme me-3"></i>
								Request a Call-back
							</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</section>
	<CallbackModal :showModal="showCallbackRequest" @close-callback-request="toggleCallbackRequest"></CallbackModal>
</template>

<script setup>
import CallbackModal from '@/Components/CallbackModal.vue';
import ApplicationLogo from '@/Components/ApplicationLogo.vue';
import { Head, Link, useForm, usePage } from '@inertiajs/vue3';
import { computed, ref } from 'vue';

defineProps({
	canResetPassword: Boolean,
	status: String,
});

const current_user = computed(() => usePage().props.global.current_user);

const showCallbackRequest = ref(false);
const toggleCallbackRequest = () => {
	showCallbackRequest.value = !showCallbackRequest.value;
};

const form = useForm({
	email: '',
	password: '',
	remember: false,
});

const submit = () => {
	form.post(route('login'), {
		onFinish: () => form.reset('password'),
	});
};
</script>
