<template>
	<Head title="Select Customer"></Head>
	<section id="client-login" class="form-page">
		<div class="bg-container">
			<video autoplay muted loop poster="/storage/images/pod-b-roll-1.jpg" src="/storage/videos/pod-b-roll-2.mp4"></video>
			<div class="bg-poster" style="background-image: url('/storage/images/pod-b-roll-1.jpg')"></div>
		</div>
		<div class="container">
			<div class="row justify-content-lg-start justify-content-center">
				<div class="col-xxl-4 col-xl-5 col-lg-6 col-md-8 col-sm-10 col-12">
					<div class="bg-tertiary py-xl-20 py-md-16 py-12 px-xl-16 px-md-12 px-8">
						<div class="d-flex justify-content-lg-start justify-content-center mb-lg-10 mb-8">
							<ApplicationLogo></ApplicationLogo>
						</div>

						<h1 class="h2 text-lg-start text-center mb-3">Portal</h1>
						<p class="fw-light text-lg-start text-center mb-lg-12 mb-10">Please select a customer to login on behalf of before proceeding further with your order/quote.</p>

						<form @submit.prevent="submit">
							<div class="form-row mb-6">
								<InputLabel class="hidden" for="customer" value="Select a customer" />
								<select name="customer" id="customer" v-model="form.customer">
									<option value="" selected disabled>Please select a customer</option>
									<option :value="customer.id" v-for="customer in customers">{{ customer.name }} - {{ customer.address }}</option>
								</select>
								<InputError class="mt-2" :message="form.errors.customer" />
							</div>

							<div class="form-row d-flex flex-column align-items-end">
								<button class="login-button w-100" type="submit"></button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script setup>
import ApplicationLogo from '@/Components/ApplicationLogo.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import TextInput from '@/Components/TextInput.vue';
import { Head, Link, useForm } from '@inertiajs/vue3';

const props = defineProps({
	customers: Object
});

console.log(props.customers);

const form = useForm({
	customer: '',
});
const submit = () => {
	form.post(route('set-selected-customer'), {
		onFinish: () => form.reset('customer'),
	});
};
</script>
