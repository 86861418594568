<template>
	<Head title="Categories"></Head>
	<AdminLayout>
		<section id="content-panel" class="py-lg-10 py-8 px-xl-16 px-lg-8 px-4">
			<div class="row mb-md-8 mb-6">
				<div class="col-12">
					<div class="dashboard-title-block">
						<h1 class="h3 text-lg-start text-center mb-lg-0 mb-2">Category Management</h1>
						<p class="text-lg-start text-center ms-lg-auto mb-0">Add, edit and delete categories.</p>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<div class="d-flex justify-content-end mb-4">
						<Link :href="$route('admin.show-create-category')">
							<button class="btn-outline-quaternary"><span>Add new Category</span></button>
						</Link>
					</div>

					<div class="table-container">
						<table id="categories-table">
							<tbody>
								<tr v-for="category in categories">
									<td>
										{{ category.name }}
									</td>
									<td class="row-controls">
										<Link :href="$route('admin.single-category', {'category' : category.id})">
											<button class="btn-secondary text-tertiary">
												<span>Edit</span>
											</button>
										</Link>
										<button class="btn-outline-primary text-secondary ms-4" @click="remove(category.id)">
											<span>Delete</span>
										</button>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<div class="d-flex justify-content-end mt-4">
						<Link :href="$route('admin.show-create-category')">
							<button class="btn-outline-quaternary"><span>Add new Category</span></button>
						</Link>
					</div>
				</div>
			</div>
		</section>
	</AdminLayout>
</template>

<script setup>
import AdminLayout from '@layouts/AdminLayout.vue';
import { Head, Link, router } from '@inertiajs/vue3';

const props = defineProps({
	'categories': Object
});

const remove = (categoryId) => {
	Swal.fire({
		icon: 'question',
		title: 'Delete category?',
		text: 'Are you sure you want to delete this category?',
		showConfirmButton: true,
		showDenyButton: true,
		confirmButtonText: 'Delete',
		denyButtonText: 'Cancel'
	})
	.then(response => {
		if(response.isConfirmed) {
			router.delete(route('admin.delete-category', {'category' : categoryId}), {
				onSuccess: () => {
					window.Toast.fire({
						icon: 'success',
						title : 'Category deleted!',
						timer: 3000
					});
				},
				onError: () => {
					window.Toast.fire({
						icon: 'error',
						title : 'Category deletion failed!',
						timer: 3000
					})
				}
			});
		}
	});
}
</script>