export function usePrice() {
	function calcPrice(price, quantity = 1) {
		price = castFloat(price);
		price = price * quantity;
		price = price.toFixed(2);
		price = price.toString();

		let price_parts = price.split('.');

		let formatter = new Intl.NumberFormat();
		let pounds = formatter.format(parseInt(price_parts[0]));

		return {
			pounds: pounds,
			pence: price_parts[1],
		};
	}

	function castFloat(value) {
		if(typeof value === 'string' || value instanceof String) {
			value = value.replace(",", "");
		}

		return parseFloat(value);
	}

	return {
		calcPrice,
		castFloat
	}
}