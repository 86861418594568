<template>
	<AdminLayout>
		<section id="content-panel" class="pt-lg-10 pt-8">
			<section class="content-panel-inner px-xl-16 px-lg-8 px-4">
				<div class="row mb-md-8 mb-6">
					<div class="col-12">
						<div class="dashboard-title-block">
							<h1 class="h3 text-lg-start text-center mb-lg-0 mb-2" v-if="reviewed">Edit `{{ group.name }}`</h1>
							<h1 class="h3 text-lg-start text-center mb-lg-0 mb-2" v-else>Inbound Epicor Product</h1>

							<p class="text-lg-start text-center ms-lg-auto mb-0">
								Review the product details from Epicor, add images and extra details, then confirm.
							</p>
						</div>
					</div>
				</div>

				<div class="row mb-lg-8 mb-6">
					<div class="col-12">
						<div class="epicor-details p-6">
							<div class="d-flex flex-lg-row flex-column align-items-center bottom-divider">
								<h4 class="text-start mb-lg-0 mb-4">{{ group.name }}</h4>
								<p class="text-lg-start text-center fs-6 ms-lg-auto mb-lg-0 mb-2">
									These fields are from Epicor and cannot be changed here. They are presented for review purposes only.
								</p>
								<i class="fa-solid fa-lock ms-4 pb-1"></i>
							</div>

							<div class="row mt-8 mb-n4" id="epicor-values">
								<div class="col-xxl-5ths col-lg-4 col-md-6 col-12 mb-4"><span class="text-primary fw-bold me-2">SKU:</span>{{ group.SKU }}</div>
								<div class="col-xxl-5ths col-lg-4 col-md-6 col-12 mb-4"><span class="text-primary fw-bold me-2">Avg Price (ex VAT):</span>£{{ group.unit_price }}</div>
							</div>

						</div>
					</div>
				</div>

				<div class="row mb-lg-8 mb-6">
					<div class="col-12 mb-lg-8 mb-6">
						<div class="border border-1 border-primary p-4">
							<h3 class="h5 text-start mb-4">Categories</h3>
							<form method="PUT" @submit.prevent="saveCategories" class="d-flex flex-column">
								<ul class="product-category-list list-unstyled mb-0">
									<li v-for="category in categories" :key="category.id" class="me-4">
										<div class="checkbox-container" :class="{'disabled' : readonly}">
											<input type="checkbox" :id="'category-' + category.id" :value="category.id" v-model="category_form.selected_categories">
											<label :for="'category-' + category.id">{{ category.name }}</label>
										</div>
									</li>
								</ul>

								<button class="btn-outline-quaternary text-quaternary mt-4 ms-auto" type="submit" v-if="!readonly">
									<span>Save categories</span>
								</button>
							</form>
						</div>
					</div>

					<div class="col-md-2 col-12">
						<h3 class="h5 text-start mb-lg-0 mb-2">Description:</h3>
					</div>

					<div class="col-md-10 col-12">
						<div class="d-flex align-items-start justify-content-md-end">
							<div class="d-flex align-items-start mb-lg-0 mb-2">
								<div class="me-2">
									<i class="fa-light fa-circle-info"></i>
								</div>
								<p class="mb-0">
									This is a Master procuct - anything set here will be default for variations without their own data.
								</p>
							</div>
							<div class="d-flex align-items-center ms-lg-4">
								<i class="fa-regular fa-circle-dot me-2"></i>
								<span class="product-tag">Master</span>
							</div>
						</div>
					</div>

					<div class="col-lg-7 col-12 mt-6">
						<template v-if="readonly">
							<template v-if="group.custom_description">
								<div class="master-description py-lg-8 py-6 px-6" v-html="group.custom_description">
								</div>
							</template>
							<template v-else>
								<h5 class="text-start text-secondary mb-0">
									No custom description found for this group!
								</h5>
							</template>
						</template>
						<template v-else>
							<editor
								id="product-description"
								api-key="l2rni2rxqi55xico6zhg1sujh0z5h591cxf6qwba2gnv4jql"
								:init="{
									height: 750,
									menubar: true,
									toolbar:
										'undo redo | formatselect | bold italic backcolor | \
										alignleft aligncenter alignright alignjustify | \
										bullist numlist outdent indent | removeformat | help',
								}"
								v-model="groupForm.custom_description"
							></editor>
						</template>


						<h3 class="h5 text-start mb-lg-6 mb-4 mt-xl-10 mt-8">Stock Message</h3>
						<template v-if="readonly">
							<template v-if="group.stock_message">
								<div class="master-description py-lg-8 py-6 px-6" v-html="group.stock_message">
								</div>
							</template>
							<template v-else>
								<h5 class="text-start text-secondary mb-0">
									No stock message found for this group!
								</h5>
							</template>
						</template>
						<template v-else>
							<editor
									id="stock-message"
									api-key="l2rni2rxqi55xico6zhg1sujh0z5h591cxf6qwba2gnv4jql"
									:init="{
										height: 250,
										menubar: false,
										toolbar: 'undo redo',
									}"
									v-model="groupForm.stock_message"
									placeholder="E.g Estimated delivery time for this product is 2 working weeks."
							></editor>
						</template>
					</div>

					<div class="col-lg-5 col-12 mt-6">
						<h3 class="h5 text-start mb-4">Images</h3>
						<form id="file-upload-form" class="dropzone inline-dropzone mb-2" :action="$route('admin.prod-group-image-upload')">
							<input type="hidden" id="part_id" name="part_id" :value="group.id">
							<div class="image-box p-2">
								<div class="d-flex flex-column align-items-center" v-if="queueLength === 0">
									<span class="fa-stack fa-2x mb-2">
										<i class="fa-light fa-square fa-stack-2x text-septenary"></i>
										<i class="fa-solid fa-plus fa-stack-1x text-secondary"></i>
									</span>
									<h5 class="text-center text-secondary mb-0">Drag files here to upload ...</h5>
								</div>

								<div class="preview-wrapper d-none">
									<div class="my-2 px-2">
										<div class="img-block bg-tertiary">
											<button class="dz-remove" data-dz-remove></button>
											<img data-dz-thumbnail />
											<div class="dz-progress my-2"><span class="dz-upload" data-dz-uploadprogress></span></div>
										</div>
									</div>
								</div>

								<div id="preview-container" class="d-flex flex-wrap justify-content-center align-items-center mb-n2 mx-n2"></div>
							</div>
						</form>

						<div class="product-gallery mb-n2 me-n2">
							<div class="gallery-block img-block p-2 me-2 mb-2" v-for="image in groupImages">
								<span class="remove" :id="'attachment-' + image.id" @click="remove_attachment(image.id)" v-if="!readonly"></span>
								<div class="bg-square" :style="'background-image: url(' + image.file_location + ')'"></div>
							</div>
						</div>
						<div class="bg-tertiary p-4 mt-4">
							<div class="d-flex align-items-center bg-darkgrey mb-4 pt-4 pb-3 px-4">
								<h5 class="text-tertiary mb-0">Bulk image upload</h5>
								<i class="fa-duotone theme-sertus-white invert-theme ms-auto fa-layer-group pb-1"></i>
							</div>
							<p class="mb-0">Upload a set of images. Each image added will be attached to this product group ONLY, regardless of the filename.</p>
						</div>
					</div>
				</div>

				<div class="row mb-lg-10 mb-8">
					<div class="col-12">
						<h5 class="text-left mb-4">Variations:</h5>
						<div class="table-container" v-if="group.variations.length > 0">
							<table id="product-review-table" class="variations-table">
								<tbody>
									<!-- VARIATION -->
									<tr v-for="variation in group.variations">
										<td>
											<div class="d-lg-block d-none">
												<div class="d-flex align-items-center">
													<h5 class="text-start fw-normal mb-n1">{{ variation.name }}</h5>
												</div>
											</div>
											<div class="d-lg-none d-block">
												<div class="d-flex align-items-center">
													<div class="me-4 mb-n1">
														<h5 class="text-start mb-0">{{ variation.name }}</h5>
														<div class="d-flex align-items-center">
															SKU: <span class="fw-bold text-secondary ms-4">{{ variation.SKU }}</span>
														</div>
													</div>

													<div class="row-controls d-flex align-items-center ms-auto">
														<Link :href="$route('admin.part-review', {'part': variation.id})" v-if="!variation.reviewed">
															<button class="btn-outline-secondary d-xxl-none d-block">
																<span>Review</span>
															</button>
														</Link>
														<Link :href="$route('admin.part-details', {'part': variation.id})" v-else>
															<button class="btn-outline-secondary d-xxl-none d-block">
																<span>Edit</span>
															</button>
														</Link>
													</div>
												</div>
											</div>
										</td>
										<td>
											<div class="d-flex align-items-center">
												<div class="d-flex align-items-center ms-6 me-auto">
													SKU: <span class="fw-bold text-secondary ms-xxl-12 ms-4">{{ variation.SKU }}</span>
												</div>

												<div class="d-flex align-items-center ms-auto">
													<Link :href="$route('admin.part-review', {'part': variation.id})" v-if="!variation.reviewed">
														<button class="btn-outline-secondary">
															<span>Review</span>
														</button>
													</Link>
													<Link :href="$route('admin.part-details', {'part': variation.id})" v-else>
														<button class="btn-outline-secondary">
															<span v-if="$can('edit parts')">Edit</span>
															<span v-else>View</span>
														</button>
													</Link>
												</div>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="border-top border-1 border-primary pt-5" v-else>
							<h5 class="text-lg-start text-center text-secondary mb-0">There are no variations to show for this group!</h5>
						</div>

						<div class="d-flex align-items-center mt-lg-12 mt-md-10 mt-8">
							<Link :href="$route('admin.part-list')" v-if="reviewed">
								<button class="btn-underline-secondary">
									<i class="fa-regular fa-chevron-left text-primary"></i>
									<span> Back to Part List </span>
								</button>
							</Link>
							<Link :href="$route('admin.epicor-sync')" v-else>
								<button class="btn-underline-secondary">
									<i class="fa-regular fa-chevron-left text-primary"></i>
									<span> Back to Epicor Sync</span>
								</button>
							</Link>
						</div>
					</div>
				</div>
			</section>

			<section id="confirm-banner" v-if="!readonly">
				<div class="row justify-content-center">
					<div class="col-auto">
						<div class="d-flex align-items-center">
							<p class="text-tertiary mb-0" v-if="reviewed">
								Click 'confirm' to save your changes once you have finished editing.
							</p>
							<p class="text-tertiary mb-0" v-else>
								When you've added all your details - confirm this product to add it to the site
							</p>
							<button class="btn-secondary text-tertiary ms-4" @click="confirm_changes"><span>Confirm</span></button>
						</div>
					</div>
				</div>
			</section>
			<div class="confirm-placeholder"></div>
		</section>
	</AdminLayout>
</template>

<script setup>
import AdminLayout from '@layouts/AdminLayout.vue';
import Editor from '@tinymce/tinymce-vue';
import { Link, useForm } from '@inertiajs/vue3';
import { computed, onMounted, ref } from "vue";
import useDropzone from "@js/Composables/dropzone";

const props = defineProps({
	'group' : Object,
	'readonly' : Boolean,
	'csrf_token': String,
});

const queueLength = ref(0);
const groupImages = ref(props.group.data.images);
const { inlineDropzone } = useDropzone(props, groupImages, queueLength);

const group = ref(props.group.data);
const dropzone = ref(null);
const categories = ref(props.group.data.categories);
const reviewed = computed(() => group.value.reviewed);
// filter out the selected categories for the group
const selected_categories = computed(() => {
	let values = categories.value.map(item => {
		if(item.assigned) {
			return item.id;
		}
	})
	return values.filter(item => {
		return item !== undefined;
	});
});

// assign the selected values to the form
const category_form = useForm({
	'id' : group.value.id,
	'selected_categories' : selected_categories.value
});

const groupForm = useForm({
	'id' : group.value.id,
	'custom_description' : group.value.custom_description,
	'stock_message' : group.value.stock_message,
})

onMounted(() => {
	dropzone.value = inlineDropzone();
});

const remove_attachment = async attachment_id => {
	let group_id = group.value.id;

	Swal.fire({
		icon: 'info',
		title: 'Deleting image ...',
		text: 'Please wait whilst we delete your image',
		showConfirmButton: false,
		showDenyButton: false,
	});

	await axios.delete(route('admin.remove-prod-group-attachment'), {
		data: {
			'part_id': group_id,
			'attachment_id': attachment_id
		}
	})
	.then(response => {
		setTimeout(() => {
			group.value.images = response.data;
			Swal.close();

			window.Toast.fire({
				icon: 'success',
				title: 'Image deleted successfully'
			});
		}, 1500);
	})
	.catch(error => {
		console.log(error);
		Swal.fire({
			icon: 'error',
			title: 'Image deletion failed!',
			text: 'Sorry, something has gone wrong on our end whilst trying to delete your image. Please try again and then reach out to tech support if the issue persists.',
			showDenyButton: false,
			confirmButtonText: 'Okay'
		});
	});
}

const confirm_changes = () => {
	Swal.fire({
		icon:'info',
		title: 'Saving Now',
		text: 'Hold tight whilst we save the group data for you. Thanks!',
		showConfirmButton: false,
		showDenyButton: false
	});

	let routeString = 'admin.update-prod-group-details';
	if(!reviewed.value) {
		routeString = 'admin.confirm-prod-group-review';
	}

	groupForm.put(route(routeString), {
		onSuccess: response => {
			Swal.close(); // close the 'Syncing' alert window
			window.Toast.fire({
				icon:'success',
				title: 'Group successfully synced or updated!',
				timer: 3000
			});
		},
		onError: error => {
			Swal.close(); // close the 'Syncing' alert window

			console.log(error);
			Swal.fire({
				icon: 'error',
				title: 'Group save failed!',
				text: 'Please try again. If this problem persist then please reach out to technical support!',
				showDenyButton: false,
				showConfirmButton: true,
				confirmButtonText: 'Okay'
			});
		}
	});
}

const saveCategories = () => {
	category_form.put(route('admin.update-group-categories'), {
		onSuccess: response => {
			window.Toast.fire({
				icon: 'success',
				title: 'Catefories list updated',
				timer: 3000
			});
		},
		onError: error => {
			window.Toast.fire({
				icon: 'error',
				title: 'Failed to update categories!',
				timer: 3000
			});
		}
	})
}
</script>
