<template>
	<AdminLayout>
		<section id="content-panel" class="py-lg-10 py-8 px-xl-16 px-lg-8 px-4">
			<div class="row mb-lg-12 mb-10">
				<div class="col-12">
					<div class="dashboard-title-block">
						<h1 class="h3 text-lg-start text-center mb-lg-0 mb-2">Bulk Image Upload</h1>
						<p class="text-lg-start text-center ms-lg-auto mb-0">Bulk upload images to products here.</p>
					</div>
				</div>
			</div>

			<div class="row mb-lg-6 mb-4">
				<div class="col-12">
					<!-- <form class="d-none dropzone w-100" :action="$route('admin.bulk-upload-images')" style="height: 250px"></form> -->
					<form id="file-upload-form" class="dropzone" :action="$route('admin.bulk-upload-images')">
						<div class="bg-tertiary py-xl-20 py-lg-16 py-md-12 py-10 p-8" id="upload-panel">
							<div class="dz-message empty-content d-flex flex-column align-items-center" data-dz-message>
								<i class="fa-duotone fa-layer-group theme-sertus fs-2 mb-4"></i>
								<p class="text-center">Drag and drop a <span class="fw-bold">ZIP</span> file of images here.</p>
								<p class="text-center">Each image must have the same name as the product SKU you wish to match.</p>
								<p class="text-secondary text-center fw-bold">
									I.E "SKU-HERE_image-number.jpg"
									<br />
									E.G: "EDF-34ds_1.jpg", "EDF-34ds_2.jpg", "EDF-34ds_3.jpg" ...
								</p>

								<button class="btn-primary text-tertiary" type="button">
									<span class="d-flex align-items-center">
										<span class="me-2">Or upload from computer.</span>
										<i class="fa-duotone fa-cloud-arrow-up theme-sertus ms-auto pb-1"></i>
									</span>
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
			<div class="row mb-xl-12 mb-lg-10 mb-8">
				<div class="col-lg-3 col-12">
					<div class="d-flex align-items-center">
						<h3 class="h5 text-secondary text-lg-start text-center me-lg-4 mb-lg-0 mb-4">{{ queueCount }} Queued Images</h3>
						<h3 class="h5 text-secondary text-lg-start text-center mb-lg-0 mb-4">{{ uploadCount }} Uploaded Images</h3>
					</div>
				</div>
				<div class="col-lg-9 col-12">
					<div class="d-flex justify-content-lg-end justify-cotent-center align-items-start">
						<i class="fa-light fa-circle-info fs-3 me-2 pb-1"></i>
						<p class="mb-0">Double check your bulk upload here. Replace any incorrect images by uploading a new one, then confirm.</p>
					</div>
				</div>
			</div>
			<div class="row mx-xl-n8 mx-lg-n4 mx-n2 mb-n8" id="preview-container">
				<div class="preview-wrapper d-none">
					<div class="preview-tile-container col-xxl-3 col-md-4 col-6 px-lg-4 px-2 px-xxl-8 mb-lg-8 mb-4">
						<div class="tile bg-tertiary p-4">
							<div class="img-block bg-tertiary mb-4">
								<img data-dz-thumbnail />
								<div class="dz-progress mt-2"><span class="dz-upload" data-dz-uploadprogress></span></div>
							</div>
							<h3 class="h5 text-lg-start text-center fw-normal" data-dz-name></h3>
							<div class="controls-wrapper mt-auto">
								<!-- configure custom remove link -->
								<a class="dz-remove" data-dz-remove="">
									<button class="remove-button" data-dz-remove>
										<i class="fa-duotone fa-trash theme-sertus me-2 pb-1"></i>
										<span> Remove Image </span>
									</button>
								</a>
								<!-- custom remove link end -->
							</div>
						</div>
					</div>
				</div>

				<div class="col-12 mb-4 px-xxl-8" v-if="queueCount > 0">
					<div class="d-flex justify-content-end">
						<button class="btn-secondary text-tertiary" @click="uploadFiles">
							<span>
								<i class="fa-duotone fa-cloud-arrow-up theme-sertus-white invert-theme me-2 mb-n1"></i>
								Upload files
							</span>
						</button>
						<button class="btn-primary text-tertiary ms-4" @click="clearZone">
							<span>
								<i class="fa-duotone fa-circle-xmark theme-sertus-white invert-theme me-2 mb-n2"></i>
								Clear Queue
							</span>
						</button>
					</div>
				</div>
			</div>
		</section>
	</AdminLayout>
</template>

<script setup>
import AdminLayout from '@layouts/AdminLayout.vue';
import Dropzone from 'dropzone';
import { onMounted, ref } from 'vue';

const props = defineProps({
	'csrf_token': String,
});

const uploadCount = ref(0);
const queueCount = ref(0);
const zone = ref(null);

const initDropzone = () => {
	// create a dropzone that only accepts zip files
	let dropzone = new Dropzone('.dropzone', {
		autoProcessQueue: false,
		addRemoveLinks: false,
		headers: {
			'X-CSRF-TOKEN': props.csrf_token,
		},
		maxFilesize: 2048, // a max 2MB upload size
		parallelUploads: 4, // this is not used when uploadMultiple has a value of false
		paramName: 'uploads',
		previewsContainer: '#preview-container',
		previewTemplate: document.querySelector('.preview-wrapper').innerHTML,
		uploadMultiple: false,

		addedFile: function (file) {
			file.previewTemplate = Dropzone.createElement(this.options.previewTemplate);
		},
	});

	dropzone.on('addedfiles', function () {
		queueCount.value = dropzone.getQueuedFiles().length;
	});
	dropzone.on('removedfile', function (file) {
		queueCount.value = dropzone.getQueuedFiles().length;
	});

	dropzone.on('complete', function (file) {
		console.log(file.status);
		if (file.status === 'success') {
			// after showing the alert, we remove the file from the queue and update the queued items display
			uploadCount.value++;
			this.removeFile(file);
			queueCount.value = dropzone.getQueuedFiles().length;

			let remaining = zone.value.getQueuedFiles().length;
			if(remaining === 0) {
				// force a short delay for the user's benefit
				setTimeout(() => {
					Swal.close();

					window.Toast.fire({
						icon: 'success',
						title: 'Images uploaded successfully!'
					});
				}, 1000);
			}
		}
	});

	dropzone.on('success', function(file, response) {

	});

	dropzone.on('error', function (file, error) {
		Swal.close();

		window.Toast.fire({
			icon: 'error',
			title: 'File upload failed',
			text: 'Sorry, one or more of your file uploads failed. Please check the dtails page for more info!',
			timer: 5000
		});

		this.removeFile(file);
		queueCount.value = dropzone.getQueuedFiles().length;
	});


	return dropzone; // retun the dropzone for use outsize of the init function via a Vue ref
};

const uploadFiles = async () => {
	Swal.fire({
		icon: 'info',
		title: 'Uploading images',
		text: 'Please wait whilst we upload your images to the server. This may take some time!',
		showConfirmButton: false,
		showDenyButton: false
	});

	let dropzone = zone.value;
	await dropzone.processQueue();
};

const clearZone = () => {
	let dropzone = zone.value;
	dropzone.removeAllFiles();
};

// run the dropzone init when the component is fully mounted within the application
onMounted(() => {
	zone.value = initDropzone(); // assign the dropzone to a Vue ref for use in the template
});
</script>
