<template>
	<Head title="Admin | Reset Password"></Head>
	<section id="form-page" style="background-image: url('/storage/images/Login hero image.jpg')">
		<div class="container py-xl-20 py-md-16 py-12 h-100">
			<div class="row justify-content-center h-100">
				<div class="col-xl-7 col-lg-9 col-md-10 col-12 d-flex flex-column justify-content-center align-items-start h-100">
					<div class="form-wrapper px-lg-12 px-md-8 px-6 py-xl-20 py-lg-16 py-12">
						<div class="d-flex justify-content-lg-start justify-content-center">
							<img src="/storage/images/Sertus Logo.svg" alt="Black Sertus Logo" class="sertus-logo lg mb-lg-12 mb-md-10 mb-8" />
						</div>

						<h1 class="h4 text-lg-start text-center text-secondary mb-0">Admin | Reset Your Password</h1>
						<h2 class="text-lg-start text-center mb-lg-10 mb-8">Sertus Portal</h2>

						<form @submit.prevent="submit" id="register-form" class="form-2-col">
							<div class="row justify-content-end">
								<div class="col-12 mb-md-8 mb-6">
									<div class="form-row">
										<InputLabel for="email" value="Email" />
										<TextInput
											id="email"
											type="email"
											v-model="form.email"
											placeholder-value="Email address"
											required
											autofocus
											autocomplete="email"
											tabindex="1"
										/>
										<InputError class="mt-2" :message="form.errors.email" />
									</div>
								</div>

								<div class="col-lg-6 col-12 mb-md-8 mb-6">
									<div class="form-row">
										<InputLabel for="password" value="Password" />
										<TextInput
											id="password"
											type="password"
											class="mt-1 block w-full"
											v-model="form.password"
											placeholder-value="Password"
											required
											autocomplete="new-password"
										/>
										<InputError class="mt-2" :message="form.errors.password" />
									</div>
								</div>

								<div class="col-lg-6 col-12 mb-md-8 mb-6">
									<div class="form-row">
										<InputLabel for="password_confirmation" value="Confirm Password" />
										<TextInput
											id="password_confirmation"
											type="password"
											class="mt-1 block w-full"
											v-model="form.password_confirmation"
											placeholder-value="Confirm Password"
											required
											autocomplete="new-password"
										/>
										<InputError class="mt-2" :message="form.errors.password_confirmation" />
									</div>
								</div>

								<div class="col-12">
									<div class="d-flex flex-column align-items-end">
										<PrimaryButton
											class="ml-4"
											id="register-submit"
											:class="{ 'disabled': form.processing }"
											:disabled="form.processing"
											tabindex="6"
										>
											Set new password
										</PrimaryButton>
									</div>
								</div>
							</div>
						</form>

						<h5 class="text-center text-secondary mb-0 mt-4" v-if="status">{{ status }}</h5>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script setup>
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import { Head, useForm } from '@inertiajs/vue3';

const props = defineProps({
	email: String,
	token: String,
	status: String,
});

const form = useForm({
	token: props.token,
	email: props.email,
	password: '',
	password_confirmation: '',
});

const submit = () => {
	form.post(route('admin.password.update'), {
		onFinish: () => form.reset('password', 'password_confirmation'),
	});
};
</script>
