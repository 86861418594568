<template>
	<Head :title="category.name"></Head>
	<AdminLayout>
		<section id="content-panel" class="single-category py-lg-10 py-8 px-xl-16 px-lg-8 px-4">
			<div class="row mb-md-8 mb-6">
				<div class="col-12">
					<div class="dashboard-title-block">
						<h1 class="h3 text-lg-start text-center mb-0 me-4">
							Category:
							<span class="text-secondary">{{ category.name }}</span>
						</h1>

						<button class="btn-outline-quaternary text-quaternary mt-lg-0 mt-4 ms-auto" @click="remove">
							<span>Delete category</span>
						</button>
					</div>
				</div>
			</div>
			<form method="POST" @submit.prevent="save" class="row justify-content-xl-start justify-content-center">
				<div class="col-xxxl-auto col-md-10 col-12 mb-xxxl-0 mb-md-8 mb-6">
					<h5 class="mb-2">Category Icon</h5>
					<div class="icon-upload-container">
						<div class="preview-container mb-2 p-3">
							<img :src="props.category.icon_path" alt="Category icon preview" id="icon-preview">
						</div>

						<label for="cat-icon" class="btn-outline-primary text-primary icon-upload">
							<span>Upload Icon</span>
						</label>
						<input type="file" name="cat-icon" id="cat-icon" accept="image/*" @change="previewImage" ref="icon">
					</div>
				</div>
				
				<div class="col-xxxl-4 col-xl-6 col-md-10 col-12 mb-xl-0 mb-md-8 mb-6">
					<label class="h5 mb-2" for="name">Category name</label>
					<TextInput class="text-field" name="name" :placeholder-value="category.name ? category.name : 'Add a category name (required) ...'" v-model="form.name"></TextInput>
					<InputError class="mt-2" :message="form.errors.name"></InputError>
				</div>
				<div class="col-xxxl-4 col-xl-6 col-md-10 col-12">
					<label class="h5 mb-2" for="description">Category snippet</label>
					<textarea class="text-field" name="description" :placeholder="category.description ? category.description : 'Add a custom category description ...'" v-model="form.description" rows="10"></textarea>
					<InputError class="mt-2" :message="form.errors.description"></InputError>
				</div>
				<div class="col-12 mt-xl-6 mt-4">
					<div class="d-flex align-items-end border-top border-1 border-senary pt-4 w-100">
						<Link :href="$route('admin.categories')" class="me-4">
							<button class="btn-underline-secondary text-secondary fw-normal" type="button">
								<span>Back to Categories list</span>
							</button>
						</Link>
						<button class="btn-outline-primary text-primary ms-auto" type="submit">
							<span>Save changes</span>
						</button>
					</div>
				</div>
			</form>
		</section>
	</AdminLayout>
</template>

<script setup>
import AdminLayout from "@layouts/AdminLayout.vue";
import TextInput from "@js/Components/TextInput.vue";
import InputError from "@js/Components/InputError.vue";
import { Head, Link, useForm, router } from "@inertiajs/vue3";
import InputLabel from "@js/Components/InputLabel.vue";
import { ref } from "vue";

const props = defineProps({
	'category' : Object
});

const icon = ref(null);
const form = useForm({
	'id' : props.category.id,
	'cat_icon' : null,
	'name': props.category.name,
	'description': props.category.description,
})

const save = () => {
	form.post(route('admin.update-category'), {
		onSuccess: (response) => {
			window.Toast.fire({
				icon: 'success',
				title: 'Category updated!',
				timer: 3000
			});
		},
		onError: () => {
			console.log(form.errors)
			window.Toast.fire({
				icon: 'error',
				title: 'Category update failed!',
				timer: 3000
			});
		}
	});
}

const remove = () => {
	let id = props.category.id;

	Swal.fire({
		icon: 'question',
		title: 'Delete category?',
		text: 'Are you sure you want to delete this category?',
		showConfirmButton: true,
		showDenyButton: true,
		confirmButtonText: 'Delete',
		denyButtonText: 'Cancel'
	})
	.then(response => {
		if(response.isConfirmed) {
			router.delete(route('admin.delete-category', {'category' : id}), {
				onSuccess: () => {
					window.Toast.fire({
						icon: 'success',
						title : 'Category deleted!',
						timer: 3000
					});
				},
				onError: error => {
					console.error(error);
					window.Toast.fire({
						icon: 'error',
						title : 'Category deletion failed!',
						timer: 3000
					})
				}
			});
		}
	});
}
const previewImage = (event) => {
	if(event.target.files.length > 0){
		let src = URL.createObjectURL(event.target.files[0]);
		let preview = document.getElementById("icon-preview");
		preview.src = src;
		preview.style.display = "block";
		form.cat_icon = icon.value.files[0];
	}
}
</script>