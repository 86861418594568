<template>
	<AdminLayout>
		<section id="content-panel" class="py-lg-10 py-8 px-xl-16 px-lg-8 px-4">
			<div class="row mb-md-8 mb-6">
				<div class="col-12">
					<div class="dashboard-title-block">
						<h1 class="h3 text-lg-start text-center mb-0">Quote #: <span class="text-secondary">{{ quote.sertus_quote_num }}</span></h1>
					</div>
				</div>
			</div>

			<div class="row mb-lg-8 mb-6 mx-lg-n8" id="quote-details">
				<div class="col-lg-auto col-md-6 col-12 flex-fill px-lg-8 mb-2">
					<div class="d-flex flex-fill align-items-center">
						<i class="fa-duotone fa-user theme-sertus me-2 pb-1"></i>
						<span class="fw-bold">{{ quote.customer_name }}</span>
					</div>
				</div>

				<div class="d-lg-none offset-md-6"></div>

				<div class="col-xxxl-2 col-lg-auto col-md-6 col-12 px-lg-8 mb-2">
					<div class="d-flex flex-fill align-items-center justify-content-xxxl-end">
						<span class="fw-bold me-2">Date:</span>
						<span class="text-senary text-lg-start text-end ms-xxxl-4 ms-auto">{{ quote.quoted_on }}</span>
					</div>
				</div>

				<div class="col-xxxl-2 col-lg-auto col-md-6 col-12 px-lg-8 mb-2">
					<div class="d-flex flex-fill align-items-center justify-content-xxxl-end">
						<span class="fw-bold me-2">Value:</span>
						<span class="text-senary text-lg-start text-end ms-xxxl-4 ms-auto">£{{ quote.quote_amt }}</span>
					</div>
				</div>
			</div>

			<div class="row mb-6">
				<div class="col-12">
					<h3 class="h5 text-lg-start text-center text-secondary fw-extra mb-0">Quote Items</h3>
				</div>
			</div>

			<div class="row mb-lg-8 mb-6">
				<div class="col-12">
					<div class="table-container border-0 d-flex justify-content-center">
						<table id="quote-items-table">
							<tbody>
								<tr v-for="line in quote.line_items">
									<td>
										<span class="">{{ line.part_name }} - <span class="text-secondary">{{ line.part_num }}</span></span>
										<div class="d-xxl-none d-flex align-items-center mt-2">
											<span>Price</span>
											<span class="text-secondary fw-bold ms-4">£{{ line.total_price }} <span class="text-primary fw-light fs-6">({{ line.expected_quantity }})</span></span>
										</div>
										<div class="d-md-none d-block mt-4" v-if="$can('edit quotes')">
											<template v-if="!line.discount_edit">
												<div class="d-flex justify-content-end">
													<button class="btn-disc-edit btn-outline-secondary text-primary" @click="editDiscount(line)">
														<span>Edit Discount</span>
													</button>
												</div>
											</template>
											<template v-else>
												<div class="d-flex align-items-center justify-content-end">
													<input type="number" class="discount-field" placeholder="10%" min="0" max="100" :id="'discount-edit-' + line.id" :value="line.discount_percent" @input="updatePricing(line)">
													<button class="btn-save btn-outline-primary text-primary ms-2" @click="updateDiscount(line)"></button>
													<button class="btn-cancel btn-outline-secondary text-secondary ms-2" @click="cancelEdit(line)"></button>
												</div>
											</template>
										</div>
									</td>
									<td class="d-xxl-table-cell d-none">
										<span class="me-2">Price</span>
										<span class="text-secondary fw-bold ms-auto">£{{ line.total_price }} <span class="text-primary fw-light fs-6">({{ line.expected_quantity }})</span></span>
									</td>
									<td class="d-md-table-cell d-none" v-if="$can('edit quotes')">
										<template v-if="!line.discount_edit">
											<div class="d-flex justify-content-end">
												<button class="btn-disc-edit btn-outline-secondary text-primary" @click="editDiscount(line)" :class="{'disabled' : editLock }">
													<span>Edit Discount</span>
												</button>
											</div>
										</template>
										<template v-else>
											<div class="d-flex align-items-center">
												<input type="number" class="discount-field" placeholder="10%" min="0" max="100" :id="'discount-edit-' + line.id" :value="line.discount_percent" @input="updatePricing(line)">
												<button class="btn-save btn-outline-primary text-primary ms-2" @click="updateDiscount(line)"></button>
												<button class="btn-cancel btn-outline-secondary text-secondary ms-2" @click="cancelEdit(line)"></button>
											</div>
										</template>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-md-6 order-md-1 order-2">
					<Link v-bind:href="$route('admin.quotes')">
						<button class="btn-underline-secondary py-0">
							<i class="fa-regular fa-chevron-left text-primary me-2 pb-1"></i>
							<span>Back to quotes list</span>
						</button>
					</Link>
				</div>
				<div class="col-md-6 order-md-2 order-1 mb-md-0 mb-4">
					<div class="d-flex justify-content-end">
						<h5 class="total mb-0">Quote Total: <span class="text-secondary order-cost ms-2">£{{ quote.quote_amt }}</span></h5>
					</div>
				</div>
			</div>

			<div class="row mt-4">
				<div class="col-12">
					<div class="d-flex flex-row flex-wrap justify-content-md-end justify-content-start" id="quote-actions">
						<a href="tel: 00000000000"> </a>

						<a :href="'mailto:' + quote.contact_email" class="ms-md-4 mt-md-0 mt-4">
							<button class="btn-octenary text-tertiary">
								<span>Email Customer</span>
							</button>
						</a>
					</div>
				</div>
			</div>

			<template v-if="logs.length > 0 && $can('edit quotes')">
				<div class="row mt-lg-12 mt-md-10 mt-8">
					<div class="col-xxxl-10 col-12">
						<h5 class="text-lg-start text-center mb-4">Last 10 logs for this quote:</h5>
						<table id="log-table">
							<thead>
								<tr>
									<th>User</th>
									<th>Log</th>
									<th class="d-xl-table-cell d-none">Logged</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="log in logs">
									<td>
										<p class="d-md-none d-block mb-2">
											{{ log.log }}
										</p>
										<p class="d-md-block d-none mb-xl-0 mb-2">
											{{ log.user }}
										</p>
										<p class="d-md-none d-block fw-bold mb-2">
											User: <span class="text-secondary fw-normal ms-2">{{ log.user }}</span>
										</p>
										<p class="d-xl-none d-block fw-bold mb-0">
											Logged at: <span class="text-secondary fw-normal ms-2">{{ log.log_created }}</span>
										</p>
									</td>
									<td class="d-md-table-cell d-none">{{ log.log }}</td>
									<td class="d-xl-table-cell d-none">{{ log.log_created }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</template>
		</section>
	</AdminLayout>
</template>

<script setup>
import AdminLayout from '@layouts/AdminLayout.vue';
import { Link } from '@inertiajs/vue3';
import { ref } from "vue";
import { usePrice } from "@js/Composables/pricing";

const {
	castFloat
} = usePrice();

const props = defineProps({
	'quote': Object
});

const quote = ref(props.quote.data);
const logs = ref(props.quote.data.logs);
const ogDiscount = ref(0);
const ogFinal = ref(0);
const ogTotal = ref(0);
const editLock = ref(false);

const editDiscount = line => {
	ogDiscount.value = line.discount_percent;
	ogFinal.value = line.total_price;
	ogTotal.value = quote.value.quote_amt;
	editLock.value = true;
	line.discount_edit = true;
}
const cancelEdit = line => {
	line.discount_percent = ogDiscount.value;
	line.total_price = ogFinal.value;
	quote.value.quote_amt = ogTotal.value;
	ogDiscount.value = 0;
	ogFinal.value = 0;
	ogTotal.value = 0;
	editLock.value = false;
	line.discount_edit = false;
}
const updateDiscount = line => {
	console.log(line);
	let line_id =  line.id;
	let new_discount = line.discount_percent;

	Swal.fire({
		icon:'info',
		title: 'Updating ...',
		text: 'Please wait whilst we update the discount for this line item',
		allowEnterKey: false,
		allowEscapeKey: false,
		allowOutsideClick: false,
		showConfirmButton: false,
		showDenyButton: false
	});

	axios.post(route('admin.update-quote-discount'), {
		'line_id' : line_id,
		'new_discount': new_discount
	})
	.then(response => response.data)
	.then(data => {
		setTimeout(() => {
			Swal.close();
			logs.value = data.logs;
			line.discount_edit = false;
			ogDiscount.value = 0;
			ogFinal.value = 0;
			ogTotal.value = 0;
			editLock.value = false;

			window.Toast.fire({
				icon: 'success',
				title: 'Discount updated!',
				timer: 3000
			});
		}, 250);
	})
	.catch(error => {
		console.log(error);
		let data = error.response.data;
		if(data.api_failed) {
			window.Toast.fire({
				icon: 'error',
				title: 'API Sync failed: ' + data.message,
				text: 'Quote API update failed. Automatically retrying.',
				timer: 5000
			});

			setTimeout(() => {
				Swal.fire({
					icon:'info',
					title: 'Retrying ...',
					text: 'Please wait whilst we retry the sycn with Epicor.',
					allowEnterKey: false,
					allowEscapeKey: false,
					allowOutsideClick: false,
					showConfirmButton: false,
					showDenyButton: false
				});

				axios.post(route('admin.retry-quote-sync'), {
					'line_id' : line_id,
				})
				.then(response => response.data)
				.then(data => {
					setTimeout(() => {
						Swal.close();
						logs.value = data.logs;
						line.discount_edit = false;
						ogDiscount.value = 0;
						ogFinal.value = 0;
						ogTotal.value = 0;
						editLock.value = false;

						window.Toast.fire({
							icon: 'success',
							title: 'Discount updated!',
							timer: 3000
						});
					}, 250);
				})
				.catch(error => {
					Swal.fire({
						icon: 'error',
						title: 'Sync failed again,',
						text: 'The sync to push the quote updates to Epicor failed for a second time. Please reach out to tech support with your quote number for assistance with this issue!',
						confirmButtonText: 'Okay'
					});

					line.discount_edit = false;
					ogDiscount.value = 0;
					ogFinal.value = 0;
					ogTotal.value = 0;
					editLock.value = false;
				});
			}, 1000)
		}
		else {
			window.Toast.fire({
				icon: 'error',
				title: 'Quote update failed',
				timer: 3000
			});
		}
	})
}

const updatePricing = (line) => {
	line.discount_percent = event.target.value;
	let discount = line.discount_percent;
	// if discount is more than 100 then just say the value is 0
	if(discount > 100) {
		line.total_price = 0;
	}
	else {
		// get the discount vlaue
		let discount_value = (line.expected_unit_price * (discount / 100));
		let final_price = ogFinal.value;
		// get the final price by
		final_price = (line.expected_unit_price - discount_value) * line.expected_quantity;
		line.total_price = final_price.toFixed(2);
	}

	let quote_total = quote.value.line_items.reduce((carry, item) => {
		let price = castFloat(item.total_price);
		return carry + price;
	}, 0);
	quote_total = quote_total.toFixed(2);
	quote.value.quote_amt = quote_total;
}
</script>
