<template>
	<Head title="Edit Address"></Head>
	<ClientLayout>
		<Breadcrumbs></Breadcrumbs>
		<ImpersonationBanner :impersonating="is_impersonating"></ImpersonationBanner>

		<section id="content-panel" class="my-account py-lg-16 py-10 px-xl-16 px-lg-8 px-4" :class="{impersonating: is_impersonating}">
			<div class="row mb-lg-10 mb-8">
				<div class="col-12">
					<h1 class="h2 text-lg-start text-center mb-0">Edit Address</h1>
				</div>
			</div>

			<form @submit.prevent="editAddress" id="update-address-form">
				<div class="row mx-md-n2 mb-lg-12 mb-md-10 mb-8">
					<div class="col-xxl-5ths col-xl-4 col-md-6 col-12 px-md-2 mb-4 mb-4">
						<TextInput
							id="address-name"
							type="text"
							v-model="form.name"
							placeholder-value="Give this address a name"
							required
							autofocus
							tabindex="1"
						></TextInput>
						<InputError class="mt-2" :message="form.errors.name"></InputError>
					</div>
				</div>

				<div class="row mx-md-n2 mb-lg-12 mb-md-10 mb-8 mt-n4">
					<div class="col-xxl-5ths col-xl-4 col-md-6 col-12 px-md-2 mb-4 mb-4">
						<TextInput
								id="contact_name"
								type="text"
								v-model="form.contact_name"
								placeholder-value="Contact Name"
								required
								autofocus
								tabindex="2"
						></TextInput>
						<InputError class="mt-2" :message="form.errors.contact_name"></InputError>
					</div>
					<div class="col-xxl-5ths col-xl-4 col-md-6 col-12 px-md-2 mb-4 mb-4">
						<TextInput
								id="contact_number"
								type="text"
								v-model="form.contact_number"
								placeholder-value="Contact Number"
								required
								autofocus
								tabindex="3"
						></TextInput>
						<InputError class="mt-2" :message="form.errors.contact_number"></InputError>
					</div>
				</div>

				<div class="row mx-md-n2 mb-lg-12 mb-md-10 mb-8 mt-n4">
					<div class="col-xxl-5ths col-xl-4 col-md-6 col-12 px-md-2 mb-4 mb-4">
						<TextInput
								id="address_1"
								type="text"
								v-model="form.address_1"
								placeholder-value="Building No./Name"
								required
								autofocus
								tabindex="2"
						></TextInput>
						<InputError class="mt-2" :message="form.errors.address_1"></InputError>
					</div>
					<div class="col-xxl-5ths col-xl-4 col-md-6 col-12 px-md-2 mb-4 mb-4">
						<TextInput
								id="address_2"
								type="text"
								v-model="form.address_2"
								placeholder-value="Stret (optional)"
								autofocus
								tabindex="3"
						></TextInput>
						<InputError class="mt-2" :message="form.errors.address_2"></InputError>
					</div>
				</div>

				<div class="row mx-md-n2 mb-lg-12 mb-md-10 mb-8 mt-n4">
					<div class="col-xxl-5ths col-xl-4 col-md-6 col-12 px-md-2 mb-4 mb-4">
						<TextInput
								id="address_3"
								type="text"
								v-model="form.address_3"
								placeholder-value="Town (optional)"
								autofocus
								tabindex="4"
						></TextInput>
						<InputError class="mt-2" :message="form.errors.address_3"></InputError>
					</div>
					<div class="col-xxl-5ths col-xl-4 col-md-6 col-12 px-md-2 mb-4 mb-4">
						<TextInput id="city" type="text" v-model="form.city" placeholder-value="City" required autofocus tabindex="5"></TextInput>
						<InputError class="mt-2" :message="form.errors.city"></InputError>
					</div>
				</div>

				<div class="row mx-md-n2 mb-lg-12 mb-md-10 mb-8 mt-n4">
					<div class="col-xxl-5ths col-xl-4 col-md-6 col-12 px-md-2 mb-4 mb-4">
						<TextInput id="state" type="text" v-model="form.state" placeholder-value="State (optional)" autofocus tabindex="6"></TextInput>
						<InputError class="mt-2" :message="form.errors.state"></InputError>
					</div>
					<div class="col-xxl-5ths col-xl-4 col-md-6 col-12 px-md-2 mb-4 mb-4">
						<TextInput id="zip" type="text" v-model="form.zip" placeholder-value="Postcode" required autofocus tabindex="7"></TextInput>
						<InputError class="mt-2" :message="form.errors.zip"></InputError>
					</div>
				</div>

				<div class="row mx-md-n2 mb-lg-12 mb-md-10 mb-8 mt-n4">
					<div class="col-xxl-5ths col-xl-4 col-md-6 col-12 px-md-2 mb-4 mb-4">
						<TextInput id="country" type="text" v-model="form.country" placeholder-value="Country" required autofocus tabindex="8"></TextInput>
						<InputError class="mt-2" :message="form.errors.country"></InputError>
					</div>
				</div>

				<div class="row mx-md-n2 mb-xxl-25 mb-lg-20 mb-md-16 mb-12">
					<div class="col-12 px-md-2 mb-4">
						<h5 class="text-lg-start text-center text-secondary mb-0">
							Full Address:
							<span class="text-primary ms-4">{{ compileAddress() }}</span>
						</h5>
					</div>
					<div class="col-xxl-5ths col-xl-4 col-md-6 col-12 px-md-2 mb-4">
						<button class="btn-secondary text-tertiary fw-normal pt-5 pb-4 w-100" type="submit">
							<span>
								<i class="fa-regular fa-plus text-quaternary mb-n1 me-3"></i>
								Save Address
							</span>
						</button>
					</div>
				</div>
			</form>

			<div class="row">
				<div class="col-auto">
					<button class="btn-underline-secondary" id="update-address" @click="back">
						<i class="fa-regular fa-chevron-left text-primary mb-n1 me-3"></i>
						<span>Back to Address list</span>
					</button>
				</div>
			</div>
		</section>
	</ClientLayout>
</template>

<script setup>
import ClientLayout from '@layouts/ClientLayout.vue';
import Breadcrumbs from '@/Components/Breadcrumbs.vue';
import TextInput from '@/Components/TextInput.vue';
import ImpersonationBanner from "@js/Components/ImpersonationBanner.vue";
import { Head, Link, useForm, usePage, router } from '@inertiajs/vue3';
import { computed, ref } from 'vue';
import InputError from "@js/Components/InputError.vue";

const props = defineProps({
	address: Object
});

const address = ref(props.address.data);
const form = useForm({
	id: address.value.id,
	name: address.value.name,
	contact_name: address.value.contact_name,
	contact_number: address.value.contact_number,
	address_1: address.value.address_1,
	address_2: address.value.address_2,
	address_3: address.value.address_3,
	city: address.value.city,
	state: address.value.state,
	zip: address.value.zip,
	country: address.value.country,
	favourite: address.value.favourite
});

const is_impersonating = computed(() => usePage().props.global.is_impersonating);

const compileAddress = () => {
	let address = "";

	if(form.address_1 === '') {
		address += 'HOUSE # REQUIRED, '
	}
	else {
		address += form.address_1 + ', ';
	}

	if(form.address_2 !== "") {
		address += form.address_2 + ', ';
	}
	if(form.address_3 !== "") {
		address += form.address_3 + ', ';
	}

	if(form.city === '') {
		address += 'CITY REQUIRED, '
	}
	else {
		address += form.city + ', ';
	}

	if(form.state !== "") {
		address += form.state + ', ';
	}

	if(form.zip === '') {
		address += 'POSTCODE REQUIRED, '
	}
	else {
		address += form.zip + ', ';
	}

	if(form.country === '') {
		address += 'COUNTRY REQUIRED, '
	}
	else {
		address += form.country;
	}
	return address;
}
const editAddress = () => {
	Swal.fire({
		icon: 'question',
		title: 'Save changes?',
		text: 'Please confirm that you would like to save the changes you have made to this address.',
		showConfirmButton: true,
		confirmButtonText: 'Save changes',
		showDenyButton: true,
		denyButtonText: 'Discard changes'
	})
	.then(result => {
		if(result.isConfirmed) {
			Swal.close();
			Swal.fire({
				icon: 'info',
				title:'Saving',
				text: 'Please wait whilst we save your changes!',
				showConfirmButton: false,
				showDenyButton: false,
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false
			});

			form.post(route('account.addresses.update'), {
				onSuccess: data => {
					setTimeout(() => {
						Swal.close();
						Swal.fire({
							icon: 'success',
							title:'Success',
							text: 'The changes made to your address have been saved. Thank you!',
							confirmButtonText: 'Okay',
						})
						.then(response => {
							if(response.isDismissed || response.isConfirmed) {
								router.get(route('account.addresses.manage'));
							}
						});
					}, 500)
				},
				onError: () => {
					Swal.close();
					Swal.fire({
						icon: 'error',
						title: 'Update failed',
						text: 'Sorry, we could not update your address! Please make sure that you have fixed all errors on the form andt try again!',
						confirmButtonText: 'Okay'
					});
				}
			});
		}

		if(result.isDenied) {
			form.reset();
		}
	});
}

const back = () => {
	Swal.fire({
		icon: 'question',
		title: 'Are you sure?',
		text: 'By returning to the address list page, you will lose any changes that have been made to the current address. Please confirm to continue',
		showConfirmButton: true,
		confirmButtonText: 'Go back',
		showDenyButton: true,
		denyButtonText: 'Keep editing'
	})
	.then(result => {
		if(result.isConfirmed) {
			router.get(route('account.addresses.manage'));
		}
	})
}
</script>
