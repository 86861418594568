<template>
	<Head title="Email Verification" />

	<section class="form-page" style="background-image: url('/storage/images/Login hero image.jpg')">
		<div class="container py-xl-20 py-md-16 py-12 h-100">
			<div class="row justify-content-center h-100">
				<div class="col-xl-6 col-lg-8 col-md-10 col-12 d-flex flex-column justify-content-center align-items-start h-100">
					<div class="form-wrapper px-lg-12 px-md-8 px-6 py-xl-20 py-lg-16 py-12">
						<h1 class="h2 text-center">
							Verify your Email
						</h1>
						<div class="wysiwyg text-center">
							<p class="mb-4">
								Thanks for signing up! Before getting started, we need you to verify your email address by clicking on the link we just emailed to you? If you didn't
								receive the email, we will gladly send you another.
							</p>

							<template v-if="verificationLinkSent">
								<h5 class="text-secondary text-center">A new verification link has been sent to the email address you provided during registration.</h5>
							</template>
						</div>

						<form @submit.prevent="submit" class="mt-4">
							<div class="d-flex justify-content-center">
								<PrimaryButton :class="{ 'disabled': form.processing }" :disabled="form.processing"> Resend Verification Email </PrimaryButton>

								<Link :href="route('logout')" method="post" class="d-block ms-4">
									<button class="btn-outline-primary">
										<span>Log Out</span>
									</button>
								</Link>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script setup>
import { computed } from 'vue';
import GuestLayout from '@/Layouts/GuestLayout.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import { Head, Link, useForm } from '@inertiajs/vue3';

const props = defineProps({
	status: String,
});

const form = useForm({});
const verificationLinkSent = computed(() => props.status === 'verification-link-sent');

const submit = () => {
	form.post(route('verification.send'));
};
</script>