<template>
	<Head title="Search Results"></Head>
	<section id="client-portal">
		<Navbar></Navbar>
		<Breadcrumbs></Breadcrumbs>

		<section id="content-panel" class="py-lg-16 py-10 px-xl-16 px-lg-8 px-4">
			<div class="row">
				<div class="col-12">
					<h1 class="h2 text-lg-start text-center mb-lg-10 mb-md-8 mb-6">
						Search Results for: <span class="text-secondary">"{{ query }}"</span>
					</h1>
				</div>
			</div>

			<div class="row mt-lg-12 mt-md-10 mt-8 mb-n8">
				<template v-if="results.length > 0 ">
					<div class="col-xl-5ths col-lg-4 col-md-6 col-12 mb-8" v-for="result in results">
						<div class="product-card py-xl-4 px-xxxl-4">
							<Link class="product-card-link" :href="$route('variation-part-product', {'group' : result.prod_group_id, 'variation': result.id})">
								<Thumbnail :images="result.images"></Thumbnail>
								<div class="d-flex flex-column">
									<h5 class="text-primary text-lg-start text-center fw-bold mb-auto">{{ result.name }} - <span class="text-secondary">{{ result.sertus_part_num }}</span></h5>
									<p class="text-lg-start text-center text-quaternary fw-light text-uppercase mt-2 mb-2">{{ result.prod_code }}</p>
									<p class="price text-lg-start text-center text-secondary fw-extra mb-lg-8 mb-6">
										£{{ result.final_price }}
									</p>
								</div>
								<div class="mt-auto">
									<button class="btn-outline-primary w-100">
										<span>Configure</span>
									</button>
								</div>
							</Link>
						</div>
					</div>
				</template>
				<template v-else>
					<div class="col-auto">
						<h3 class="text-lg-start text-center mb-0">
							No search results found!
						</h3>
						<div class="d-flex justify-content-lg-start justify-content-center mt-lg-6 mt-4">
							<Link :href="$route('catalogue')">
								<button class="btn-outline-primary text-secondary">
									<span>Continue browsing the catalogue</span>
								</button>
							</Link>
						</div>
					</div>
				</template>
			</div>
		</section>
	</section>
</template>

<script setup>
import { Head, Link } from "@inertiajs/vue3";
import { ref } from "vue";
import Navbar from "@js/Components/Navbar.vue";
import Breadcrumbs from "@js/Components/Breadcrumbs.vue";
import Thumbnail from "@js/Components/Thumbnail.vue";

const props = defineProps({
	'results' : Object,
	'query' : String
});

const results = ref([]);
</script>