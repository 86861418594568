<template>
	<Head title="Admin | Login"></Head>
	<section>
		<div class="row gx-md-0">
			<div class="col-xl-3 col-lg-4 col-md-5 col-12 order-md-1 order-2">
				<div
					class="d-flex flex-column justify-content-center bg-tertiary px-lg-12 px-md-8 px-6 py-lg-25 py-md-20 py-16 w-100 h-100"
				>
					<div class="d-flex justify-content-lg-start justify-content-center mb-auto">
						<img src="/storage/images/Sertus Logo.svg" alt="Black Sertus Logo" class="sertus-logo lg mb-lg-12 mb-md-10 mb-8" />
					</div>

					<h1 class="h4 text-lg-start text-center text-secondary mb-0">Administration</h1>
					<h2 class="text-lg-start text-center mb-0">Sertus Portal</h2>

					<form id="login-form" class="mt-lg-20 mt-md-16 mt-12 mb-auto" @submit.prevent="submit">
						<div class="form-row">
							<label for="email" class="text-quaternary mb-2">Email</label>
							<input type="text" name="email" v-model="form.email" placeholder="Email" tabindex="1" />
							<InputError class="mt-2" v-bind:message="form.errors.email"></InputError>
						</div>

						<div class="form-row">
							<label for="passwprd" class="text-quaternary mb-2">Password</label>
							<input type="password" name="password" v-model="form.password" placeholder="Password" tabindex="2" />
							<InputError class="mt-2" v-bind:message="form.errors.password"></InputError>
						</div>

						<div class="form-row">
							<button class="login-button mt-8 w-100" type="submit" :disabled="form.processing" tabindex="3"></button>

							<div class="d-flex justify-content-end w-100 mt-2" v-if="can_reset_password">
								<Link v-bind:href="$route('admin.password.request')" tabindex="4">
									<button class="btn-underline-primary fw-light">
										Forgotten your <span class="text-quaternary ms-1">password?</span>
									</button>
								</Link>
							</div>

							<progress v-if="form.progress" :value="form.progress.percentage" max="100">
								{{ form.progress.percentage }}
							</progress>

							<div class="mt-2" v-if="status">
								<p class="fw-bold text-lg-start text-center text-secondary mb-0">{{ status }}</p>
							</div>
						</div>
					</form>
				</div>
			</div>
			<div class="col-xl-9 col-lg-8 col-md-7 col-12 order-md-2 order-1">
				<div class="img-block">
					<div class="login-img" style="background-image: url('/storage/images/Login hero image.jpg')" b></div>
				</div>
			</div>
		</div>
	</section>
</template>

<script setup>
import { Head, Link, useForm } from '@inertiajs/vue3';
import InputError from '@/Components/InputError.vue';

const props = defineProps({
	'can_reset_password': Boolean,
	'status': String,
});

// creating the form using the inbuilt inertia form helper
const form = useForm({
	email: null,
	password: null,
	remember: false,
});

// override the default form submission action in favour of using inertia to post to the server
const submit = () => {
	// reset the password input box in the UI if we fail the submission
	form.post(route('admin.login'), {
		onError: () => form.reset('password'),
		onFinish: () => form.reset('password'),
	});
};
</script>
