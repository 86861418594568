<template>
	<Head title="Address Management"></Head>
	<ClientLayout>
		<Breadcrumbs></Breadcrumbs>
		<ImpersonationBanner :impersonating="is_impersonating"></ImpersonationBanner>

		<section id="content-panel" class="my-account py-lg-16 py-10 px-xl-16 px-lg-8 px-4" :class="{impersonating: is_impersonating}">
			<div class="row mb-lg-10 mb-8">
				<div class="col-12">
					<h1 class="h2 text-lg-start text-center mb-0">Your Delivery Addresses</h1>
				</div>
			</div>

			<div class="row">
				<div class="col-12 mb-6">
					<div class="d-flex justify-content-end">
						<Link :href="$route('account.addresses.add')">
							<button class="btn-secondary text-tertiary fw-normal pt-5 pb-4 px-xl-20 px-lg-16 px-md-12 px-10">
								<span>
									<i class="fa-regular fa-plus text-quaternary mb-n1 me-3"></i>
									Add Address
								</span>
							</button>
						</Link>
					</div>
				</div>
			</div>

			<div class="row mb-n2" id="address-rows">
				<div class="col-12 address-row-container mb-2" v-for="address in addresses">
					<div class="address-row px-4 pt-4 pb-3">
						<div class="row">
							<div class="col-xl-2 col-md-8 col-12 border-right">
								<div class="d-flex flex-column justify-content-center h-100">
									<h5 class="text-secondary mb-xl-0 mb-1">{{ address.name }}</h5>

									<p class="d-xl-none d-block fs-5 me-4 mb-md-9 mb-2">{{ address.compiled }}</p>

									<div class="d-md-none d-flex flex-column align-items-end">
										<button class="btn-underline-senary favourite-button" :class="{'favourite': address.favourite}" @click="toggleFavourite(address.id)">
											<i class="fa-regular fa-star text-secondary"></i>
											<span>Add to Favourites</span>
										</button>

										<button class="btn-underline-senary" @click="deleteAddress(address.id)">
											<i class="fa-duotone fa-trash theme-sertus"></i>
											<span>Remove</span>
										</button>

										<Link :href="$route('account.addresses.edit', {'address': address.id})">
											<button class="btn-underline-senary">
												<i class="fa-regular fa-wrench mb-n1"></i>
												<span>Edit</span>
											</button>
										</Link>
									</div>
								</div>
							</div>

							<div class="col-6 d-xl-block d-none border-right">
								<div class="d-flex align-items-center h-100">
									<p class="fs-5 me-4 mb-0">{{ address.compiled }}</p>

									<Link :href="$route('account.addresses.edit', {'address': address.id})" class="d-block ms-auto">
										<button class="btn-underline-senary py-0">
											<i class="fa-regular fa-wrench mb-n1"></i>
											<span>Edit</span>
										</button>
									</Link>
								</div>
							</div>

							<div class="col-2 d-xxl-block d-none border-right">
								<div class="d-flex align-items-center h-100">
									<button class="btn-underline-senary" @click="deleteAddress(address.id)">
										<i class="fa-duotone fa-trash theme-sertus"></i>
										<span>Remove</span>
									</button>
								</div>
							</div>

							<div class="col-xxl-2 d-md-block d-none col-4">
								<div class="d-flex flex-xxl-row flex-column align-items-xxl-center align-items-start h-100">
									<button class="btn-underline-senary favourite-button" :class="{'favourite': address.favourite}" @click="toggleFavourite(address.id)">
										<i class="fa-regular fa-star text-secondary"></i>
										<span v-if="address.favourite">Remove Favourite</span>
										<span v-else>Add to Favourites</span>
									</button>

									<button class="btn-underline-senary d-xxl-none d-block" @click="deleteAddress(address.id)">
										<i class="fa-duotone fa-trash theme-sertus"></i>
										<span>Remove</span>
									</button>

									<Link :href="$route('account.addresses.edit', {'address': address.id})">
										<button class="btn-underline-senary d-xl-none d-block">
											<i class="fa-regular fa-wrench mb-n1"></i>
											<span>Edit</span>
										</button>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-auto mt-lg-10 mt-8">
					<Link :href="$route('account.overview')">
						<button class="btn-underline-secondary">
							<i class="fa-regular fa-chevron-left text-primary mb-n1 me-3"></i>
							<span>Back</span>
						</button>
					</Link>
				</div>
			</div>
		</section>
	</ClientLayout>
</template>

<script setup>
import ClientLayout from '@layouts/ClientLayout.vue';
import Breadcrumbs from '@/Components/Breadcrumbs.vue';
import ImpersonationBanner from "@js/Components/ImpersonationBanner.vue";
import { Head, Link, usePage } from '@inertiajs/vue3';
import { computed, ref } from "vue";

const props = defineProps({
	'addresses': Object
});

const addresses = ref(props.addresses.data);
const is_impersonating = computed(() => usePage().props.global.is_impersonating);

const deleteAddress = id => {
	Swal.fire({
		icon:'question',
		title: 'Are you sure?',
		text: 'Are you sure you want to delete this address? You will not be able to use this in future orders unless you re-add it via your account.',
		showConfirmButton: true,
		confirmButtonText: 'Delete',
		showDenyButton: true,
		denyButtonText: 'Cancel',
	})
	.then(result => {
		if(result.isConfirmed) {
			axios.delete(route('account.addresses.delete', { 'address': id }))
			.then(response => response.data)
			.then(data => {
				window.Toast.fire({
					icon:'success',
					title: 'Address deleted',
					timer: 3000
				});
				console.log(data);
				addresses.value = data.addresses;
			})
			.catch(error => {
				window.Toast.fire({
					icon: 'error',
					title: 'Address deletion failed',
					timer: 3000
				});
			})
		}
	})
}

const toggleFavourite = id => {
	console.log(id);

	// TODO create the toggle favourite route and the fn to back it
	axios.post(route('account.addresses.toggle-favourite'), {
		'address_id' : id
	})
	.then(response => response.data)
	.then(data => {
		let index = addresses.value.findIndex(item => item.id === data.address.id);
		addresses.value[index] = data.address;
	});
}
</script>
