<template>
	<Head title="Set Project Name"></Head>
	<section id="client-login" class="form-page">
		<div class="bg-container">
			<video autoplay muted loop poster="/storage/images/pod-b-roll-1.jpg" src="/storage/videos/pod-b-roll-2.mp4"></video>
			<div class="bg-poster" style="background-image: url('/storage/images/pod-b-roll-1.jpg')"></div>
		</div>
		<div class="container">
			<div class="row justify-content-lg-start justify-content-center">
				<div class="col-xxl-4 col-xl-5 col-lg-6 col-md-8 col-sm-10 col-12">
					<div class="bg-tertiary py-xl-20 py-md-16 py-12 px-xl-16 px-md-12 px-8">
						<div class="d-flex justify-content-lg-start justify-content-center mb-lg-10 mb-8">
							<ApplicationLogo></ApplicationLogo>
						</div>

						<h1 class="h2 text-lg-start text-center mb-4">Portal</h1>
						<h3 class="text-lg-start text-center mb-2">Project Selection</h3>

						<template v-if="data.resume_basket_allowed">
							<p class="fw-light text-lg-start text-center mb-lg-8 mb-6">
								Please choose whether you would like to resume your current basket or
								start a new project from scratch.
							</p>
						</template>
						<template v-else>
							<p class="fw-light text-lg-start text-center mb-lg-8 mb-6">
								Please choose a name for your project before proceeding. This can be changed later
								from the 'full basket' page of the portal.
							</p>
						</template>

						<div class="mb-6">
							<div class="radio-container" v-if="data.resume_basket_allowed">
								<input type="radio" name="project_select" id="continue_project" :value="false" v-model="new_project" @change="reset">
								<label for="continue_project">Use Saved Project</label>
							</div>
							<div class="radio-container mt-4">
								<input type="radio" name="project_select" id="new_project" :value="true" v-model="new_project" @change="reset">
								<label for="new_project">Start New Project</label>
							</div>
						</div>

						<form @submit.prevent="create_new" v-if="new_project">
							<div class="form-row mb-6">
								<InputLabel class="fw-bold" for="project_name" value="New Project Name" />
								<TextInput
										id="project_name"
										type="project_name"
										v-model="create_form.project_name"
										placeholder-value="e.g Sertus office project ..."
										tabindex="1"></TextInput>
								<InputError class="mt-2" :message="create_form.errors.project_name" remove-margin="true" />
							</div>

							<div class="form-row d-flex flex-column align-items-end">
								<button class="login-button w-100" type="submit"></button>
							</div>
						</form>
						<form @submit.prevent="select_existing" v-else>
							<div class="select-container mb-6">
								<InputLabel class="fw-bold mb-2" for="project-select" value="Select Existing Project" />
								<select name="project-select" id="project-select" v-model="existing_form.existing_project_id">
									<option value="" selected disabled>Please select a project ...</option>
									<option v-for="project in projects" :key="project.id" :value="project.id">{{ project.name }}</option>
								</select>
								<InputError class="mt-2" :message="existing_form.errors.existing_project_id" remove-margin="true"/>
							</div>
							<div class="form-row d-flex flex-column align-items-end">
								<button class="login-button w-100" type="submit"></button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script setup>
import ApplicationLogo from '@/Components/ApplicationLogo.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import TextInput from '@/Components/TextInput.vue';
import { Head, Link, useForm } from '@inertiajs/vue3';
import { ref } from "vue";

defineProps({
	'data': Object,
	'projects': Array
})

const new_project = ref(false )

const create_form = useForm({
	project_name: '',
});
const existing_form = useForm({
	existing_project_id: ''
});

const create_new = () => {
	create_form.post(route('create-new-project'), {
		onSuccess: () => create_form.reset(),
	});
};

const select_existing = () => {
	existing_form.post(route('select-existing-project'),  {
		onSuccess: () => existing_form.reset(),
	});
}

const reset = () => {
	create_form.reset();
	create_form.clearErrors();
	existing_form.reset();
	existing_form.clearErrors();
}
</script>
