<template>
	<div class="product-card py-xl-4 px-xxxl-4" :class="{ 'no-stock': false}">
		<Link class="product-card-link" :href="$route('bom-product', {'id': part.id})">
			<Thumbnail :images="part.images"></Thumbnail>

			<div class="d-flex flex-column">
				<h5 class="text-primary text-lg-start text-center fw-light mb-auto">{{ part.description }}</h5>
				<p class="text-lg-start text-center text-quaternary fw-light text-uppercase mt-2 mb-2">{{ part.prod_code }}</p>
			</div>
			<div class="mt-auto">
				<h5 class="text-lg-start text-center text-primary mb-4">
					<span class="fw-normal">
						Category:
					</span>
					{{ part.category }}
				</h5>
				<button class="btn-outline-primary w-100">
					<span>Configure</span>
				</button>
			</div>
		</Link>
	</div>
</template>

<script setup>
import Thumbnail from "@js/Components/Thumbnail.vue";
import { Link } from '@inertiajs/vue3';
import { ref } from "vue";

const props = defineProps({
	'part':  Object
})
const part = ref(props.part);
</script>