import {usePrice} from "@js/Composables/pricing";
const { castFloat } = usePrice();

// initial state
// shape: [{ id, name, unit_price, discount_percent, discount_value, is_discounted, quantity }]
const state = {
	basket_id: null,
	project_name: '',
	items: [],
};

// getters
const getters = {
	basketId: (state) => {
		return state.basket_id
	},

	basketName: (state) => {
		return state.project_name;
	},

	basketItems: (state, getters, rootState) => {
		return state.items;
	},

	basketCount: (state, getters) => {
		return getters.basketItems.reduce((accumulator, value) => accumulator + value.quantity, 0);
	},

	basketTotalPrice: (state, getters) => {
		return getters.basketItems.reduce((total, product) => {
			let price = product.final_price;
			if(typeof price === 'string' || price instanceof String) {
				price = price.replace(",", "");
			}
			price = Number(price).toFixed(2);
			return total + (price * product.quantity);
		}, 0);
	},
};

// actions
const actions = {
	async createQuote({ commit, state }, products) {
		return await axios.post(route('basket.generate-quote'), {
			'basket_id' : state.basket_id
		});
	},

	changeName({commit, state}, newName) {
		commit('setBasketName', newName);
	},

	fetchBasket({ state, commit }) {
		axios.get(route('basket.fetch'))
		.then(response => response.data)
		.then(data => {
			const items = data.items;
			commit('setBasketId', data.id);
			commit('setBasketName', data.name);
			commit('setBasketItems', { items });
		});
	},

	addToBasket({ state, commit }, product ) {
		axios.post(route('basket.add-item'),  {
			'id' : product.id,
			'unit_price': castFloat(product.unit_price),
			'discount_percent': product.discount_percent,
			'discount_value': castFloat(product.discount_value),
			'is_discounted': product.is_discounted,
			'prod_code': product.prod_code,
			'images' : product.images
		})
		.then(response => response.data)
		.then(data => {
			const basketItem = state.items.find((item) => item.id === product.id);
			if (!basketItem) {
				console.log(data);
				commit('pushProductToBasket', {
					id: data.id,
					name: data.name,
					part_num: data.part_num,
					prod_code: data.prod_code,
					unit_price: data.unit_price,
					discount_percent: data.discount_percent,
					discount_value: data.discount_value,
					final_price: data.final_price,
					is_discounted: data.is_discounted,
					images: data.images
				});
			} else {
				commit('incrementItemQuantity', basketItem);
			}
		})
		.catch(error => console.log(error));
	},

	removeFromBasket({ state, commit }, product) {
		const basketItem = state.items.find((item) => item.id === product.id);
		if (basketItem) {
			if (basketItem.quantity === 1) {
				axios.delete(route('basket.fully-remove-item', { id: product.id}))
				.then(response => response.data)
				.then(data => {
					console.log(data);
					commit('removeProductFromBasket', { id: product.id });
				});
			} else {
				axios.put(route('basket.reduce-quantity'), {
					id: product.id
				})
				.then(response => response.data)
				.then(data => {
					console.log(data);
					commit('decrementItemQuantity', basketItem);
				})
			}
		}
	},

	fullyRemoveFromBasket({ state, commit }, product) {
		const basketItem = state.items.find((item) => item.id === product.id);
		if(basketItem) {
			axios.delete(route('basket.fully-remove-item', { id: product.id}))
			.then(response => response.data)
			.then(data => {
				console.log(data);
				commit('removeProductFromBasket', { id: product.id });
			});
		}
	},

	clearBasket({ state, commit }) {
		state.items.forEach((item, index) => {
			commit("removeProductFromBasket", {id: item.id});
		});
	},
};

// mutations
const mutations = {
	pushProductToBasket(state, { id, name, part_num, prod_code, unit_price, discount_percent, discount_value, final_price, is_discounted, images }) {
		state.items.push({
			id,
			discount_value,
			discount_percent,
			final_price: final_price,
			is_discounted,
			name,
			part_num,
			unit_price,
			quantity: 1,
			prod_code,
			images
		});
	},
	removeProductFromBasket(state, { id }) {
		state.items = state.items.filter((item) => item.id !== id);
	},

	incrementItemQuantity(state, { id }) {
		const basketItem = state.items.find((item) => item.id === id);
		basketItem.quantity++;
	},
	decrementItemQuantity(state, { id }) {
		const basketItem = state.items.find((item) => item.id === id);
		basketItem.quantity--;
	},

	setBasketId(state, id) {
		if(id) {
			state.basket_id = id;
		}
	},

	setBasketName(state, name) {
		if(name) {
			state.project_name = name;
		}
	},

	setBasketItems(state, { items }) {
		if(items) {
			state.items = items.map(item => {
				return {
					id: item.part_id,
					discount_percent: item.discount_percent,
					discount_value: item.discount_value,
					final_price: item.final_price,
					is_discounted: item.is_discounted,
					name: item.name,
					part_num: item.part_num,
					prod_code: item.prod_code,
					thumbnail: item.thumbnail,
					unit_price: item.unit_price,
					quantity: item.quantity,
					images: item.images
				}
			});
		}
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
