<template>
	<Head title="Thank You"></Head>
	<section id="client-portal">
		<Navbar></Navbar>
		<Breadcrumbs></Breadcrumbs>

		<section id="content-panel" class="py-lg-16 py-10 px-xl-16 px-lg-8 px-4">
			<div class="row justify-content-center">
				<div class="col-xxl-4 col-xl-6 col-lg-8 col-md-10 col-12 mb-xl-0 mb-lg-16 mb-md-12 mb-10">
					<div class="d-flex flex-column align-items-xl-start align-items-center mb-xl-12 mb-8">
						<i class="fa-duotone fa-thumbs-up fs-1 theme-sertus mb-xl-12 mb-md-6 mb-4"></i>
						<h1 class="h2 text-xl-start text-center mb-0">Thank you for your order!</h1>
					</div>

					<div class="wysiwyg text-xl-start text-center mb-xl-10 mb-6">
						<p class="mb-4">
							Thank you for placing an order with us. Our team will begin to process your order shortly and we will
							reach out to you with further details surrounding delivery of your order in due course.
						</p>
						<h5 class="text-secondary mb-">In the meantime, why not:</h5>
					</div>

					<div class="d-flex flex-md-row flex-column">
						<Link class="d-block flex-fill mb-md-0 mb-4" :href="$route('catalogue')">
							<button class="btn-secondary text-tertiary py-5 w-100">
								<span>Start a new Order / Quote</span>
							</button>
						</Link>
						<Link class="d-block flex-fill ms-md-4" :href="$route('account.your-orders')">
							<button class="btn-primary text-tertiary py-5 w-100">
								<span>View your order history</span>
							</button>
						</Link>
					</div>
				</div>

				<div class="offset-xxl-2 col-xxl-6 col-12 mt-8">
					<div class="border border-primary px-6 py-lg-12 py-md-10 py-8">
						<div class="d-flex align-items-center mb-xl-10 mb-lg-8 mb-6 w-100">
							<h3 class="d-flex align-items-center justify-content-lg-start justify-content-center text-lg-start text-center mb-0">
								<i class="fa-duotone fa-list theme-sertus theme-invert fs-2 me-lg-6 me-4 pb-1"></i>
								Your Latest Order Details
							</h3>

							<span class="d-none h3 text-senary fw-normal ms-auto mb-0">Project name</span>
						</div>

						<OrderSummary :data="order.data"></OrderSummary>
					</div>
				</div>
			</div>
		</section>
	</section>
</template>

<script setup>
import Breadcrumbs from '@/Components/Breadcrumbs.vue';
import Navbar from '@/Components/Navbar.vue';
import OrderSummary from '@/Components/LineItemsSummary.vue';
import { Head, Link } from '@inertiajs/vue3';
import { onMounted, ref } from "vue";
import { useStore } from "vuex";

const store = useStore();
onMounted(() => store.dispatch('basket/clearBasket'));

const props = defineProps({
	'order' : Object
});

const order = ref(props.order);
</script>
