<template>
	<AdminLayout>
		<section id="content-panel" class="py-lg-10 py-8 px-xl-16 px-lg-8 px-4">
			<div class="row mb-md-8 mb-6">
				<div class="col-12">
					<div class="dashboard-title-block">
						<h1 class="h3 text-lg-start text-center mb-lg-0 mb-2">View Quotes</h1>
						<p class="text-lg-start text-center ms-lg-auto mb-0">Review currrent quote requests.</p>
					</div>
				</div>
			</div>

			<div class="row mb-6">
				<div class="col-6">
					<h3 class="h5 text-lg-start text-center text-secondary fw-extra mb-0">Quote List</h3>
				</div>
				<div class="col-auto ms-auto" v-if="$can('export quotes')">
					<button class="btn-outline-secondary text-secondary download-btn" type="submit" @click="download">
						<span>Download quote data</span>
					</button>
				</div>
			</div>

			<div class="row">
				<div class="col-auto ms-auto d-xxl-none d-block mb-lg-6 mb-4">
					<Bootstrap5Pagination :data="quotes" @pagination-change-page="changePage" :limit="2"/>
				</div>

				<div class="col-12">
					<div class="table-head bg-primary py-3 px-6 pe-md-3">
						<div class="d-flex flex-md-row flex-column align-items-md-center">
							<span class="text-tertiary fs-6 mb-md-n1 mb-3">
								Showing <span class="text-quaternary">{{ from }}</span>
								to <span class="text-quaternary">{{ to }}</span>
								of <span class="text-quaternary">{{ totalQuotes }}</span>
							</span>

							<div class="pagination-wrapper d-xxl-flex d-none align-items-center">
								<span class="text-tertiary mb-n1"> Page </span>
								<div class="ms-8">
									<Bootstrap5Pagination :data="quotes" @pagination-change-page="changePage" :limit="2"/>
								</div>
							</div>

							<div class="search-wrapper">
								<input
										type="text"
										name="quote-search"
										id="quote-search"
										placeholder="Search by Customer or Quote #..."
										v-model="form.search"
										@keyup="submitSearch"
								/>
								<i class="fa-duotone fa-magnifying-glass theme-sertus"></i>
							</div>
						</div>
					</div>

					<div class="table-container d-flex justify-content-center">
						<table id="view-quotes-table">
							<tbody>
							<tr v-for="quote in quotes.data">
								<td>
									<div class="d-flex align-items-center mb-n1">
										<span class="oneline me-2"> Quote #: </span>
										<span class="text-secondary fw-bold me-xxxl-4 ms-auto"> {{ quote.sertus_quote_num }} </span>
									</div>
								</td>

								<td>
									<span class="d-block mb-n1">{{ quote.customer_name }}</span>

									<div class="d-xxl-none d-flex align-items-center mb-n1 mt-2">
										<span class="me-2">Date:</span>
										<span class="text-secondary fw-bold ms-sm-0 ms-auto me-xxxl-4">{{ quote.quoted_on }}</span>
									</div>

									<div class="d-xxl-none d-flex align-items-center mb-n1 mt-2">
										<span class="me-2">Value:</span>
										<span class="text-secondary fw-bold ms-sm-0 ms-auto me-xxxl-4">£{{ quote.quote_amt }}</span>
									</div>

									<div class="d-md-none d-flex flex-column mt-2">
										<div class="d-flex align-items-center flex-fill mb-n1">
											<span class="me-2">Items:</span>
											<span class="text-secondary fw-bold ms-sm-0 ms-auto">{{ quote.line_item_count }}</span>
										</div>
										<Link class="ms-auto mt-4" v-bind:href="$route('admin.single-quote', {'id': quote.id})">
											<button class="btn-outline-secondary"><span>View Quote</span></button>
										</Link>
									</div>
								</td>

								<td>
									<div class="d-flex align-items-center mb-n1">
										<span class="me-2">Date:</span>
										<span class="text-secondary fw-bold ms-auto me-xxxl-4">{{ quote.quoted_on }}</span>
									</div>

									<div class="d-xxxl-none d-flex align-items-center mb-n1 mt-2">
										<span class="me-2">Value:</span>
										<span class="text-secondary fw-bold ms-auto me-xxxl-4">£{{ quote.quote_amt }}</span>
									</div>
								</td>

								<td>
									<div class="d-flex align-items-center mb-n1">
										<span class="me-2">Value:</span>
										<span class="text-secondary fw-bold ms-auto me-xxxl-4">£{{ quote.quote_amt }}</span>
									</div>
								</td>

								<td>
									<div class="d-flex align-items-center">
										<div class="flex-fill mb-n1 me-4">
											<span class="me-2">Items:</span>
											<span class="text-secondary fw-bold ms-auto">{{ quote.line_item_count }}</span>
										</div>
										<Link class="ms-auto" v-bind:href="$route('admin.single-quote', {'id': quote.id })">
											<button class="btn-outline-secondary"><span>View Quote</span></button>
										</Link>
									</div>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div class="col-auto ms-auto d-xxl-none d-block mt-lg-6 mt-4">
					<Bootstrap5Pagination :data="quotes" @pagination-change-page="changePage" :limit="2"/>
				</div>
			</div>
		</section>
	</AdminLayout>
</template>

<script setup>
import AdminLayout from '@layouts/AdminLayout.vue';
import { Link, useForm } from '@inertiajs/vue3';
import { ref } from "vue";
import Bootstrap5Pagination from "laravel-vue-pagination/src/Bootstrap5Pagination.vue";

const quotes = ref({});
const oldQuotes = ref(quotes.value);
const currentPage = ref(1);
const from = ref(1);
const to = ref(0);
const totalQuotes = ref(0);

const form = useForm({
	search: ''
});

const submitSearch = async () => {
	// if we do not happen to have a search term then we set the order to the current page's orders
	if(form.search === '') {
		changePage(currentPage.value);
	}
	else {
		// otherwise we need to get the right results for the search term
		const response = await axios.get(route('admin.quote-search', {'query': form.search}));
		oldQuotes.value = quotes.value;
		quotes.value = response.data;
	}
}

const changePage = async (page = 1) => {
	const response = await axios.get(`/sertus-admin/paginate-quotes?page=${ page }`);
	quotes.value = response.data;
	console.log(quotes.value);
	from.value = response.data.meta.from;
	to.value = response.data.meta.to;
	totalQuotes.value = response.data.meta.total;
	currentPage.value = page;
}
changePage();

const download = () => {
	axios.post(route('admin.export-quotes'))
	.then(response => {
		console.log(response);
		return response.data;
	})
	.then(data => {
		const url = window.URL.createObjectURL(new Blob([data]))
		const link = document.createElement('a')
		link.href = url
		link.setAttribute('download', 'quote_export.csv');
		document.body.appendChild(link)
		link.click();
	});
}
</script>
