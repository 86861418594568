
import { computed } from "vue";
import { router } from "@inertiajs/vue3";
import { useStore } from "vuex";

export function useBasket() {
	const store = useStore();

	const basketName = computed(() => store.getters['basket/basketName']);
	const basketItems = computed(() => store.getters['basket/basketItems']);
	const basketCount = computed(() => store.getters['basket/basketCount']);
	const total = computed(() => store.getters['basket/basketTotalPrice']);

	const checkout = () => router.get(route('checkout'));

	function clearProject() {
		Swal.fire({
			icon: 'info',
			title: 'Clearing Basket Project ...',
			text: 'Please wait whilst we clear your basket project',
			showConfirmButton: false,
			showDenyButton: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			allowOutsideClick: false
		});

		axios.post(route('basket.clear-project'))
		.then(response => response.data)
		.then(data => {
			setTimeout(() => {
				Swal.close();
				store.dispatch('basket/fetchBasket');
			});
		})
		.catch(error => {
			Swal.close();
			Swal.fire({
				icon: 'error',
				title: 'Could not Clear Project',
				text: 'Something went wrong whilst clearing your project Please try again and if the problem continues then you should contact tech support.',
				confirmButtonText: 'Okay'
			});
		})
	}

	function increaseQty(item) {
		store.dispatch('basket/addToBasket', item);
	}

	function newQuote() {
		Swal.fire({
			icon: 'question',
			title: 'Create quote?',
			text: 'Are you sure you would like to create a quote with the items currently in your project?',
			confirmButtonText: 'Yes',
			showDenyButton: true,
			denyButtonText: 'No',
			allowOutsideClick: false,
		})
		.then(response => {
			if(response.isConfirmed) {
				Swal.fire({
					icon: 'info',
					title: 'Generating quote ...',
					text: 'Please hold tight whilst we generate your quote for you. You will be redirected once the process has finished!',
					allowOutsideClick: false,
					allowEnterKey: false,
					allowEscapeKey: false,
					showDenyButton: false,
					showConfirmButton: false
				});

				store.dispatch('basket/createQuote', basketItems.value)
				.then(response => response.data)
				.then(data => {
					setTimeout(() => {
						Swal.close();
						let new_quote_id = data.new_quote_id;
						router.get(route('account.single-quote', {'quote': new_quote_id}));
					}, 750);
				})
				.catch(error => {
					Swal.close();
				});
			}
		})
		.catch(error => {
			Swal.close();
		});
	}

	function refreshBasket() {
		store.dispatch('basket/fetchBasket');
	}

	function reduceQty(item) {
		store.dispatch('basket/removeFromBasket', item);
	}

	function removeBasketItem(item) {
		store.dispatch('basket/fullyRemoveFromBasket', item);
	}

	return {
		basketName,
		basketItems,
		basketCount,
		total,
		checkout,
		clearProject,
		increaseQty,
		newQuote,
		reduceQty,
		refreshBasket,
		removeBasketItem
	}
}