<template>
	<AdminLayout>
		<section id="content-panel" class="py-lg-10 py-8 px-xl-16 px-lg-8 px-4">
			<div class="row mb-md-8 mb-6">
				<div class="col-12">
					<div class="dashboard-title-block">
						<h1 class="h3 text-lg-start text-center mb-lg-0 mb-2">User Management</h1>
						<p class="text-lg-start text-center ms-lg-auto mb-0">Manager and impersonate users.</p>
					</div>
				</div>
			</div>

			<div class="row mb-lg-6 mb-4">
				<div class="col-12">
					<h3 class="h5 text-lg-start text-center text-secondary fw-extra mb-0">List of Users</h3>
				</div>
			</div>

			<div class="row">
				<div class="col-auto ms-auto d-xxl-none d-block mb-lg-6 mb-4">
					<Bootstrap5Pagination :data="users" @pagination-change-page="changePage" :limit="2"/>
				</div>

				<div class="col-12 mb-xxl-0 mb-lg-6 mb-4">
					<div class="table-head bg-primary p-4 py-lg-3 ps-lg-6 pe-lg-3">
						<div class="row justify-content-end">
							<div class="col-md-6 col-12 mb-xl-0 mb-4">
								<div class="d-flex align-items-center h-100">
									<span class="text-tertiary fs-6 mb-md-n1 mb-3">
										Showing <span class="text-quaternary">{{ from }}</span>
										to <span class="text-quaternary">{{ to }}</span>
										of <span class="text-quaternary">{{ totalUsers }}</span> (including Admins)
									</span>
								</div>
							</div>

							<div class="col-md-6 col-12 mb-xl-0 mb-4">
								<div id="user-filters">
									<button class="admin-filter" @click="toggleFilter('admin', $event)" :class="{ selected: activeFilters.includes('admin') }">
										<span class="ms-4 mb-n1"> Administrator </span>
									</button>
									<button class="customer-filter ms-4" @click="toggleFilter('contact', $event)" :class="{ selected: activeFilters.includes('contact') }">
										<span class="ms-4 mb-n1"> Customer </span>
									</button>
								</div>
							</div>

							<div class="col-md-6 col-12 d-xl-block d-none mb-xl-0 mb-4">
								<div class="pagination-wrapper d-flex align-items-center h-100">
									<span class="text-tertiary mb-n1"> Page </span>
									<div class="ms-8">
										<Bootstrap5Pagination :data="users" @pagination-change-page="changePage" :limit="2"/>
									</div>
								</div>
							</div>

							<div class="col-xl-6 col-12 mx-xl-auto mx-0 mt-xl-4">
								<div class="search-wrapper h-100 w-100">
									<input
										type="text"
										class="h-100 w-100"
										name="user-search"
										id="user-search"
										placeholder="Search by name or email ..."
										autocomplete="off"
										v-model="form.search"
										@keyup="submitSearch"
									/>
									<i class="fa-duotone fa-magnifying-glass theme-sertus"></i>
								</div>
							</div>
						</div>
					</div>

					<div class="table-container">
						<table id="user-manangement-table" style="table-layout: auto">
							<tbody>
								<tr v-for="user in users.data">
									<td class="d-md-table-cell d-none">
										<div class="d-flex align-items-center" v-if="user.type === 'admin'">
											<i class="fa-duotone fa-user theme-sertus pb-1"></i>
											<i class="fa-solid fa-plus text-secondary fs-6 me-lg-6 me-4 pb-3"></i>
											<span class="fw-bold mb-n1">Administrator</span>
										</div>
										<div class="d-flex align-items-center" v-else>
											<i class="fa-duotone fa-user theme-sertus me-lg-8 me-6 pb-1"></i>
											<span class="fw-bold mb-n1">Customer</span>
										</div>
									</td>
									<td class="d-md-table-cell d-none">
										<span class="mb-n1">{{ user.name }}</span>
									</td>
									<td class="d-md-table-cell d-none">
										<div class="d-flex align-items-center">
											<div class="d-flex align-items-center mb-n1 me-auto">
												<span class="me-4">Email:</span>
												<span class="text-secondary fw-bold ms-auto">{{ user.email }}</span>
											</div>

											<template v-if="$can('impersonate')">
												<Link :href="$route('impersonate.start', { 'id': user.id })" v-if="user.type !== 'admin'">
													<button class="btn-outline-primary d-flex align-items-center ms-4">
														<i class="fa-duotone fa-user-group theme-sertus me-2"></i>
														<span>Impersonate</span>
													</button>
												</Link>
											</template>
											<Link class="ms-4" v-bind:href="$route('admin.single-admin', {'user': user.id})" v-if="user.type === 'admin'">
												<button class="btn-outline-secondary">
													<span>View</span>
												</button>
											</Link>
											<Link class="ms-4" v-bind:href="$route('admin.single-contact', {'contact': user.id})" v-else>
												<button class="btn-outline-secondary">
													<span>View</span>
												</button>
											</Link>
										</div>
									</td>
								</tr>

								<tr v-show="users.length === 0">
									<td>
										<div class="bg-quatenary py-2">
											<h5 class="text-center mb-0">Sorry ... we could not find a matching user's name or email!</h5>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div class="col-auto ms-auto d-xxl-none d-block mt-lg-6 mt-4">
					<Bootstrap5Pagination :data="users" @pagination-change-page="changePage" :limit="2"/>
				</div>
			</div>
		</section>
	</AdminLayout>
</template>

<script setup>
import AdminLayout from '@layouts/AdminLayout.vue';
import { Link, useForm } from '@inertiajs/vue3';
import { ref } from 'vue';
import Bootstrap5Pagination from "laravel-vue-pagination/src/Bootstrap5Pagination.vue";

const users = ref([]);
const oldUsers = ref(users.value);
const currentPage = ref(1)
const from = ref(1)
const to = ref(0)
const totalUsers = ref(0)
const activeFilters = ref([]);

const form = useForm({
	search: ''
})

// NOTE - the function below was written before user pagination was added and so will no longer work for the full data set
/*const submitSearch = function () {
	// if the user has reset the search string then we should reset the users and
	// contacts to the state that the server passed to us on page load
	if (searchQuery.value == '') {
		users.value = props.users;
		_contacts.value = props.contacts;
	} else {
		// filter the users based on either their name or email
		// filter the prop values though so that we are not searching
		// an empty array in the case that the user does not fully clear the search string!
		users.value = props.users.filter((user, key) => {
			let username = user.name.toLowerCase();
			let email = user.email.toLowerCase();

			if (username.includes(searchQuery.value.toLowerCase()) || email.includes(searchQuery.value.toLowerCase())) {
				return true;
			}
			return false;
		});

		// filter the contacts based on either their name or their email
		// filter the prop values though so that we are not searching
		// an empty array in the case that the user does not fully clear the search string!
		_contacts.value = props.contacts.filter((contact, key) => {
			let contactname = contact.first_name + ' ' + contact.last_name;
			contactname = contactname.toLowerCase();
			let email = contact.email.toLowerCase();

			if (contactname.includes(searchQuery.value.toLowerCase()) || email.includes(searchQuery.value.toLowerCase())) {
				return true;
			}
			return false;
		});
	}
};*/

const submitSearch = async () => {
	// if we do not happen to have a search term then we set the order to the current page's orders
	if(form.search === '') {
		changePage(currentPage.value);
	}
	else {
		// otherwise we need to get the right results for the search term
		const response = await axios.get(route('admin.user-search', {'query': form.search}));
		oldUsers.value = users.value;
		console.log(response.data);
		users.value.data = response.data;
	}
}

const toggleFilter = async (type, $event) => {
	$event.preventDefault();

	if(oldUsers.value.length > 0) {
		users.value = oldUsers.value;
	}

	if(activeFilters.value.includes(type)) {
		activeFilters.value.filter(value => {
			return value !== type
		});
	}
	else {
		activeFilters.value.push(type);
	}
	oldUsers.value = users.value;

	const response = await axios.get(route('admin.fetch-users')); // get all users from the server
	users.value = response.data; // ser the user variable with all users
	users.value.data = users.value.filter((user) => {
		return user.type === type
	});
};

const changePage = async (page = 1) => {
	form.reset();
	const response = await axios.get(`/sertus-admin/paginate-users?page=${ page }`);
	users.value = response.data; // get the whole response data object (including  pagination meta data)
	from.value = response.data.from;
	to.value = response.data.to;
	totalUsers.value = response.data.total;
	currentPage.value = page;
}
changePage();
</script>
