<template>
	<Head title="Quote Details"></Head>
	<section id="client-portal">
		<Navbar></Navbar>
		<Breadcrumbs></Breadcrumbs>
		<ImpersonationBanner :impersonating="is_impersonating"></ImpersonationBanner>

		<section id="content-panel" class="py-lg-16 py-10 px-xl-16 px-lg-8 px-4" :class="{impersonating: is_impersonating}">
			<div class="row justify-content-center">
				<div class="col-xxl-4 col-lg-9 col-md-10 col-12 mb-xxl-0 mb-12">
					<h1 class="h2 text-xl-start text-center mb-xl-12 mb-md-10 mb-8">Quote Request <span class="text-secondary">#{{ quote.sertus_quote_num }}</span></h1>
					<div class="wyswigy text-lg-start text-center mb-0">
						<p>
							Here you are able to review your quote and see any updates that Sertus have provided for your quote.
							<br><br>
							You may also use the button below to convert your quote back to a basket and then order the items you saved previously.
						</p>
					</div>

					<div class="row mt-lg-12 mt-md-10 mt-8" >
						<div class="col-lg-6 col-12" v-if="quote.quoted">
							<form @submit.prevent="orderQuote" v-if="!quote.expired">
								<input type="hidden" :value="form.quote_id">
								<button class="btn-secondary text-tertiary fw-bold pt-5 pb-4 w-100" type="submit">
									<span v-if="quote.ordered">Re-order this Quote</span>
									<span v-else>Order this Quote</span>
								</button>
							</form>
							<template v-else>
								<button class="btn-secondary text-tertiary fw-bold pt-5 pb-4 w-100 disabled">
									<span>Quote Expired</span>
								</button>
							</template>
						</div>
						<div class="col-12" v-else>
							<h5 class="text-lg-start text-center text-secondary mb-0">
								Please check back here once your quote has been reviewed by our team to create an order from the quote.
							</h5>
						</div>
					</div>

					<div class="mt-lg-12 mt-md-10 mt-8">
						<div class="d-flex justify-content-lg-start justify-content-center">
							<Link :href="$route('account.your-quotes')">
								<button class="btn-underline-secondary">
									<i class="fa-regular fa-chevron-left text-primary me-2 mb-n1"></i>
									<span>Back to Quote list</span>
								</button>
							</Link>
						</div>
					</div>
				</div>

				<div class="col-xxxl-6 col-xxl-8 col-lg-9 col-md-10 col-12 offset-xxxl-2">
					<div class="border border-primary px-6 py-lg-12 py-md-10 py-8">
						<div class="d-flex align-items-center mb-xl-10 mb-lg-8 mb-6 w-100">
							<h3 class="d-flex align-items-center justify-content-lg-start justify-content-center text-lg-start text-center mb-0">
								<i class="fa-duotone fa-list theme-sertus theme-invert fs-2 me-lg-6 me-4 pb-1"></i>
								Your Quote Details
							</h3>

							<span class="d-block h3 text-senary fw-normal ms-auto mb-0">{{ quote.project_name }}</span>
						</div>

						<LineItems :data="summary"></LineItems>
					</div>
				</div>
			</div>
		</section>
	</section>
</template>

<script setup>
import Breadcrumbs from '@/Components/Breadcrumbs.vue';
import Navbar from '@/Components/Navbar.vue';
import LineItems from '@/Components/LineItemsSummary.vue';
import ImpersonationBanner from "@js/Components/ImpersonationBanner.vue";
import { Head, Link, usePage, useForm } from '@inertiajs/vue3';
import { computed, ref } from 'vue';

const props = defineProps({
	'quote' : Object
});
const quote = ref(props.quote.data);
const summary = ref({
	'items': quote.value.items,
	'total': quote.value.total
});
const is_impersonating = computed(() => usePage().props.global.is_impersonating);
const form = useForm({
	quote_id : quote.value.id
});

const orderQuote = function() {
	Swal.fire({
		icon: 'info',
		title: 'Purchase this Quote?',
		text: 'Are you sure you wish to purchase this quote? You will lose any items currently added to your basket.',
		confirmButtonText: 'Yes',
		denyButtonText: 'No',
		showDenyButton: true
	})
	.then(result => {
		if(result.isConfirmed) {
			Swal.fire({
				icon: 'info',
				title: 'Creating New Basket',
				text: 'Hold tight whilst we generate your new basket from the quote.',
				showConfirmButton: false,
				showDenyButton: false,
				allowEnterKey: false,
				allowEscapeKey: false,
				allowOutsideClick: false
			});

			setTimeout(() => {
				form.post(route('account.purchase-quote'), {
					onSuccess: response => {
						Swal.close();
					},
					onError: () => {
						Swal.close();
						Swal.fire({
							icon: 'error',
							title: 'Quote Purchase Failed',
							text: 'Sorry, we could not convert your quote back to a basket for checkout. Please try again!',
							confirmButtonText: 'Okay'
						});
					}
				});
			}, 500);
		}
	});
}
</script>
