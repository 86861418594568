<template>
	<Head :title="title"></Head>
	<section id="error-page" style="background-image: url('/storage/images/Login hero image.jpg')">
		<header class="bg-primary py-4">
			<div class="container">
				<div class="row">
					<div class="col-6">
						<div class="logo">
							<img src="/storage/images/sertus-logo-white.svg" alt="White Sertus Logo" />
						</div>
					</div>
					<div class="col-6">
						<div class="d-flex align-items-center justify-content-end h-100">
							<Link :href="$route('checkout')">
								<button class="btn-outline-tertiary flip-icon fs-5">
									<span>Back to checkout</span>
								</button>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</header>
		<section class="content-section py-xl-25 py-lg-29 py-md-16 py-12">
			<div class="container h-100">
				<div class="row align-items-center justify-content-center h-100">
					<div class="col-xxl-6 col-lg-8 col-md-10 col-12">
						<div class="content-box bg-tertiary py-xl-16 py-md-12 py-10 px-xl-12 px-md-10 px-8">
							<h1 class="fw-extra text-center text-secondary mb-2">
								Payment Error
							</h1>
							<div class="wysiwyg text-center text-primary fw-normal" v-if="message !== ''">
								<p>{{ message }}</p>
							</div>
							<div class="d-flex flex-lg-row flex-column align-items-center justify-content-center mt-4">
								<Link :href="$route('checkout')">
									<button class="btn-outline-secondary text-secondary">
										<span>Return to Checkout</span>
									</button>
								</Link>

								<Link :href="$route('catalogue')" class="ms-lg-4 mt-lg-0 mt-4">
									<button class="btn-outline-quaternary">
										<span>Continue Browsing</span>
									</button>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</section>
</template>

<script setup>
import { Head, Link } from '@inertiajs/vue3';
import { onMounted } from 'vue';
import { useStore } from "vuex";

const store = useStore();
onMounted(() => store.dispatch('basket/clearBasket'));

const props = defineProps({
	message: String,
});
</script>
