<template>
	<ClientLayout>
		<Head title="Product Catalogue"></Head>
		<Breadcrumbs></Breadcrumbs>
		<ImpersonationBanner :impersonating="is_impersonating"></ImpersonationBanner>

		<section id="content-panel" class="py-lg-16 py-10 px-xl-16 px-lg-8 px-4" :class="{impersonating: is_impersonating}">
			<section>
				<div class="row justify-content-lg-start justify-content-center mb-lg-16 mb-md-12 mb-10">
					<div class="col-xl-6 col-lg-8 col-md-10 col-12">
						<h1 class="h2 text-lg-start text-center">Product Catalogue</h1>
						<div class="wysiwyg text-lg-start text-center text-senary">
							<p>
								Browse our range of products to find the solution that is right for you and your customer's needs. Once you have picked the product that
								you need, you can configure it to exactly suit your requirements!
							</p>
						</div>
					</div>
				</div>

				<div class="row mb-n8">
					<div class="col-xxxl-5ths col-xxl-3 col-xl-4 col-sm-6 col-12 mb-8">
						<div class="cta-tile no-link d-flex align-items-end p-6">
							<h3 class="cta-title text-primary text-start fw-extra mb-0 mt-12 w-100">
								Browse by <br />
								Building Area
							</h3>
						</div>
					</div>

					<div class="col-xxxl-5ths col-xxl-3 col-xl-4 col-sm-6 col-12 mb-8" v-for="category in categories">
						<Link :href="$route('product-category', {'id' : category.id})">
							<div class="category-tile p-4">
								<div class="img-block mb-6">
									<div class="bg-square bg-contain" :style="'background-image: url(' + category.icon_path + ')'"></div>
								</div>
								<h5 class="text-primary text-lg-start text-center mb-2">{{ category.name }}</h5>
								<div class="text-primary wysiwyg text-lg-start text-center">
									<p>{{ category.description }}</p>
								</div>
							</div>
						</Link>
					</div>
				</div>
			</section>

			<section class="border-top border-2 border-primary mt-12 pt-12">
				<div class="row mb-lg-12 mb-10">
					<div class="col-xl-5 col-12 mb-xl-0 mb-4">
						<h2 class="text-md-start text-center mb-0">All Products</h2>
					</div>
					<div class="col-xl-7 col-12 d-none">
						<div class="d-flex justify-content-md-end justify-content-center w-100" id="filters">
							<div class="select-wrapper arrow-end">
								<select name="sort-filter" id="sort-filter">
									<option value="relevance">Sort By: Relevance</option>
									<option value="relevance">Sort By: Price (Low to high)</option>
									<option value="relevance">Sort By: Price (High to low)</option>
									<option value="relevance">Sort By: Some other requirement</option>
								</select>
							</div>
							<div class="select-wrapper arrow-end ms-xl-10 ms-lg-8 ms-6">
								<select name="category-filter" id="category-filter">
									<option value=0>Category: All</option>
									<option :value="category.id" v-for="category in categories">{{ category.name }}</option>
								</select>
							</div>
						</div>
					</div>
				</div>

				<div class="row mb-lg-n12 mb-n8">
					<!-- <div class="col-xxxl-5ths col-xxl-3 col-md-4 col-6 mb-xl-16 mb-lg-12 mb-8">
						<Link class="product-card-link" :href="$route('catalogue')">
							<div class="product-card py-xl-4 px-xxxl-4">
								<div class="img-block mb-6">
									<div class="bg-square" style="background-image: url('/storage/images/fviD7QZkpbra6UTkByC1zUNMBRG4FJSPYZtvc0LT.jpg')"></div>
								</div>
								<div class="d-flex flex-column flex-fill">
									<h5 class="text-primary text-lg-start text-center fw-light mb-auto">BOM product - with product configurator shown</h5>
									<p class="text-lg-start text-center text-quaternary fw-light text-uppercase mt-2 mb-2">SKU HERE</p>
									<p class="price text-lg-start text-center text-secondary fw-extra mb-lg-8 mb-6">£XXX - £YYY</p>

									<h5 class="text-lg-start text-center text-primary fw-normal mb-4">Category Name</h5>
									<button class="btn-outline-primary w-100"><span>Configure</span></button>
								</div>
							</div>
						</Link>
					</div> -->

					<div class="col-xxxl-5ths col-xxl-3 col-md-4 col-6 mb-xl-16 mb-lg-12 mb-8" v-for="part in parts">
						<ProductCard :part="part"></ProductCard>
					</div>
				</div>
			</section>
		</section>
	</ClientLayout>
</template>

<script setup>
import ClientLayout from '@layouts/ClientLayout.vue';
import Breadcrumbs from '@/Components/Breadcrumbs.vue';
import ImpersonationBanner from "@js/Components/ImpersonationBanner.vue";
import ProductCard from "@js/Components/ProductCard.vue";
import { Head, Link, usePage } from '@inertiajs/vue3';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const props = defineProps({
	'categories' : Object,
	'parts': Object
});
const products = computed(() => store.state.products.all);
const is_impersonating = computed(() => usePage().props.global.is_impersonating);
const categories = ref(props.categories);
const parts = ref(props.parts.data);

const addToBasket = (product) => store.dispatch('basket/addToBasket', product);
</script>
