<template>
	<input
		:value="modelValue"
		:placeholder="placeholderValue"
		@input="$emit('update:modelValue', $event.target.value)"
		ref="input"
	/>
</template>

<script setup>
import { onMounted, ref } from 'vue';

defineProps(['modelValue', 'placeholderValue']);
defineEmits(['update:modelValue']);

const input = ref(null);

onMounted(() => {
	if (input.value.hasAttribute('autofocus')) {
		input.value.focus();
	}
});
</script>
