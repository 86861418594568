<template>
	<template v-if="item.is_discounted && showDiscount">
		<div class="item-price fw-bold text-senary mb-n1 ms-auto">
			<s>
				<span class="h6 mb-0">£{{ calcPrice(item.unit_price, item.quantity).pounds }}.</span>
				<span>{{ calcPrice(item.unit_price, item.quantity).pence }}</span>
			</s>
		</div>
		<div class="item-price fw-bold mb-n1 ms-2">
			<span class="h6 mb-0">£{{ calcPrice(item.final_price, item.quantity).pounds }}.</span>
			<span>{{ calcPrice(item.final_price, item.quantity).pence }}</span>
		</div>
	</template>
	<div class="item-price fw-bold mb-n1 ms-auto" v-else>
		<span class="h6 mb-0">£{{ calcPrice(item.final_price, item.quantity).pounds }}.</span>
		<span>{{ calcPrice(item.final_price, item.quantity).pence }}</span>
	</div>
</template>

<script setup>
import { usePrice} from "@js/Composables/pricing";

defineProps({
	item : Object,
	showDiscount : {
		type: Boolean,
		default: true,
	}
});

const {
	calcPrice,
} = usePrice();
</script>