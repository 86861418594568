<template>
	<AdminLayout>
		<section id="content-panel" class="pt-lg-10 pt-8">
			<section class="content-panel-inner px-xl-16 px-lg-8 px-4">
				<div class="row mb-md-8 mb-6">
					<div class="col-12">
						<div class="dashboard-title-block">
							<h1 class="h3 text-lg-start text-center mb-lg-0 mb-2" v-if="reviewed">Edit `{{ part.name }}`</h1>
							<h1 class="h3 text-lg-start text-center mb-lg-0 mb-2" v-else>Inbound Epicor Product</h1>

							<p class="text-lg-start text-center ms-lg-auto mb-0">
								Review the product details from Epicor, add images and extra details, then confirm.
							</p>
						</div>
					</div>
				</div>

				<div class="row mb-lg-8 mb-6">
					<div class="col-12">
						<div class="epicor-details p-6">
							<div class="d-flex flex-lg-row flex-column align-items-center bottom-divider">
								<h4 class="text-start mb-lg-0 mb-4">{{ part.name }}</h4>
								<p class="text-lg-start text-center fs-6 ms-lg-auto mb-lg-0 mb-2">
									These fields are from Epicor and cannot be changed here. They are presented for review purposes only.
								</p>
								<i class="fa-solid fa-lock ms-4 pb-1"></i>
							</div>

							<div class="row mt-8 mb-n4" id="epicor-values">
								<div class="col-xxl-5ths col-lg-4 col-md-6 col-12 mb-4"><span class="text-primary fw-bold me-2">SKU:</span>{{ part.SKU }}</div>
								<div class="col-xxl-5ths col-lg-4 col-md-6 col-12 mb-4"><span class="text-primary fw-bold me-2">Master SKU:</span>{{ part.prod_group.SKU }}</div>
								<div class="col-xxl-5ths col-lg-4 col-md-6 col-12 mb-4"><span class="text-primary fw-bold me-2">Price (ex VAT):</span>£{{ part.unit_price }}</div>
								<div class="col-xxl-5ths col-lg-4 col-md-6 col-12 mb-4"><span class="text-primary fw-bold me-2">Category:</span>{{ part.category }}</div>
								<div class="col-xxl-5ths col-lg-4 col-md-6 col-12 mb-4"><span class="text-primary fw-bold me-2">Width:</span>{{ part.dimensions.width }}mm</div>
								<div class="col-xxl-5ths col-lg-4 col-md-6 col-12 mb-4"><span class="text-primary fw-bold me-2">Height:</span>{{ part.dimensions.height }}mm</div>
								<div class="col-xxl-5ths col-lg-4 col-md-6 col-12 mb-4"><span class="text-primary fw-bold me-2">Length:</span>{{ part.dimensions.length }}mm</div>
								<div class="col-xxl-5ths col-lg-4 col-md-6 col-12 mb-4"><span class="text-primary fw-bold me-2">Net Weight:</span>{{ part.dimensions.net_weight }}kg</div>
							</div>
						</div>
					</div>
				</div>

				<div class="row mb-lg-8 mb-6">
					<div class="offset-lg-2 col-lg-10 col-12">
						<div class="d-flex align-items-start justify-content-md-end">
							<div class="d-flex align-items-start mb-lg-0 mb-2">
								<div class="me-2">
									<i class="fa-light fa-circle-info"></i>
								</div>
								<p class="mb-0">
									This product is a variation of <span class="text-secondary fw-bold">{{ part.prod_group.SKU }}</span> - anything added below
									will override the master product defaults.
								</p>
								<div class="d-flex align-items-center ms-4">
									<i class="fa-solid fa-code-branch row-icon me-2"></i>
									<span class="product-tag">Variation</span>
								</div>
							</div>
						</div>
					</div>

					<div class="col-lg-7 col-12 mt-lg-6 mt-4">
						<section class="mb-lg-10 mb-8">
							<h3 class="h5 text-start mb-lg-6 mb-4">Part Custom Name</h3>
							<template v-if="readonly">
								<h5 class="text-start text-secondary mb-0">{{ part.custom_name }}</h5>
							</template>
							<template v-else>
								<TextInput
										id="custom-name"
										class="fw-bold"
										type="text"
										v-model="partForm.custom_name"
										placeholder-value="Custom part name ..."
								/>
							</template>
						</section>

						<section class="mb-lg-10 mb-8">
							<h3 class="h5 text-start mb-lg-6 mb-4">Product Group Description</h3>
							<div class="master-description py-lg-8 py-6 px-6" v-html="part.prod_group.custom_description"></div>
						</section>

						<section class="mb-lg-10 mb-8">
							<h3 class="h5 text-start mb-lg-6 mb-4">Variation Part Description:</h3>
							<template v-if="readonly">
								<template v-if="part.custom_description !== ''">
									<div class="master-description py-lg-8 py-6 px-6" v-html="part.custom_description">
									</div>
								</template>
								<template v-else>
									<h5 class="text-start text-secondary mb-0">No custom description is available for this part!</h5>
								</template>
							</template>
							<template v-else>
								<editor
										id="product-description"
										api-key="l2rni2rxqi55xico6zhg1sujh0z5h591cxf6qwba2gnv4jql"
										:init="{
										height: 750,
										menubar: true,
										toolbar:
											'undo redo | formatselect | bold italic backcolor | \
											alignleft aligncenter alignright alignjustify | \
											bullist numlist outdent indent | removeformat | help',
										}"
										v-model="partForm.custom_description"
								></editor>
							</template>
						</section>

						<section class="mb-lg-10 mb-8">
							<h3 class="h5 text-start mb-lg-6 mb-4">Product Group Stock Message</h3>
							<template v-if="part.prod_group.stock_message !== ''">
								<div class="master-description py-lg-8 py-6 px-6" v-html="part.prod_group.stock_message"></div>
							</template>
							<template v-else>
								<div class="master-description py-lg-8 py-6 px-6">
									<p>No stock message set at the product group level!</p>
								</div>
							</template>
						</section>

						<section class="mb-lg-10 mb-8">
							<h3 class="h5 text-start mb-lg-6 mb-4">Variation Part Stock Message</h3>
							<template v-if="readonly">
								<template v-if="part.stock_message">
									<div class="master-description py-lg-8 py-6 px-6" v-html="part.stock_message"></div>
								</template>
								<template v-else>
									<h5 class="text-start text-secondary mb-0">
										No stock message found for this part!
									</h5>
								</template>
							</template>
							<template v-else>
								<editor
										id="stock-message"
										api-key="l2rni2rxqi55xico6zhg1sujh0z5h591cxf6qwba2gnv4jql"
										:init="{
										height: 250,
										menubar: false,
										toolbar: 'undo redo',
									}"
										v-model="partForm.stock_message"
										placeholder="E.g Estimated delivery time for this product is 2 working weeks."
								></editor>
							</template>
						</section>
					</div>

					<div class="col-lg-5 col-12 mt-6">
						<template v-if="part.prod_group.images.length > 0">
							<!-- only show master images HTML if we have some images to show -->
							<h3 class="h5 text-start mb-2">Product Group Images</h3>
							<h5 class="text-start text-senary mb-4 fw-normal">These images can be edited by visting the details page for the product group.</h5>
							<div class="product-gallery mb-n2 me-n2">
								<div class="gallery-block img-block p-2 me-2 mb-2" v-for="image in part.prod_group.images">
									<div class="bg-square" :style="'background-image: url(' + image.file_location + ')'"></div>
								</div>
							</div>

							<div class="span d-block border-top border-1 border-primary mt-5 mb-4 w-100"></div>
						</template>

						<h3 class="h5 text-start mb-4">Part Images</h3>
						<div class="product-gallery me-n2">
							<div class="gallery-block img-block p-2 me-2 mb-2" v-for="image in part.images">
								<span class="remove" :id="'attachment-' + image.id" @click="remove_attachment(image.id)" v-if="!readonly"></span>
								<div class="bg-square" :style="'background-image: url(' + image.file_location + ')'"></div>
							</div>
						</div>

						<form id="file-upload-form" class="dropzone inline-dropzone mb-2" :action="$route('admin.part-image-upload')">
							<input type="hidden" id="part_id" name="part_id" :value="part.id">
							<div class="image-box p-2">
								<div class="d-flex flex-column align-items-center" v-if="queueLength === 0">
									<span class="fa-stack fa-2x mb-2">
										<i class="fa-light fa-square fa-stack-2x text-septenary"></i>
										<i class="fa-solid fa-plus fa-stack-1x text-secondary"></i>
									</span>
									<h5 class="text-center text-secondary mb-0">Drag files here to upload ...</h5>
								</div>

								<div class="preview-wrapper d-none">
									<div class="my-2 px-2">
										<div class="img-block bg-tertiary">
											<button class="dz-remove" data-dz-remove></button>
											<img data-dz-thumbnail />
											<div class="dz-progress my-2"><span class="dz-upload" data-dz-uploadprogress></span></div>
										</div>
									</div>
								</div>

								<div id="preview-container" class="d-flex flex-wrap justify-content-center align-items-center mb-n2 mx-n2"></div>
							</div>
						</form>
						<p class="text-senary mb-0">
							This part is a variation product - you can add a master list of images to the product group that this part belongs to
							<Link :href="$route('admin.prod-group-details', { 'group': part.prod_group.id })">
								<span class="text-secondary fw-bold">here</span>.
							</Link>
						</p>
					</div>
				</div>

				<div class="row mb-lg-8 mb-6 mt-lg-10 mt-8">
					<div class="col-auto">
						<div class="d-flex align-items-center">
							<Link :href="$route('admin.part-list')" v-if="reviewed">
								<button class="btn-underline-secondary">
									<i class="fa-regular fa-chevron-left text-primary"></i>
									<span> Back to Part List </span>
								</button>
							</Link>
							<Link :href="$route('admin.epicor-sync')" v-else>
								<button class="btn-underline-secondary">
									<i class="fa-regular fa-chevron-left text-primary"></i>
									<span> Back to Epicor Sync</span>
								</button>
							</Link>
						</div>
					</div>
				</div>
			</section>

			<section id="confirm-banner" v-if="!readonly">
				<div class="row justify-content-center">
					<div class="col-auto">
						<div class="d-flex align-items-center">
							<p class="text-tertiary mb-0" v-if="reviewed">
								Click 'confirm' to save your changes once you have finished editing.
							</p>
							<p class="text-tertiary mb-0" v-else>
								When you've added all your details - confirm this product to add it to the site
							</p>
							<button class="btn-secondary text-tertiary ms-4" @click="confirm_changes"><span>Confirm</span></button>
						</div>
					</div>
				</div>
			</section>
			<div class="confirm-placeholder"></div>
		</section>
	</AdminLayout>
</template>

<script setup>
import AdminLayout from '@layouts/AdminLayout.vue';
import TextInput from "@js/Components/TextInput.vue";
import Editor from '@tinymce/tinymce-vue';
import useDropzone from "@js/Composables/dropzone";
import { Link, useForm } from '@inertiajs/vue3';
import { computed, onMounted, ref } from "vue";
import { router } from "@inertiajs/vue3";

const props = defineProps({
	'part' : Object,
	'readonly' : Boolean,
	'csrf_token': String,
});

const queueLength = ref(0);
const partImages = ref(props.part.data.images);
const { inlineDropzone } = useDropzone(props, partImages, queueLength);

const part = ref(props.part.data);
const dropzone = ref(null);
const reviewed = computed(() => part.reviewed);

const partForm = useForm({
	'id' : part.value.id,
	'custom_name' : part.value.custom_name,
	'custom_description' : part.value.custom_description,
	'stock_message' : part.value.stock_message
});

onMounted(() => {
	dropzone.value = inlineDropzone();
});

const remove_attachment = async attachment_id => {
	let part_id = part.value.id;

	Swal.fire({
		icon: 'info',
		title: 'Deleting image ...',
		text: 'Please wait whilst we delete your image',
		showConfirmButton: false,
		showDenyButton: false,
	});

	await axios.delete(route('admin.remove-part-attachment'), {
		data: {
			'part_id': part_id,
			'attachment_id': attachment_id
		}
	})
	.then(response => {
		setTimeout(() => {
			part.value.images = response.data;
			Swal.close();

			window.Toast.fire({
				icon: 'success',
				title: 'Image deleted successfully'
			});
		}, 1500);
	})
	.catch(error => {
		console.log(error);
		Swal.fire({
			icon: 'error',
			title: 'Image deletion failed!',
			text: 'Sorry, something has gone wrong on our end whilst trying to delete your image. Please try again and then reach out to tech support if the issue persists.',
			showDenyButton: false,
			confirmButtonText: 'Okay'
		});
	});
}

const confirm_changes = () => {
	Swal.fire({
		icon:'info',
		title: 'Saving Now',
		text: 'Hold tight whilst we save the part review data for you. Thanks!',
		showConfirmButton: false,
		showDenyButton: false
	});

	let routeString = 'admin.update-part-details';
	if(!reviewed.value) {
		routeString = 'admin.confirm-part-review';
	}

	partForm.put(route(routeString), {
		onSuccess: response => {
			Swal.close(); // close the 'Syncing' alert window
			window.Toast.fire({
				icon:'success',
				title: 'Part successfully synced or updated!',
				timer: 3000
			});
		},
		onError: error => {
			Swal.close(); // close the 'Syncing' alert window

			console.log(error);
			Swal.fire({
				icon: 'error',
				title: 'Part save failed!',
				text: 'Please try again. If this problem persist then please reach out to technical support!',
				showDenyButton: false,
				showConfirmButton: true,
				confirmButtonText: 'Okay'
			});
		}
	});
}
</script>
