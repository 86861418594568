<template>
	<Head title="Admin | Sync Review"></Head>
	<AdminLayout>
		<section id="content-panel" class="py-lg-10 py-8 px-xl-16 px-lg-8 px-4">
			<div class="row mb-md-8 mb-6">
				<div class="col-12">
					<div class="dashboard-title-block">
						<h1 class="h3 text-lg-start text-center mb-lg-0 mb-2">Epicor Sync Review</h1>
						<p class="text-lg-start text-center ms-lg-auto mb-0">Review and confirm these parts to add them to the portal.</p>
					</div>
				</div>
			</div>

			<div class="row mb-lg-10 mb-6">
				<div class="col-12 mb-lg-6 mb-4">
					<h2 class="h5 fw-extra text-lg-start text-center text-secondary mb-0">
						<i class="fa-duotone fa-inbox theme-sertus me-2"></i>
						Inbound parts
					</h2>
				</div>
				<div class="col-xxl-8 col-lg-6 col-12">
					<div class="d-flex align-items-lg-center align-items-start h-100">
						<div class="me-2">
							<span class="fa-stack fa-1x">
								<i class="fa-solid fa-info fa-stack-1x text-secondary"></i>
								<i class="fa-light fa-circle fa-stack-2x text-septenary"></i>
							</span>
						</div>

						<p class="mb-0">Only bulk review parts that don't need images or a description. Everything else should be reviewed manually!</p>
					</div>
				</div>
				<div class="col-xxl-4 col-lg-6 col-12 mt-lg-0 mt-4">
					<div class="d-flex align-items-center justify-content-lg-end">
						<button class="btn-primary text-tertiary" @click="confirmAll">
							<span>Confirm all</span>
						</button>
						<button class="btn-outline-primary ms-4" @click="confirmSelected">
							<span>Confirm selected</span>
						</button>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<div class="table-container">
						<table id="product-review-table">
							<tbody>
								<!-- MASTER -->
								<tr v-for="part in masters" :key="part.id" :class="{ selected: part.selected }">
									<td>
										<div class="d-flex align-items-center">
											<i class="fa-regular fa-circle-dot table-icon me-3"></i>
											<span class="row-tag"> Master </span>
										</div>
									</td>

									<td>
										<div class="d-flex flex-md-row flex-column align-items-md-center">
											<div class="d-flex flex-xxl-row flex-column flex-fill align-items-xxl-center">
												<h5 class="text-start fw-normal mb-0">{{ part.name }}</h5>
												<div class="sku-container d-flex align-items-center ms-xxl-auto me-xxxl-12 mt-xxl-0 mt-1">
													<span>SKU:</span>
													<span class="fw-bold text-secondary ms-xxxl-10 ms-xl-4 ms-2">{{ part.prod_code }}</span>
												</div>
												<div class="d-xl-none d-flex align-items-center mt-1">
													<span>Variations:</span>
													<span class="fw-bold text-secondary ms-2 me-4">{{ part.variations }}</span>
												</div>
											</div>

											<div class="d-xl-none d-flex align-items-center ms-auto mt-md-0 mt-2">
												<Link v-bind:href="$route('admin.prod-group-review', {'group': part.id})">
													<button class="btn-outline-secondary">
														<span>Review</span>
													</button>
												</Link>
												<div class="checkbox-wrapper ms-4">
													<input
															name="bulk-select"
															type="checkbox"
															:id="'bulk-select-' + part.id"
															v-model="part.selected"
															@input="toggleSelected(part, 'variation')"
													/>
													<label :for="'bulk-select-' + part.id"></label>
												</div>
											</div>
										</div>
									</td>

									<td class="d-xl-table-cell d-none">
										<div class="d-flex align-items-center ms-xxxl-12">
											<span class="oneline">Variations:</span>
											<span class="fw-bold text-secondary ms-4 me-8">{{ part.variations }}</span>

											<div class="d-flex align-items-center ms-auto">
												<Link v-bind:href="$route('admin.prod-group-review', {'group': part.id})">
													<button class="btn-outline-secondary d-xxxl-block d-none">
														<span>Review & Edit</span>
													</button>
													<button class="btn-outline-secondary d-xxxl-none d-block">
														<span>Review</span>
													</button>
												</Link>
												<div class="checkbox-wrapper ms-4">
													<input
															name="bulk-select"
															type="checkbox"
															:id="'bulk-select-' + part.id"
															v-model="part.selected"
															@input="toggleSelected(part, 'variation')"
													/>
													<label :for="'bulk-select-' + part.id"></label>
												</div>
											</div>
										</div>
									</td>
								</tr>


								<!-- VARIATION -->
								<tr v-for="part in variations" :key="part.id" :class="{ selected: part.selected }">
									<td>
										<div class="d-flex align-items-center">
											<i class="fa-solid fa-code-branch table-icon me-3" :class="part.type"></i>
											<span class="row-tag"> Variation </span>
										</div>
									</td>

									<td>
										<div class="d-flex flex-md-row flex-column align-items-md-center">
											<div class="d-flex flex-xxl-row flex-column flex-fill align-items-xxl-center">
												<h5 class="text-start fw-normal mb-0">{{ part.name }}</h5>
												<div class="sku-container d-flex align-items-center ms-xxl-auto me-xxxl-12 mt-xxl-0 mt-1">
													<span>SKU:</span>
													<span class="fw-bold text-secondary ms-xxxl-10 ms-xl-4 ms-2">{{ part.sertus_part_num }}</span>
												</div>
												<div class="d-xl-none d-flex align-items-center mt-1">
													<span>Master SKU:</span>
													<span class="fw-bold text-secondary ms-2 me-4">{{ part.prod_code }}</span>
												</div>
											</div>

											<div class="d-xl-none d-flex align-items-center ms-auto mt-md-0 mt-2">
												<Link v-bind:href="$route('admin.part-review', {'part': part.id})">
													<button class="btn-outline-secondary">
														<span>Review</span>
													</button>
												</Link>
												<div class="checkbox-wrapper ms-4">
													<input
															name="bulk-select"
															type="checkbox"
															:id="'bulk-select-' + part.id"
															v-model="part.selected"
															@input="toggleSelected(part, 'variation')"
													/>
													<label :for="'bulk-select-' + part.id"></label>
												</div>
											</div>
										</div>
									</td>

									<td class="d-xl-table-cell d-none">
										<div class="d-flex align-items-center ms-xxxl-12">
											<span class="oneline">Master SKU:</span>
											<span class="fw-bold text-secondary ms-4 me-8">{{ part.prod_code }}</span>

											<div class="d-flex align-items-center ms-auto">
												<Link v-bind:href="$route('admin.part-review', {'part': part.id})">
													<button class="btn-outline-secondary d-xxxl-block d-none">
														<span>Review & Edit</span>
													</button>
													<button class="btn-outline-secondary d-xxxl-none d-block">
														<span>Review</span>
													</button>
												</Link>
												<div class="checkbox-wrapper ms-4">
													<input
															name="bulk-select"
															type="checkbox"
															:id="'bulk-select-' + part.id"
															v-model="part.selected"
															@input="toggleSelected(part, 'variation')"
													/>
													<label :for="'bulk-select-' + part.id"></label>
												</div>
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<div class="row mt-lg-10 mt-6">
				<div class="col-12 d-flex justify-content-lg-end mb-8">
					<div class="d-flex align-items-center">
						<button class="btn-primary text-tertiary" @click="confirmAll">
							<span>Confrim all</span>
						</button>
						<button class="btn-outline-primary ms-4" @click="confirmSelected">
							<span>Confirm Selected</span>
						</button>
					</div>
				</div>
				<div class="col-auto">
					<div class="d-flex flex-xxl-row flex-column align-items-xxl-center">
						<h3 class="h5 d-flex align-items-center fw-extra text-lg-start text-center text-secondary mb-xl-0 mb-2">
							<i class="fa-duotone fa-trash theme-sertus invert-theme me-2 pb-1"></i>
							Removed parts
						</h3>
						<p class="mb-0 ms-xxl-8">The following parts are no longer in the Epicor system and have been removed by the sync.</p>
					</div>
				</div>
				<div class="col-12 mt-4">
					<div class="d-flex flex-wrap" v-if="deleted.length > 0">
						<span v-for="item in deleted" :key="item.id" class="me-2 text-secondary text-italic">{{ item.name }}</span>
					</div>
					<div v-else>
						<h5 class="text-lg-start text-center text-secondary fw-normal mb-0">No recently deleted parts to display!</h5>
					</div>
				</div>
			</div>
		</section>
	</AdminLayout>
</template>

<script setup>
import AdminLayout from '@layouts/AdminLayout.vue';
import { Head, Link } from '@inertiajs/vue3';
import { router } from '@inertiajs/vue3';
import { onMounted, reactive, ref } from 'vue';

const props = defineProps({
	'prod_groups': Object,
	'parts' : Object,
	'deleted': Object
});

const deleted = ref(props.deleted);
const masters = ref(props.prod_groups.data);
const variations = ref(props.parts.data);
const variationSyncIds = ref([]);
const masterSyncIds = ref([]);

onMounted(() => {
	// make sure to add any pre-selected parts to the sync array when the component has finished mounting
	variations.value.forEach((variation) => {
		variation.selected ? variationSyncIds.value.push(variation.id) : false;
	});
	masters.value.forEach((part) => {
		part.selected ? masterSyncIds.value.push(part.id) : false;
	});
});

const toggleSelected = function (part, type) {
	if(type === 'variation') {
		if(variationSyncIds.value.includes(part.id)) {
			variationSyncIds.value = variationSyncIds.value.filter((item) => item !== part,id);
		}
		else {
			variationSyncIds.value.push(part.id);
		}
	}
	else if(type === 'master') {
		if(masterSyncIds.value.includes(part.id)) {
			masterSyncIds.value = masterSyncIds.value.filter((item) => item !== part,id);
		}
		else {
			masterSyncIds.value.push(part.id);
		}
	}
};

const confirmAll = function () {
	variations.value.forEach((part) => {
		variationSyncIds.value.push(part.id);
		part.selected = true;
	})

	masters.value.forEach((part) => {
		masterSyncIds.value.push(part.id);
		part.selected = true;
	})

	Swal.fire({
		confirmButtonText: 'Yes, sync now!',
		confirmButtonColor: '#753fbf',
		denyButtonText: 'Cancel Sync All!',
		focusDeny: true,
		icon: 'question',
		showDenyButton: true,
		title: 'Sync all parts?',
		text: "Are you sure you want to sync all of the table's parts in one go? You will not be able to undo this!",
	}).then((result) => {
		if (result.isConfirmed) {
			sync();
		} else if (result.isDenied) {
			unselectAll();
		}
	});
};

const confirmSelected = function () {
	// selected products is the total of the variation and master products
	const selectedCount = variationSyncIds.value.length + masterSyncIds.value.length;

	// if we have no selected parts then display that to the user and end this function call
	if (selectedCount === 0) {
		Swal.fire({
			icon: 'info',
			title: 'Please select a Product!',
			text: 'No parts have been selected in the table to sync. Please select one or more to continue!',
		});

		return;
	}

	// if we have parts to sync them we check with the user to make sure they are happy to sync those parts
	Swal.fire({
		confirmButtonText: 'Yes, sync now!',
		confirmButtonColor: '#753fbf',
		denyButtonText: 'Cancel sync!',
		focusDeny: true,
		icon: 'question',
		showDenyButton: true,
		title: 'Sync selected parts?',
		text: 'Are you sure you want to sync the parts that you have selected in the table?',
	}).then((result) => {
		// if they are then we call the sync function
		if (result.isConfirmed) {
			sync();
		}
		// otherwise, clear the selection and return control to the user
		else {
			Swal.fire({
				icon: 'info',
				showConfirmButton: false,
				title: 'No parts were synced!',
				timer: 4000,
			});
		}
	});
};

const unselectAll = () => {
	// reset all of the selected properties to false
	variations.value.forEach((part) => (part.selected = false));
	masters.value.forEach((part) => (part.selected = false));
	
	// set sync arrays to empty
	variationSyncIds.value = [];
	masterSyncIds.value = [];
};

const removeparts = () => {
	// set the remaining parts/groups to only be those that are not selected
	variations.value = variations.value.filter((part) => !part.selected);
	masters.value = masters.value.filter((part) => !part.selected);
};

const sync = function () {
	Swal.fire({
		icon: 'info',
		title: 'Syncing parts',
		text: 'Please wait whilst we sync your chosen parts to the database!',
		showCloseButton: false,
		showConfirmButton: false
	});

	axios.post(
		route('admin.batch-review-parts'),
		{
			'partSyncs': variationSyncIds.value,
			'groupSyncs': masterSyncIds.value
		},
		{
			headers: {
				'content-type': 'application/json',
			},
		},
	)
	.then(response => response.data)
	.then(async data => {
		removeparts();

		// reset the sync arrays
		masterSyncIds.value = [];
		variationSyncIds.value = [];

		// fire a request to get new parts from the db for the page
		let refreshCount =  await axios.post(route('admin.refresh-parts'), {
 			'group_count': masters.value.length,
			'part_count': variations.value.length
		})
		.then(response => response.data)
		.then(data => {
			// assign the parts in the response to the data arrays that output to the page
			masters.value = masters.value.concat(data.groups);
			variations.value = variations.value.concat(data.parts);

			// return how many parts we retrieved to relay the info back to the user
			return data.refresh_count;
		});

		// find the number of synced items
		let count = data.updated_groups.length + data.updated_parts.length;
		// close the processing modal from the start of this function!
		Swal.close();

		// set the text to relay back to the user
		let text = `${refreshCount} parts have been pulled in for review.`;
		if(refreshCount === 1) {
			text = '1 part has been pulled in for review.';
		}

		// relay message to user
		window.Toast.fire({
			icon: 'success',
			title: `${count} part(s) synced successfully!`,
			text: text
		});
	})
	.catch(error => {
		console.log(error);
		Swal.fire({
			icon:'error',
			title: 'Oops, something went wrong!',
			text: error.message,
			timer: 10000
		});
	});
};
</script>
