<template>
	<!-- desktop sidebar / header & navigation -->
	<header id="sidebar" class="d-lg-block d-none">
		<div class="d-flex flex-column h-100 px-xxlxl-12 px-xl-10 px-4 py-lg-10 py-8">
			<Link class="d-block mb-6" v-bind:href="$route('admin.dashboard')">
				<ApplicationLogo></ApplicationLogo>
			</Link>

			<div class="my-auto">
				<h4 class="text-secondary fw-extra mb-0">Welcome <span v-if="current_user !== ''">{{ current_user }}</span></h4>
				<p class="mb-0">What do you need to do today?</p>

				<nav id="navigation" class="d-flex flex-column mt-lg-8 mt-6">
					<ul class="nav flex-column">
						<li class="nav-item" v-if="$can('sync parts')">
							<Link class="nav-link" v-bind:href="$route('admin.epicor-sync')">
								<button class="nav-button">
									Epicor Sync Review
									<i class="fa-duotone theme-sertus ms-auto fa-arrows-repeat"></i>
								</button>
							</Link>
						</li>
						<li class="nav-item">
							<Link class="nav-link" v-bind:href="$route('admin.part-list')">
								<button class="nav-button">
									Edit Product Details
									<i class="fa-duotone theme-sertus ms-auto fa-pen-line"></i>
								</button>
							</Link>
						</li>
						<li class="nav-item" v-if="$can('upload images')">
							<Link class="nav-link" v-bind:href="$route('admin.bulk-image-uploads')">
								<button class="nav-button">
									Bulk Image Upload
									<i class="fa-duotone theme-sertus ms-auto fa-layer-group"></i>
								</button>
							</Link>
						</li>
						<li class="nav-item">
							<Link class="nav-link" v-bind:href="$route('admin.orders')">
								<button class="nav-button">
									View Orders
									<i class="fa-duotone theme-sertus ms-auto fa-box-taped"></i>
								</button>
							</Link>
						</li>
						<li class="nav-item">
							<Link class="nav-link" v-bind:href="$route('admin.quotes')">
								<button class="nav-button">
									Quote Requests
									<i class="fa-duotone theme-sertus ms-auto fa-clipboard fa-swap-opacity"></i>
								</button>
							</Link>
						</li>
						<li class="nav-item">
							<Link class="nav-link" v-bind:href="$route('admin.all-users')">
								<button class="nav-button">
									User Management
									<i class="fa-duotone theme-sertus ms-auto fa-user-group"></i>
								</button>
							</Link>
						</li>
						<li class="nav-item" v-if="$can('manage categories')">
							<Link class="nav-link" v-bind:href="$route('admin.categories')">
								<button class="nav-button">
									Categories
									<i class="fa-duotone theme-sertus ms-auto fa-list"></i>
								</button>
							</Link>
						</li>
					</ul>
				</nav>
			</div>

			<div class="mt-6">
				<nav id="sidebar-footer">
					<ul class="nav flex-column nav-footer">
						<li class="nav-item">
							<Link class="nav-link" v-bind:href="$route('admin.dashboard')">
								<i class="fa-duotone theme-sertus invert-theme fa-table-layout me-2"></i>
								<span class="link-text">Dashboard</span>
							</Link>
						</li>

						<li>
							<form @submit.prevent="submit">
								<button type="submit" class="btn-underline-secondary ps-4">
									<i class="fa-duotone theme-sertus fa-arrow-right-to-bracket me-2 fs-3"></i>
									<span class="text-primary link-text"> Logoff </span>
								</button>
							</form>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	</header>
	<!-- desktop sidebar / header end -->

	<!-- mobile header & navigation -->
	<header id="header" class="d-lg-none d-block">
		<div class="container-fluid">
			<div class="row py-4">
				<div class="col-6">
					<Link :href="$route('admin.dashboard')" class="logo" @click="closeMenu">
						<ApplicationLogo></ApplicationLogo>
					</Link>
				</div>
				<div class="col-6 d-flex justify-content-end align-items-center">
					<nav id="navigation-mobile">
						<ul class="nav" v-bind:class="{ open: isOpen }">
							<li class="nav-item">
								<Link class="nav-link" v-bind:href="$route('admin.epicor-sync')" @click="closeMenu">
									<button class="nav-button">
										Epicor Sync Review
										<i class="fa-duotone theme-sertus ms-auto fa-arrows-repeat"></i>
									</button>
								</Link>
							</li>
							<li class="nav-item">
								<Link class="nav-link" :href="$route('admin.part-list')" @click="closeMenu">
									<button class="nav-button">
										Edit Product Details
										<i class="fa-duotone theme-sertus ms-auto fa-pen-line"></i>
									</button>
								</Link>
							</li>
							<li class="nav-item">
								<Link class="nav-link" :href="$route('admin.bulk-image-uploads')" @click="closeMenu">
									<button class="nav-button">
										Bulk Image Upload
										<i class="fa-duotone theme-sertus ms-auto fa-layer-group"></i>
									</button>
								</Link>
							</li>
							<li class="nav-item">
								<Link class="nav-link" :href="$route('admin.orders')" @click="closeMenu">
									<button class="nav-button">
										View Orders
										<i class="fa-duotone theme-sertus ms-auto fa-box-taped"></i>
									</button>
								</Link>
							</li>
							<li class="nav-item">
								<Link class="nav-link" :href="$route('admin.quotes')" @click="closeMenu">
									<button class="nav-button">
										Quote Requests
										<i class="fa-duotone theme-sertus ms-auto fa-clipboard fa-swap-opacity"></i>
									</button>
								</Link>
							</li>
							<li class="nav-item">
								<Link class="nav-link" :href="$route('admin.all-users')" @click="closeMenu">
									<button class="nav-button">
										User Management
										<i class="fa-duotone theme-sertus ms-auto fa-user-group"></i>
									</button>
								</Link>
							</li>

							<li class="nav-item mt-6">
								<Link class="nav-link" :href="$route('admin.dashboard')" @click="closeMenu">
									<button class="nav-button">
										Dashboard
										<i class="fa-duotone theme-sertus invert-theme fa-table-layout ms-auto"></i>
									</button>
								</Link>
							</li>
							<li class="nav-item">
								<form @submit.prevent="submit" class="nav-link">
									<button class="nav-button" type="submit">
										Logout
										<i class="fa-duotone theme-sertus fa-arrow-right-to-bracket ms-auto"></i>
									</button>
								</form>
							</li>
						</ul>
					</nav>
					<div>
						<button class="d-flex align-items-center p-0" v-bind:class="{ open: isOpen }" @click="toggleMenu" id="burger" name="menu" type="button">
							<span class="burger"></span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</header>
	<!-- mobile header end -->
</template>

<script setup>
import { computed, ref } from 'vue';
import { Link, useForm, usePage } from '@inertiajs/vue3';
import ApplicationLogo from '@/Components/ApplicationLogo.vue';

const isOpen = ref(false);
const form = useForm({});
const current_user = computed(() => usePage().props.global.current_user);
// const current_user = "test";

const toggleMenu = () => {
	isOpen.value = !isOpen.value;
};
const closeMenu = () => {
	isOpen.value = false;
};

const submit = () => {
	form.post(route('admin.logout'));
};
</script>
