<template>
	<AdminLayout>
		<section id="content-panel" class="py-lg-10 py-8 px-xl-16 px-lg-8 px-4">
			<div class="row mb-md-8 mb-6">
				<div class="col-12">
					<div class="dashboard-title-block">
						<h1 class="h3 text-lg-start text-center mb-lg-0 mb-2">User: <span class="text-secondary">{{ contact.first_name }} {{ contact.last_name }}</span></h1>
						<p class="text-lg-start text-center ms-lg-auto mb-0">See a user's details and reset their password.</p>
					</div>
				</div>
			</div>

			<div class="row mb-xl-12 mb-lg-10 mb-8">
				<div class="col-12">
					<div class="epicor-details p-6">
						<div class="bottom-divider">
							<div class="row">
								<div class="col-xxl-2 col-xl-3 col-lg-4 col-6">
									<h4 class="text-start mb-lg-0 mb-4">{{ contact.first_name }} {{ contact.last_name }}</h4>
								</div>
								<div class="col-lg col-md-6 col-12">
									<div class="d-flex align-items-start">
										<p class="text-xxl-start text-md-end fs-6 ms-lg-auto mb-lg-n1 mb-2">
											These fields are from Epicor and cannot be changed here. They are presented for review purposes only.
										</p>
										<i class="fa-solid fa-lock fs-4 ms-4 pb-2"></i>
									</div>
								</div>
							</div>
						</div>

						<div class="row mt-md-8 mt-6">
							<div class="col-xxl col-md-6 col-12 mb-xxl-0 mb-4">
								<div class="user-detail"><span class="text-primary fw-bold me-2">Email:</span>{{ contact.email }}</div>
							</div>
							<div class="col-xxl col-md-6 col-12 mb-xxl-0 mb-4">
								<div class="user-detail"><span class="text-primary fw-bold me-2">Phone:</span>{{ contact.phone }}</div>
							</div>
							<div class="col-xxl col-md-6 col-12 mb-xxl-0 mb-4 d-none">
								<div class="user-detail"><span class="text-primary fw-bold me-2">Credit Account:</span>09128483ND6FS8F</div>
							</div>
							<div class="col-xxl col-12">
								<div class="d-flex justify-content-end">
									<div class="user-tag">
										<i class="fa-solid fa-user-tag me-4"></i>
										<span class="mb-n1">Customer</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="row mb-xl-16 mb-lg-12 mb-10" v-if="$can('impersonate') || $can('reset user passwords')">
				<div class="col-12">
					<h3 class="h5 text-lg-start text-center mb-4">User Actions:</h3>
				</div>
				<div class="col-12 mb-lg-8 mb-6" v-if="$can('impersonate')">
					<div class="d-flex flex-md-row flex-column align-items-center">
						<Link :href="$route('impersonate.start', { 'id': contact.id })">
							<button class="btn-primary text-tertiary d-flex align-items-center me-xl-10 me-lg-8 me-md-6 mb-md-0 mb-4">
								<i class="fa-duotone fa-user-group theme-sertus-white me-4"></i>
								<span class="mb-n1"> Impersonate </span>
							</button>
						</Link>
						<p class="text-md-start text-center mb-0">View the font end portal as if you were loggeed in as this user.</p>
					</div>
				</div>

				<div class="col-12" v-if="$can('reset user passwords')">
					<form class="d-flex flex-md-row flex-column align-items-center" @submit.prevent="resetContactPassword">
						<input type="hidden" name="email" :value="form.email">
						<button class="btn-primary text-tertiary d-flex align-items-center me-xl-10 me-lg-8 me-md-6 mb-md-0 mb-4" type="submit">
							<i class="fa-duotone fa-arrows-rotate theme-sertus-white me-4"></i>
							<span class="mb-n1"> Reset Password </span>
						</button>
						<p class="text-md-start text-center mb-0">Send a password reset link via email to this user.</p>
					</form>
				</div>
			</div>

			<div class="row" id="user-addresses">
				<div class="col-12 mb-lg-6 mb-4">
					<h3 class="h5 text-lg-start text-center mb-0">User Delivery Addresses:</h3>
				</div>

				<div class="col-xxl-3 col-xl-4 col-md-6 col-12 mb-md-8 mb-4" v-for="address in addresses.data">
					<div class="address-box py-5 px-4" :class="{'favourite':address.favourite}">
						<h5 class="text-md-start text-center mb-2">{{ address.name }}</h5>
						<p class="text-lg-start text-center fs-6 mb-0">{{ address.compiled }}</p>

						<div class="fav-box">
							<span class="d-block mb-n1">Favourite</span>
							<i class="fa-regular fa-star text-tertiary ms-lg-8 ms-md-6 ms-4"></i>
						</div>
					</div>
				</div>

				<div class="col-12 mt-6">
					<div class="d-flex">
						<Link v-bind:href="$route('admin.all-users')">
							<button class="btn-underline-secondary d-flex align-items-center py-0">
								<i class="fa-regular fa-chevron-left text-primary pb-0 me-4"></i>
								<span>Back to users list</span>
							</button>
						</Link>
					</div>
				</div>
			</div>
		</section>
	</AdminLayout>
</template>

<script setup>
import AdminLayout from '@layouts/AdminLayout.vue';
import { Link, useForm } from '@inertiajs/vue3';

const props = defineProps({
	'contact' : Object,
	'addresses': Object
});

const form = useForm({
	email: props.contact.email
});

const resetContactPassword = () => {
	Swal.fire({
		icon: 'info',
		title: "Sending ...",
		text: 'Please wait whilst we send the contact their password reset link.',
		allowEnterKey: false,
		allowEscapeKey: false,
		allowOutsideClick: false,
		showConfirmButton: false,
		showDenyButton: false
	});

	form.post(route('admin.reset-contact-password'), {
		onSuccess: response => {
			setTimeout(() => {
				Swal.close();
				window.Toast.fire({
					icon:'success',
					title: 'Password reset link sent',
					timer: 3000
				});
			}, 250);
		},
		onError: error => {
			Swal.close();
			Swal.fire({
				icon: 'error',
				title: "Reset link sending failed!",
				text: 'Sorry, we failed to send the password reset link to the contact. Please check in the details section above that their email address is valid. If the issue persists, please reach our to tech support.',
				showConfirmButton: true,
				confirmButtonText: 'Okay'
			});
		}
	})
}
</script>
