<template>
	<Head :title="category.name"></Head>
	<ClientLayout>
		<Breadcrumbs></Breadcrumbs>
		<ImpersonationBanner :impersonating="is_impersonating"></ImpersonationBanner>

		<section id="content-panel" class="py-lg-16 py-10 px-xl-16 px-lg-8 px-4" :class="{impersonating: is_impersonating}">
			<section>
				<div class="row justify-content-lg-start justify-content-center mb-lg-12 mb-md-10 mb-8">
					<div class="col-12">
						<h1 class="h2 text-lg-start text-center d-flex align-items-center justify-content-lg-start justify-content-center">
							<div class="category-icon img-block me-lg-10 me-md-8 me-6">
								<div class="bg-square" style="background-image: url('/storage/images/mechanical-fan.svg')"></div>
							</div>
							<span class="mb-n1"> {{ category.name }}</span>
						</h1>
					</div>

					<div class="col-xxl-9 col-lg-8 col-md-10 col-12 mb-lg-0 mb-6">
						<div class="wysiwyg text-lg-start text-center text-senary">
							<p>
								{{ category.description }}
							</p>
						</div>
					</div>

					<div class="col-xxl-3 col-lg-4 col-auto">
						<div class="d-flex justify-content-md-end justify-content-center" id="filters">
							<div class="select-wrapper arrow-end">
								<select name="sort-filter" id="sort-filter">
									<option value="relevance">Sort By: Relevance</option>
									<option value="relevance">Sort By: Price (Low to high)</option>
									<option value="relevance">Sort By: Price (High to low)</option>
									<option value="relevance">Sort By: Some other requirement</option>
								</select>
							</div>
						</div>
					</div>
				</div>

				<div class="row mb-lg-n12 mb-n8" v-if="parts.length > 0">
					<div class="col-xxxl-5ths col-xxl-3 col-md-4 col-6 mb-xl-16 mb-lg-12 mb-8" v-for="part in parts">
						<ProductCard :part="part"></ProductCard>
					</div>
				</div>
				<div class="row" v-else>
					<div class="col-12">
						<h3 class="text-center text-secondary">Sorry, we could not find any products to show for this category!</h3>
						<div class="d-flex justify-content-center">
							<Link :href="$route('catalogue')">
								<button class="btn-secondary text-tertiary">
									<span>Return to the catalogue</span>
								</button>
							</Link>
						</div>
					</div>
				</div>
			</section>
		</section>
	</ClientLayout>
</template>

<script setup>
import ClientLayout from '@layouts/ClientLayout.vue';
import Breadcrumbs from '@/Components/Breadcrumbs.vue';
import ImpersonationBanner from "@js/Components/ImpersonationBanner.vue";
import { Head, Link, usePage } from '@inertiajs/vue3';
import { computed, ref } from "vue";
import ProductCard from "@js/Components/ProductCard.vue";

const props = defineProps({
	'category': Object,
	'parts' : Object
});

const is_impersonating = computed(() => usePage().props.global.is_impersonating);

const category = ref(props.category);
const parts = ref(props.parts.data);
console.log(parts);
</script>
