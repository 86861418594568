<template>
	<Head title="Add Address"></Head>
	<ClientLayout>
		<Breadcrumbs></Breadcrumbs>
		<ImpersonationBanner :impersonating="is_impersonating"></ImpersonationBanner>

		<section id="content-panel" class="my-account py-lg-16 py-10 px-xl-16 px-lg-8 px-4" :class="{impersonating: is_impersonating}">
			<div class="row mb-lg-10 mb-8">
				<div class="col-12">
					<h1 class="h2 text-lg-start text-center mb-0">Add Address</h1>
				</div>
			</div>

			<form @submit.prevent="addAddress" id="create-address-form">
				<div class="row mx-md-n2 mb-lg-12 mb-md-10 mb-8">
					<div class="col-xxl-5ths col-xl-4 col-md-6 col-12 px-md-2 mb-4 mb-4">
						<TextInput
							id="address-name"
							type="text"
							v-model="form.name"
							placeholder-value="Give this address a name"
							required
							autofocus
							tabindex="1"
						></TextInput>
						<InputError class="mt-2" :message="form.errors.name"></InputError>
					</div>
					<div class="col-xxl-5ths col-xl-4 col-md-6 col-12 px-md-2 mb-4 mb-4">
						<div class="checkbox-container mt-4 ms-lg-4">
							<input name="favourite" type="checkbox" id="favourite" v-model="form.favourite" tabindex="2" />
							<label class="ps-10" for="favourite">Make Favourite Address</label>
						</div>
					</div>
				</div>

				<div class="row mx-md-n2 mb-lg-12 mb-md-10 mb-8 mt-n4">
					<div class="col-xxl-5ths col-xl-4 col-md-6 col-12 px-md-2 mb-4 mb-4">
						<TextInput
								id="contact_name"
								type="text"
								v-model="form.contact_name"
								placeholder-value="Contact Name"
								required
								autofocus
								tabindex="2"
						></TextInput>
						<InputError class="mt-2" :message="form.errors.contact_name"></InputError>
					</div>
					<div class="col-xxl-5ths col-xl-4 col-md-6 col-12 px-md-2 mb-4 mb-4">
						<TextInput
								id="contact_number"
								type="text"
								v-model="form.contact_number"
								placeholder-value="Contact Number"
								required
								autofocus
								tabindex="3"
						></TextInput>
						<InputError class="mt-2" :message="form.errors.contact_number"></InputError>
					</div>
				</div>

				<div class="row mx-md-n2 mb-lg-12 mb-md-10 mb-8 mt-n4">
					<div class="col-xxl-5ths col-xl-4 col-md-6 col-12 px-md-2 mb-4 mb-4">
						<TextInput
								id="address_1"
								type="text"
								v-model="form.address_1"
								placeholder-value="Building No./Name"
								required
								autofocus
								tabindex="4"
						></TextInput>
						<InputError class="mt-2" :message="form.errors.address_1"></InputError>
					</div>
					<div class="col-xxl-5ths col-xl-4 col-md-6 col-12 px-md-2 mb-4 mb-4">
						<TextInput
								id="address_2"
								type="text"
								v-model="form.address_2"
								placeholder-value="Street (optional)"
								autofocus
								tabindex="5"
						></TextInput>
						<InputError class="mt-2" :message="form.errors.address_2"></InputError>
					</div>
				</div>

				<div class="row mx-md-n2 mb-lg-12 mb-md-10 mb-8 mt-n4">
					<div class="col-xxl-5ths col-xl-4 col-md-6 col-12 px-md-2 mb-4 mb-4">
						<TextInput
								id="address_3"
								type="text"
								v-model="form.address_3"
								placeholder-value="Town (optional)"
								autofocus
								tabindex="6"
						></TextInput>
						<InputError class="mt-2" :message="form.errors.address_3"></InputError>
					</div>
					<div class="col-xxl-5ths col-xl-4 col-md-6 col-12 px-md-2 mb-4 mb-4">
						<TextInput id="city" type="text" v-model="form.city" placeholder-value="City" required autofocus tabindex="6"></TextInput>
						<InputError class="mt-2" :message="form.errors.city"></InputError>
					</div>
				</div>

				<div class="row mx-md-n2 mb-lg-12 mb-md-10 mb-8 mt-n4">
					<div class="col-xxl-5ths col-xl-4 col-md-6 col-12 px-md-2 mb-4 mb-4">
						<TextInput id="state" type="text" v-model="form.state" placeholder-value="State (optional)" autofocus tabindex="7"></TextInput>
						<InputError class="mt-2" :message="form.errors.state"></InputError>
					</div>
					<div class="col-xxl-5ths col-xl-4 col-md-6 col-12 px-md-2 mb-4 mb-4">
						<TextInput id="zip" type="text" v-model="form.zip" placeholder-value="Postcode" required autofocus tabindex="8"></TextInput>
						<InputError class="mt-2" :message="form.errors.zip"></InputError>
					</div>
				</div>

				<div class="row mx-md-n2 mb-lg-12 mb-md-10 mb-8 mt-n4">
					<div class="col-xxl-5ths col-xl-4 col-md-6 col-12 px-md-2 mb-4 mb-4">
						<TextInput id="country" type="text" v-model="form.country" placeholder-value="Country" required autofocus tabindex="9"></TextInput>
						<InputError class="mt-2" :message="form.errors.country"></InputError>
					</div>
				</div>

				<div class="row mx-md-n2 mb-xxl-25 mb-lg-20 mb-md-16 mb-12">
					<div class="col-xxl-5ths col-xl-4 col-md-6 col-12 px-md-2 mb-4">
						<button class="btn-secondary text-tertiary fw-normal pt-5 pb-4 w-100" type="submit" tabindex="10" id="create-address">
							<span>
								<i class="fa-regular fa-plus text-quaternary mb-n1 me-3"></i>
								Add Address
							</span>
						</button>
					</div>
				</div>
			</form>

			<div class="row">
				<div class="col-auto">
					<button class="btn-underline-secondary" @click="back">
						<i class="fa-regular fa-chevron-left text-primary mb-n1 me-3"></i>
						<span>Back to Address list</span>
					</button>
				</div>
			</div>
		</section>
	</ClientLayout>
</template>

<script setup>
import ClientLayout from '@layouts/ClientLayout.vue';
import Breadcrumbs from '@/Components/Breadcrumbs.vue';
import TextInput from '@/Components/TextInput.vue';
import ImpersonationBanner from "@js/Components/ImpersonationBanner.vue";
import { Head, Link, router, useForm, usePage } from '@inertiajs/vue3';
import { computed, ref } from 'vue';
import Checkbox from "@js/Components/Checkbox.vue";
import InputError from "@js/Components/InputError.vue";

const form = useForm({
	name: '',
	contact_name: '',
	contact_number: '',
	address_1: '',
	address_2: '',
	address_3: '',
	city: '',
	zip: '',
	state: '',
	country: 'United Kingdom',
	favourite: false,
});

const is_impersonating = computed(() => usePage().props.global.is_impersonating);

const addAddress = () => {
	Swal.fire({
		icon: 'question',
		title: 'Add New Address?',
		text: 'Please confirm that you would like to create a new address using hhe details entered in the form.',
		showConfirmButton: true,
		confirmButtonText: 'Create',
		showDenyButton: true,
		denyButtonText: 'Cancel'
	})
	.then(result => {
		if(result.isConfirmed) {
			Swal.close();
			Swal.fire({
				icon: 'info',
				title:' Adding',
				text: 'Please wait whilst we add your new address!',
				showConfirmButton: false,
				showDenyButton: false,
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false
			});

			form.post(route('account.addresses.create'), {
				onSuccess: data => {
					setTimeout(() => {
						Swal.close();
						Swal.fire({
							icon: 'success',
							title:'Success',
							text: 'We have successfully created your new address. Thank you!',
							confirmButtonText: 'Okay',
						})
						.then(response => {
							if(response.isDismissed || response.isConfirmed) {
								router.get(route('account.addresses.manage'));
							}
						});
					}, 500)
				},
				onError: () => {
					Swal.close();
					Swal.fire({
						icon: 'error',
						title: 'Update failed',
						text: 'Sorry, we could not add your new! Please make sure that you have fixed all errors on the form andt try again!',
						confirmButtonText: 'Okay'
					});
				}
			});
		}

		if(result.isDenied) {
			router.get(route('account.addresses.manage'));
		}
	});
}

const back = () => {
	Swal.fire({
		icon: 'question',
		title: 'Are you sure?',
		text: 'By returning to the address list page, you will lose any changes that have been made to the current address. Please confirm to continue',
		showConfirmButton: true,
		confirmButtonText: 'Go back',
		showDenyButton: true,
		denyButtonText: 'Keep editing'
	})
	.then(result => {
		if(result.isConfirmed) {
			router.get(route('account.addresses.manage'));
		}
	})
}

</script>
