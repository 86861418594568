const state = {
	permissions: []
}

const getters = {
	permissions: (state) => {
		return state.permissions;
	}
}

const actions = {
	fetchPermissions({state, commit}) {
		axios.get('')
		.then(response => response.data)
		.then(data => {
			let permissions = data;
			permissions = [
				{
					name: 'edit admins',
					guard: 'admin'
				},
				{
					name: 'delete admins',
					guard: 'admin'
				}
			];
			commit('setPermissions', {permissions})
		});
	}
}

const mutations = {
	setPermissions(state, {permissions}) {
		if(permissions) {
			state.permissions = permissions.map(permission => {
				return {
					'name' : permission.name
				}
			});
		}
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
};