import { createStore, createLogger } from 'vuex';

import basket from './modules/basket';
import permissions from './modules/permissions';

const debug = process.env.NODE_ENV !== 'production';

const store = createStore({
	modules: {
		basket,
		permissions
	},
	plugins: debug ? [createLogger()] : [],
	strict: debug,
});

export { store };
