<template>
	<Head :title="productGroup.description"></Head>
	<ClientLayout>
		<Breadcrumbs></Breadcrumbs>
		<ImpersonationBanner :impersonating="is_impersonating"></ImpersonationBanner>

		<div id="content-panel" class="py-lg-16 py-10 px-xl-16 px-lg-8 px-4" :class="{impersonating: is_impersonating}">
			<section class="pb-lg-12 pb-10">
				<div class="row mb-lg-6 mb-4">
					<div class="col-12">
						<h1 class="h2 text-lg-start text-center mb-0">Configure: {{ productGroup.description }}</h1>
					</div>
				</div>
				<div class="row justify-content-lg-start justify-content-center">
					<div class="col-xxxl-6 col-xxl-7 col-12">
						<div class="pe-xxl-16">
							<div class="wysiwyg text-lg-start text-center mt-xxl-10 mt-lg-8 mt-6" v-if="productGroup.custom_description" v-html="productGroup.custom_description">
							</div>

							<div class="row mt-xxl-20 mt-xl-16 mt-12">
								<div class="col-12 mb-xxl-8">
									<!-- search result highlighted product -->
									<div class="search-result" v-if="is_search_result">
										<h4 class="h4 text-secondary text-lg-start text-center mb-2 fw-extra">We found a matching part number ...</h4>
										<div class="wysiwyg text-lg-start text-center mb-4 mb-md-6 mb-lg-8">
											<p>
												The part shown below matches the part number that you searched for.
												From here you can either add this part to your project, or you can use the
												product finder to narrow down your search for the right part.
											</p>
										</div>
										<div class="d-flex align-items-center justify-content-md-start justify-content-center">
											<template v-if="searchHighlight.images.length > 0">
												<div class="img-block variation-thumbnail me-4">
													<div class="bg-square" :style="'background-image: url(' + searchHighlight.images[0].file_location + ')'"></div>
												</div>
											</template>
											<template v-else>
												<div class="img-block variation-thumbnail default me-4">
													<div class="bg-square"></div>
												</div>
											</template>
											<div class="d-block">
												<h5 class="text-start text-primary mb-2">{{ searchHighlight.name }} - <span class="text-secondary">{{ searchHighlight.sertus_part_num }}</span></h5>
											</div>
										</div>

										<div class="price-container mt-md-10 mt-8">
											<div class="wysiwyg text-secondary text-md-start text-center fw-bold fs-6 mb-1" v-if="current_customer.show_discount && customer_discount > 0">
												<p>Your discount ({{ customer_discount }}%)</p>
											</div>

											<h4 class="text-md-start text-center text-senary mb-0" v-if="searchHighlight.is_discounted && current_customer.show_discount">
												<s>
													<span>£{{ calcPrice(searchHighlight.unit_price).pounds }}.</span>
													<span class="fs-5">{{ calcPrice(searchHighlight.unit_price).pence }}</span>
												</s>
											</h4>
											<h2 class="text-md-start text-center text-primary mb-1">
												<template v-if="searchHighlight.is_discounted">
													<span>£{{ calcPrice(searchHighlight.final_price).pounds }}.</span>
													<span class="fs-5">{{ calcPrice(searchHighlight.final_price).pence }}</span>
												</template>
												<template v-else>
													<span>£{{ calcPrice(searchHighlight.unit_price).pounds }}.</span>
													<span class="fs-5">{{ calcPrice(searchHighlight.unit_price).pence }}</span>
												</template>
											</h2>
											<p class="text-md-start text-center text-senary fs-5">ExVAT</p>

											<div class="d-flex justify-content-md-start justify-content-center">
												<button class="btn-secondary text-tertiary pt-5 pb-4" @click="addToProject(true)">
													<span>Add to Project</span>
												</button>
											</div>

											<div class="delivery d-flex align-items-center justify-content-md-start justify-content-center mt-lg-10 mt-md-8 mt-6">
												<template v-if="searchHighlight.stock > 0">
													<i class="fa-duotone fa-truck theme-sertus me-2"></i>
													<span>
														<span class="fw-bold text-secondary">{{ searchHighlight.stock }}</span>
														In-stock for delivery
													</span>
												</template>
												<template v-else>
													<span class="fa-stack me-2">
														<i class="fa-solid fa-truck text-primary fa-stack-1x"></i>
														<i class="fa-solid fa-ban text-secondary fa-stack-2x"></i>
													</span>
														<span>
														Out of Stock!
													</span>
												</template>
											</div>
											<div class="text-start text-senary fw-bold mt-4 stock-message" v-html="searchHighlight.stock_message"></div>
										</div>

										<div class="d-flex flex-column align-items-end mt-md-6 mt-4">
											<p class="fw-bold mb-2">Not the right part for you? Why not: </p>
											<Link :href="$route('bom-product', {'id': productGroup.id})">
												<button class="btn-outline-primary text-secondary">
													<span>Use our part finder</span>
												</button>
											</Link>
										</div>
									</div>

									<!-- product wizard/finder box -->
									<div class="product-wizard" v-else>
										<h3 class="h4 text-secondary text-lg-start text-center mb-4 mb-md-6 mb-lg-8 fw-extra">Configure your product</h3>
										<template v-if="productGroup.wizard_questions.length > 0">
											<h5 class="text-start text-primary mb-4">
												Question <span class="text-secondary">{{ currentStep + 1 }}</span> of <span class="text-secondary">{{
													productGroup.wizard_questions.length
												}}</span>
											</h5>
											<ul class="wizard-steps mb-6">
												<li class="step"
														:class="{ 'option-selected': index === currentStep, 'complete' : index < currentStep, 'disabled' : disabled }"
														:key="'step' + index"
														v-for="(item, index) in productGroup.wizard_questions">
													<div class="d-flex align-items-center">
														<span class="step-number">{{ index + 1 }}</span>
														<span class="step-label">{{ item.question }}</span>
													</div>
												</li>
											</ul>

											<div class="d-flex align-items-center mt-4">
												<div class="select-wrapper">
													<select class="border-select"
																	name="part-finder"
																	id="part-finder"
																	@change="nextStep"
																	v-model="selectedResponse"
													>
														<option value="" disabled selected>Select an option ...</option>
														<option v-for="item in productGroup.wizard_questions[currentStep].answers" :key="item.id" :value="item.id">{{ item.answer }}</option>
													</select>
												</div>

												<button class="btn-outline-primary text-primary h-100 ms-4" v-if="selectedResponse !== ''" @click="selectedResponse = ''">
													<span>Clear</span>
												</button>
											</div>
										</template>
										<template v-else>
											<h4 class="text-lg-start text-center mb-0">
												There are no configurator options for this group!
											</h4>
										</template>

										<div class="d-flex align-items-center mt-4">
											<button class="btn-quaternary twext-tertiary me-4" id="form-clear" @click="clearSelections" v-if="currentStep > 0">
												<span>Restart</span>
											</button>

											<button class="btn-secondary text-tertiary" @click="prevStep" v-if="currentStep > 0" id="form-step-back">
													<span>
														Step back
													</span>
											</button>
										</div>

										<div class="mt-6" v-if="productGroup.wizard_questions.length > 0">
											<h3 class="text-lg-start text-center mb-2">Your options:</h3>

											<template v-if="currentStep === 0 && parts.length === 0">
												<h5 class="text-lg-start text-center text-secondary mb-0">Please use the configurator above to find some options.</h5>
											</template>
											<template v-else-if="(parts.length === 0) && (overLimit === true) && (matchedCount > 0)">
												<h5 class="text-lg-start text-center text-secondary mb-0">
													Please refine your search further using the part finder to find relevant parts!
												</h5>
											</template>
											<template v-else-if="parts.length === 0 && matchedCount === 0">
												<h5 class="text-lg-start text-center text-secondary mb-0">
													We could not find any products matching your selected criteria.
													<br>
													<span class="text-primary">
														Please select another option, step back or restart the finder and try again!
													</span>
												</h5>
											</template>
											<template v-else>
												<div class="radio-container d-flex align-items-center mb-lg-8 mb-6" v-for="variation in parts" :key="variation.id" :id="'variation-' + variation.id" @change="changePartImages">
													<template v-if="variation.images.length > 0">
														<div class="img-block variation-thumbnail me-4">
															<div class="bg-square" :style="'background-image: url(' + variation.images[0].file_location + ')'"></div>
														</div>
													</template>
													<template v-else>
														<div class="img-block variation-thumbnail default me-4">
															<div class="bg-square"></div>
														</div>
													</template>
													<input type="radio" name="variation" :id="'option-' + variation.id" v-model="selectedPart" :value="variation"/>
													<label :for="'option-' + variation.id" class="d-block">
														<span class="d-block mb-n1">{{ variation.name }}</span>
														<span class="d-block text-secondary mb-n1">{{ variation.sertus_part_num }}</span>
														<span class="d-block mb-n1">
															<span class="text-senary me-2" v-if="current_customer.show_discount && variation.is_discounted">
																<s>£{{ variation.unit_price }}</s>
															</span>
															£{{ variation.final_price }}
														</span>
													</label>
												</div>
											</template>
										</div>
									</div>
								</div>

								<div class="d-xxl-block d-none col-xxxl-7 col-xxl-8" v-if="selectedPart !== null && (!is_search_result)">
									<div class="price-container mt-md-10 mt-8">
										<div class="wysiwyg text-secondary text-lg-start text-center fw-bold fs-6 mb-1" v-if="current_customer.show_discount && customer_discount > 0">
											<p>Your discount ({{ customer_discount }}%)</p>
										</div>

										<h4 class="text-lg-start text-center text-quaternary mb-2">Price</h4>
										<h4 class="text-lg-start text-center text-senary mb-0" v-if="selectedPart.is_discounted && current_customer.show_discount">
											<s>
												<span>£{{ calcPrice(selectedPart.unit_price).pounds }}.</span>
												<span class="fs-5">{{ calcPrice(selectedPart.unit_price).pence }}</span>
											</s>
										</h4>
										<h2 class="text-lg-start text-center text-primary mb-1">
											<template v-if="selectedPart.is_discounted">
												<span>£{{ calcPrice(selectedPart.final_price).pounds }}.</span>
												<span class="fs-5">{{ calcPrice(selectedPart.final_price).pence }}</span>
											</template>
											<template v-else>
												<span>£{{ calcPrice(selectedPart.unit_price).pounds }}.</span>
												<span class="fs-5">{{ calcPrice(selectedPart.unit_price).pence }}</span>
											</template>
										</h2>
										<p class="text-lg-start text-center text-senary fs-5">ExVAT</p>

										<button class="btn-secondary text-tertiary w-100 pt-5 pb-4" @click="addToProject(false)">
											<span>Add to Project</span>
										</button>

										<div class="delivery d-flex align-items-center justify-content-lg-start justify-content-center mt-lg-10 mt-md-8 mt-6">
											<template v-if="selectedPart.stock > 0">
												<i class="fa-duotone fa-truck theme-sertus me-2"></i>
												<span>
													<span class="fw-bold text-secondary">{{ selectedPart.stock }}</span>
													In-stock for delivery
												</span>
											</template>
											<template v-else>
												<span class="fa-stack me-2">
													<i class="fa-solid fa-truck text-primary fa-stack-1x"></i>
													<i class="fa-solid fa-ban text-secondary fa-stack-2x"></i>
												</span>
												<span>
													Out of Stock!
												</span>
											</template>
										</div>
										<div class="text-start text-senary fw-bold mt-4 stock-message" v-html="selectedPart.stock_message"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="offset-xxxl-1 col-xxl-5 col-12 mt-xxl-0 mt-8">
						<div class="row justify-content-center">
							<div class="col-xxl-12 col-lg-6 col-md-8 col-12 order-lg-1 order-2">
								<!-- search hightlight part imgaes -->
								<template v-if="searchHighlight !== null">
									<template v-if="searchHighlight.images.length > 0">
										<div class="img-block bg-quinary p-xxxl-12 p-6">
											<div class="bg-square" :style="'background-image: url(' + focusImage.file_location + ')'"></div>
										</div>

										<div class="gallery mt-4" id="product-gallery">
											<div v-for="image in searchHighlight.images" :key="image.id" class="img-block gallery-block" :class="{ 'selected' : image === focusImage }" @click="setFocus(image)">
												<div class="active-overlay"></div>
												<div class="bg-square" :style="'background-image: url(' + image.file_location + ')'"></div>
											</div>
										</div>
									</template>

									<div class="img-block default bg-quinary p-xxxl-12 p-6" v-else>
										<div class="bg-square"></div>
									</div>
								</template>
								<!-- selected variation images-->
								<template v-else-if="selectedPart !== null">
									<template v-if="selectedPart.images.length > 0">
										<div class="img-block bg-quinary p-xxxl-12 p-6">
											<div class="bg-square" :style="'background-image: url(' + focusImage.file_location + ')'"></div>
										</div>

										<div class="gallery mt-4" id="product-gallery">
											<div v-for="image in selectedPart.images" :key="image.id" class="img-block gallery-block" :class="{ 'selected' : image === focusImage }" @click="setFocus(image)">
												<div class="active-overlay"></div>
												<div class="bg-square" :style="'background-image: url(' + image.file_location + ')'"></div>
											</div>
										</div>
									</template>

									<div class="img-block default bg-quinary p-xxxl-12 p-6" v-else>
										<div class="bg-square"></div>
									</div>
								</template>
								<!-- default back to the product group images -->
								<template v-else>
									<template v-if="productGroup.images.length > 0">
										<div class="img-block bg-quinary p-xxxl-12 p-6">
											<div class="bg-square" :style="'background-image: url(' + focusImage.file_location + ')'"></div>
										</div>

										<div class="gallery mt-4" id="product-gallery">
											<div v-for="image in productGroup.images" :key="image.id" class="img-block gallery-block" :class="{ 'selected' : image === focusImage }" @click="setFocus(image)">
												<div class="active-overlay"></div>
												<div class="bg-square" :style="'background-image: url(' + image.file_location + ')'"></div>
											</div>
										</div>
									</template>

									<div class="img-block default bg-quinary p-xxxl-12 p-6" v-else>
										<div class="bg-square"></div>
									</div>
								</template>
							</div>

							<div class="d-xxl-none d-block col-md-6 col-8 order-lg-2 order-1 mb-lg-0 mb-10" v-if="selectedPart !== null">
								<div class="price-container mt-md-10 mt-8">
									<div class="wysiwyg text-secondary text-lg-start text-center fw-bold fs-6 mb-1" v-if="current_customer.show_discount && customer_discount > 0">
										<p>Your discount ({{ selectedPart.discount_percent }}%)</p>
									</div>

									<h4 class="text-lg-start text-center text-senary mb-0" v-if="selectedPart.is_discounted && current_customer.show_discount">
										<s>
											<span>£{{ calcPrice(selectedPart.unit_price).pounds }}.</span>
											<span class="fs-5">{{ calcPrice(selectedPart.unit_price).pence }}</span>
										</s>
									</h4>
									<h2 class="text-lg-start text-center text-primary mb-1">
										<template v-if="selectedPart.is_discounted">
											<span>£{{ calcPrice(selectedPart.final_price).pounds }}.</span>
											<span class="fs-5">{{ calcPrice(selectedPart.final_price).pence }}</span>
										</template>
										<template v-else>
											<span>£{{ calcPrice(selectedPart.unit_price).pounds }}.</span>
											<span class="fs-5">{{ calcPrice(selectedPart.unit_price).pence }}</span>
										</template>
									</h2>
									<p class="text-lg-start text-center text-senary fs-5">ExVAT</p>

									<button class="btn-secondary text-tertiary w-100 pt-5 pb-4" @click="addToProject(false)">
										<span>Add to Project</span>
									</button>

									<div class="delivery d-flex align-items-center justify-content-md-start justify-content-center mt-lg-10 mt-md-8 mt-6">
										<template v-if="selectedPart.stock > 0">
											<i class="fa-duotone fa-truck theme-sertus me-2"></i>
											<span>
												<span class="fw-bold text-secondary">{{ selectedPart.stock }}</span>
												In-stock for delivery
											</span>
										</template>
										<template v-else>
											<span class="fa-stack me-2">
												<i class="fa-solid fa-truck text-primary fa-stack-1x"></i>
												<i class="fa-solid fa-ban text-secondary fa-stack-2x"></i>
											</span>
											<span>
												Out of Stock!
											</span>
										</template>
									</div>
									<div class="text-start text-senary fw-bold mt-4 stock-message" v-html="selectedPart.stock_message"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section class="pt-lg-12 pt-10 border-top border-1 border-quinary" v-if="selectedPart || searchHighlight">
				<div class="row justify-content-lg-start justify-content-center">
					<div class="col-xl-6 col-12">
						<h2 class="text-lg-start text-center mb-lg-0 mb-4">Product Specifications</h2>
					</div>
					<div class="col-lg-auto col-md-6 col-12 ms-lg-auto mt-xxl-0 mt-4">
						<button class="btn-quaternary btn-download-guide d-none">
							<span>
								<i class="fa-light fa-file me-2"></i>
								Download PDF Product Installation Guide
							</span>
						</button>
					</div>
				</div>

				<div class="row mt-lg-16 mt-md-12 mt-10">
					<div class="col-lg-4 col-md-5 col-12 d-none">
						<div class="img-block">
							<div class="bg-portrait" style="background-image: url('/storage/images/michal-working-on-pod.png')"></div>
						</div>
					</div>

					<div class="col-lg-9 col-md-7 col-12">
						<div class="content-block">
							<h4 class="text-lg-start text-center text-secondary fw-extra mb-2">Selected Part Description</h4>
							<div class="wysiwyg text-lg-start text-center text-senary mb-lg-12 mb-10" v-if="!is_search_result">
								{{ selectedPart.description }}
							</div>
							<div class="wysiwyg text-lg-start text-center text-senary mb-lg-12 mb-10" v-else>
								{{ searchHighlight.description }}
							</div>
						</div>
					</div>
				</div>

				<div class="d-none row mt-6">
					<div class="col-xl-7 col-md-6 col-12 order-lg-1 order-2">
						<div class="content-block mt-lg-12 mt-md-10 mt-8">
							<h4 class="text-lg-start text-center text-secondary fw-extra mb-2">Sub-heading</h4>
							<div class="wysiwyg text-lg-start text-center text-senary">
								<p>
									Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
									erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
									est Lorem ipsum dolor sit amet.
								</p>

								<ul>
									<li>Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</li>
									<li>Sed diam nonumy eirmod tempor invidunt ut</li>
									<li>labore et dolore magna aliquyam erat</li>
									<li>Diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</li>
									<li>Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-xl-5 col-md-6 col-12 order-lg-2 order-1">
						<div class="img-block">
							<div class="bg-box" style="background-image: url('/storage/images/video-pholder.jpg')"></div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</ClientLayout>
</template>

<script setup>
import ClientLayout from '@layouts/ClientLayout.vue';
import Breadcrumbs from '@/Components/Breadcrumbs.vue';
import ImpersonationBanner from "@js/Components/ImpersonationBanner.vue";
import { Head, Link, usePage } from '@inertiajs/vue3';
import { computed, onBeforeMount, onMounted, onUpdated, ref } from 'vue';
import { useStore } from 'vuex';
import { usePrice } from "@js/Composables/pricing";

const store = useStore();
const {
	calcPrice
} = usePrice();

const props = defineProps({
	'group': Object,
	'search_highlight' : Object,
	'is_search_result' : Boolean,
	'customer_discount': Number,
});

const productGroup = ref(props.group.data); // the master 'prod_group' that we are displaying
const parts = ref([]); // the variations for the master that we pull back using the finder
const selectedPart = ref(null); // the variation that the user has selected
const searchHighlight = ref(computed(() => props.search_highlight)); // the variation that has been pulled back from a user search
const overLimit = ref(false); // whether or not we are over the limit for the number of parts to show on the finder
const matchedCount = ref(0); // the number of parts that the configurator matched, even if they are not shown
const focusImage = ref(productGroup.value.images[0]); // the image that we are showing as the main image
const currentStep = ref(0); // the current step of the product finder
const disabled = ref(false); // whether or not we have disabled the rest of the product finder steps
const is_impersonating = computed(() => usePage().props.global.is_impersonating); // whether or not we are impersonating a customer
const current_customer = computed(() => usePage().props.global.current_customer.data );
const selectedResponse = ref(''); // the currently selected resposne for a part finder question
const responses = ref([]); // the user selected responses for the part finder questions

onBeforeMount(async () => {
	await checkValidity();
});
onMounted(() => {
	if(props.is_search_result) {
		changePartImages();
	}
})

const changePartImages = () => {
	if(props.is_search_result && searchHighlight.value !== null) {
		if(searchHighlight.value.images.length > 0) {
			setFocus(searchHighlight.value.images[0]);
		}
	}
	else {
		if(selectedPart.value.images.length > 0) {
			setFocus(selectedPart.value.images[0]);
		}
	}
}

/**
 * Set the focus image for the product
 * @param image the image to set as the focus image
 */
const setFocus = image => {
	focusImage.value = image;
}

/**
 * Clears all selections within the product finder flow
 */
const clearSelections = () => {
	selectedPart.value = null;
	currentStep.value = 0;
	selectedResponse.value = "";
	responses.value = [];

	findParts();
}

/**
 * Progress the wizard section by one step
 */
const nextStep = async () => {
	responses.value.push(selectedResponse.value);
	selectedResponse.value = "";

	// find the products before updating the step so that if wee dont have any we can disable the rest of the finder
	// wait for the parts to be found before moving on since we need the number of variations to check if the finder should
	// be disabled later in this function
	await findParts();

	// if we don't have any products then disable the rest of the finder questions
	// if(variations.value.length === 0) {
	// 	disabled.value = true;
	// 	return;
	// }

	// otherwise make sure the finder is not disabled
	disabled.value = false;

	let questionCount = productGroup.value.wizard_questions.length;
	// check to make sure that we are not at the end of the finder otherwise we will try to access a non-existent
	// index on the questions array
	if(currentStep.value < questionCount - 1) {
		currentStep.value ++;
	}
}

/**
 * Revert the wizard section back to the previous state
 */
const prevStep = () => {
	if(responses.value.length > 0) {
		responses.value.pop();
	}

	if (currentStep.value > 0) {
		currentStep.value --;
	}

	findParts();
}

const checkValidity = () => {
	const id= productGroup.value.id;

	axios.post(route('load-finder-data'), {
		'prod_group_id' : id
	});
}

const findParts = async () => {
	const id = productGroup.value.id;

	Swal.fire({
		icon: 'info',
		title: 'Finding parts ...',
		text: 'Please wait whilst we find the parts that are right for you based off of your selected answers.',
		showDenyButton: false,
		showConfirmButton: false,
		allowOutsideClick: false,
		allowEnterKey: false,
		allowEscapeKey: false
	});

	await axios.post(route('filter-parts'), {
		'group_id' : id,
		'responses': responses.value
	})
	.then(response => response.data)
	.then(data => {
		console.log(data);
		overLimit.value = data.over_limit;
		parts.value = data.parts
		productGroup.value.wizard_questions = data.available_answers;
		matchedCount.value = data.matched_count;

		if(selectedPart.value === null) {
			// create an artificial delay on the user messages getting updated
			setTimeout(() => {
				Swal.close();
				if(!data.over_limit) {
					window.Toast.fire({
						icon: 'info',
						title: data.parts.length + ' matching variations found!',
						timer: 3000
					});
				}
			}, 500);

			if(!data.over_limit) {
				return;
			}
		}

		// check if the new variations contain the currently selected value
		let keepSelected = parts.value.some(item => item.id === selectedPart.value.id);

		if(!keepSelected) {
			selectedPart.value = null;
			Swal.close();
			Swal.fire({
				allowOutsideClick: false,
				allowEnterKey: false,
				allowEscapeKey: false,
				confirmButtonText: 'Okay!',
				icon:'info',
				showConfirmButton: true,
				confirmButtonColor: '#753fbf',
				title: 'Selected part removed!',
				text: 'Your currently selected part has been removed since it no longer fits your search criteria.',
			})
			.then((result) =>{
				if(!data.over_limit) {
					if(result.isConfirmed) {
						window.Toast.fire({
							icon: 'info',
							title: data.parts.length + ' matching variations found!',
							timer: 3000
						}, 3000);
					}
				}
			});
		}
		else {
			// create an artificial delay on the user messages getting updated
			setTimeout(() => {
				Swal.close();
				if(!data.over_limit) {
					window.Toast.fire({
						icon: 'info',
						title: data.parts.length + ' matching variations found!',
						timer: 3000
					});
				}
			}, 500);
		}

		if(data.over_limit) {
			Swal.close();
			setTimeout(() => {
				window.Toast.fire({
					icon: 'info',
					title: 'Continue refining search to find parts!',
					timer: 3000
				});
			}, 500);
		}
	})
	.catch(error => {
		console.log(error);
		Swal.close();
		window.Toast.fire({
			icon: 'error',
			title: 'Error finding parts!',
			timer: 4000
		});
	});
}

const addToProject = async (search) => {
	debugger;
	let product = search ? searchHighlight.value : selectedPart.value;

	console.log(product);

	product.prod_code = productGroup.value.prod_code;

	// add the seleted item to the basket store
	store.dispatch('basket/addToBasket', product);
}
</script>
