<template>
	<Head title="Admin | Forgot Password"></Head>
	<section id="form-page" style="background-image: url('/storage/images/Login hero image.jpg')">
		<div class="container py-xl-20 py-md-16 py-12 h-100">
			<div class="row justify-content-center h-100">
				<div class="col-xl-7 col-lg-9 col-md-10 col-12 d-flex flex-column justify-content-center align-items-start h-100">
					<a @click="back" class="d-xl-block d-none mb-4">
						<button class="btn-secondary flip-icon text-tertiary">
							<span>Go back</span>
						</button>
					</a>

					<div class="form-wrapper px-lg-12 px-md-8 px-6 py-xl-20 py-lg-16 py-12">
						<a @click="back" class="d-xl-none d-block me-auto mb-6">
							<button class="btn-secondary flip-icon text-tertiary">
								<span>Back</span>
							</button>
						</a>

						<div class="d-flex justify-content-lg-start justify-content-center">
							<img src="/storage/images/Sertus Logo.svg" alt="Black Sertus Logo" class="sertus-logo lg mb-lg-12 mb-md-10 mb-8" />
						</div>
						<h1 class="h4 text-lg-start text-center text-secondary mb-0">Customer | Request Password Reset</h1>
						<h2 class="text-lg-start text-center mb-lg-10 mb-8">Sertus Portal</h2>
						<form @submit.prevent="submit" id="register-form" class="form-2-col">
							<div class="row justify-content-end">
								<div class="col-12 mb-md-8 mb-6">
									<div class="form-row">
										<InputLabel for="email" value="Email" />
										<TextInput
											id="email"
											type="email"
											v-model="form.email"
											placeholder-value="Email address"
											required
											autofocus
											autocomplete="email"
											tabindex="1"
										/>
										<InputError class="mt-2" :message="form.errors.email" />
									</div>
								</div>

								<div class="col-12">
									<div class="d-flex flex-column align-items-end">
										<PrimaryButton
											class="ml-4"
											id="register-submit"
											:class="{ 'disabled': form.processing }"
											:disabled="form.processing"
											tabindex="6"
										>
											Request Reset Link
										</PrimaryButton>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script setup>
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import { Head, Link, useForm } from '@inertiajs/vue3';

const form = useForm({
	email: '',
});

const back = () => {
	window.history.back();
};

const submit = () => {
	form.post(route('password.email'), {
		onFinish: () => form.reset('email'),
	});
};
</script>
