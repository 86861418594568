<template>
	<section id="callback-modal" class="modal-bg" :class="{ 'show': showModal }">
		<div class="modal-window py-xxl-25 py-xxl-20 py-lg-16 py-12 px-lg-12 px-md-10 px-8">
			<!-- emit event to parent component to close the modal -->
			<button class="close" @click="$emit('close-callback-request')"></button>
			<div class="d-flex justify-content-lg-start justify-content-center mb-4">
				<i class="fa-duotone fa-phone-arrow-down-left theme-sertus fs-2"></i>
			</div>
			<h2 class="text-lg-start text-center mb-2">Request a Callback</h2>
			<p class="text-lg-start text-center mb-4">Give us a few details and we'll get back to you ASAP</p>

			<form @submit.prevent="requestCallback">
				<div class="form-row mb-6">
					<TextInput id="name" type="text" v-model="requestForm.name" placeholder-value="Full Name" required autofocus tabindex="1" />
					<InputError :message="requestForm.errors.name"></InputError>
				</div>

				<div class="form-row mb-6">
					<TextInput id="phone" type="phone" v-model="requestForm.phone" placeholder-value="Phone Number" required autofocus tabindex="2" />
					<InputError :message="requestForm.errors.phone"></InputError>
				</div>

				<div class="form-row mb-6">
					<textarea name="topic" id="topic" cols="30" rows="10"
										v-model="requestForm.topic"
										placeholder="What would you like to discuss?"
										required
										autofocus
										tabindex="3">
					</textarea>
					<InputError :message="requestForm.errors.topic"></InputError>
				</div>

				<button type="submit" class="btn-secondary text-tertiary" tabindex="4">
					<span>Submit callback request</span>
				</button>
			</form>
		</div>
	</section>
</template>

<script setup>
import TextInput from '@/Components/TextInput.vue';
import InputError from '@/Components/InputError.vue';
import { useForm } from '@inertiajs/vue3';

const requestForm = useForm({
	name: '',
	phone: '',
	topic: '',
});

const props = defineProps({
	showModal: Boolean,
});

const emit = defineEmits(['closeCallbackRequest']);

const requestCallback = () => {
	Swal.fire({
		icon: 'info',
		title: "Logging callback ...",
		text: 'Please wait whilst we send you callback request to Sertus.',
		allowEnterKey: false,
		allowEscapeKey: false,
		allowOutsideClick: false,
		showConfirmButton: false,
		showDenyButton: false
	});

	axios.post(route('send-callback-request'), {
		'name' : requestForm.name,
		'phone' : requestForm.phone,
		'topic' : requestForm.topic
	})
	.then(response => response.data)
	.then(data => {
		requestForm.reset('name', 'phone', 'topic')
		setTimeout(() => {
			Swal.close();
			Swal.fire({
				icon: 'info',
				title: "Callback submitted ...",
				text: 'Thank you, we have sent your callback request to the team at Sertus. One of our support staff will be in touch soon about your request.',
				confirmButtonText: 'Okay',
				timer: 15000,
			});
			emit('closeCallbackRequest');
		}, 250);
	})
	.catch(error => {
		requestForm.reset('name', 'phone', 'topic')
		Swal.fire({
			icon: 'error',
			title: 'Callback Request Failed',
			text: 'Callback request submission has failed. Please try again or email Sertus to submit your callback request manually.',
			showConfirmButton: true,
			confirmButtonText: 'Okay'
		});
	});
};
</script>
