<template>
	<Head title="Your Quotes"></Head>
	<ClientLayout>
		<Breadcrumbs></Breadcrumbs>
		<ImpersonationBanner :impersonating="is_impersonating"></ImpersonationBanner>

		<section id="content-panel" class="my-account py-lg-16 py-10 px-xl-16 px-lg-8 px-4" :class="{impersonating: is_impersonating}">
			<div class="row mb-lg-10 mb-8">
				<div class="col-12">
					<h1 class="h2 text-lg-start text-center mb-0">Your Quote Requests</h1>
				</div>
			</div>

			<div class="row mb-n2" id="quote-rows">
				<div class="col-12 quote-row-container mb-2" v-for="quote in quotes.data">
					<div class="quote-row px-4 pt-4 pb-3">
						<div class="row">
							<div class="col-xxxl-1 col-xxl-2 d-xxl-block d-none border-right">
								<div class="d-flex flex-column justify-content-center h-100">
									<h5 class="text-secondary mb-xxxl-0 mb-2">#{{ quote.sertus_quote_num }}</h5>
									<span class="d-xxxl-none d-inline-block">{{ quote.quote_date }}</span>
								</div>
							</div>

							<div class="col-1 d-xxxl-block d-none border-right">
								<div class="d-flex align-items-center justify-content-center h-100">
										<span>{{ quote.quote_date }}</span>
								</div>
							</div>

							<div class="col-6 col-lg d-xxl-block d-none border-right">
								<div class="d-flex align-items-center h-100">
									<div>
										<h5 class="mb-0">{{ quote.project_name }}</h5>
									</div>

									<div class="ms-auto">
										<h6 class="mb-0 quote-status complete">{{ quote.display_status }}</h6>
									</div>

									<div class="ms-xxl-12 ms-lg-10 ms-8">
										<h6 class="text-quaternary mb-0">£{{ quote.total }}</h6>
									</div>
								</div>
							</div>

							<div class="col-2 d-xxl-block d-none">
								<div class="d-flex align-items-center justify-content-end h-100">
									<Link :href="$route('account.single-quote', {'quote': quote.id})">
										<button class="btn-underline-quaternary">
											<i class="fa-duotone fa-list theme-sertus me-3"></i>
											<span class="d-xxxl-inline-block d-none">View Quote Details</span>
											<span class="d-xxxl-none d-inline-block">View Quote</span>
										</button>
									</Link>
								</div>
							</div>

							<div class="col-7 d-xxl-none d-block border-right">
								<div class="d-flex flex-column align-items-start">
									<h5 class="text-secondary mb-1"><span class="text-primary">Project Name: </span>{{ quote.project_name }}</h5>
									<h5 class="text-secondary mb-1"><span class="text-primary">Quote #: </span>{{ quote.sertus_quote_num }}</h5>
									<span class="d-block"><span class="fw-bold text-primary">Quoted on: </span>{{ quote.quote_date }}</span>
								</div>
							</div>
							<div class="col-xl-3 border-right d-xxl-none d-xl-block d-none">
								<div class="d-flex flex-column justify-content-center align-items-end h-100">
									<h6 class="text-end text-quaternary fw-bold mb-4">£{{ quote.total }}</h6>
									<h6 class="text-end quote-status complete mb-0">{{ quote.display_status }}</h6>
								</div>
							</div>
							<div class="col-5 col-xl-2 d-xxl-none d-block">
								<div class="d-flex flex-column justify-content-center align-items-end h-100">
									<h6 class="d-xl-none d-block text-quaternary fw-bold mb-2">£{{ quote.total }}</h6>
									<h6 class="d-xl-none d-block text-end mb-2 quote-status complete">Quote Requested</h6>
									<Link :href="$route('account.single-quote', {'quote': quote.id})">
										<button class="btn-underline-quaternary">
											<i class="fa-duotone fa-list theme-sertus me-3"></i>
											<span>View Quote</span>
										</button>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-auto mt-lg-10 mt-8">
					<Link :href="$route('account.overview')">
						<button class="btn-underline-secondary">
							<i class="fa-regular fa-chevron-left text-primary mb-n1 me-3"></i>
							<span>Back</span>
						</button>
					</Link>
				</div>
			</div>
		</section>
	</ClientLayout>
</template>

<script setup>
import ClientLayout from '@layouts/ClientLayout.vue';
import Breadcrumbs from '@/Components/Breadcrumbs.vue';
import ImpersonationBanner from "@js/Components/ImpersonationBanner.vue";
import { Head, Link, usePage } from '@inertiajs/vue3';
import { computed, ref } from "vue";

const props = defineProps({
	'quotes' : Object
})
const quotes = ref(props.quotes);
const is_impersonating = computed(() => usePage().props.global.is_impersonating);
</script>
