<template>
	<Head title="Admin | Create Admin"></Head>
	<section id="form-page" style="background-image: url('/storage/images/Login hero image.jpg')">
		<div class="container py-xl-20 py-md-16 py-12 h-100">
			<div class="row justify-content-center h-100">
				<div class="col-xl-7 col-lg-9 col-md-10 col-12 d-flex flex-column justify-content-center align-items-start h-100">
					<a @click="back" class="d-xl-block d-none mb-4">
						<button class="btn-secondary flip-icon text-tertiary">
							<span>Go back</span>
						</button>
					</a>

					<div class="form-wrapper px-lg-12 px-md-8 px-6 py-xl-20 py-lg-16 py-12">
						<a @click="back" class="d-xl-none d-block me-auto mb-6">
							<button class="btn-secondary flip-icon text-tertiary">
								<span>Back</span>
							</button>
						</a>

						<div class="d-flex justify-content-lg-start justify-content-center">
							<img src="/storage/images/Sertus Logo.svg" alt="Black Sertus Logo" class="sertus-logo lg mb-lg-12 mb-md-10 mb-8" />
						</div>
						<h1 class="h4 text-lg-start text-center text-secondary mb-0">Create Admin User</h1>
						<h2 class="text-lg-start text-center mb-lg-10 mb-8">Sertus Portal</h2>
						<form @submit.prevent="submit" id="register-form" class="form-2-col">
							<div class="row justify-content-end">
								<div class="col-lg-6 col-12 mb-md-8 mb-6">
									<div class="form-row">
										<InputLabel for="name" value="Name" />
										<TextInput
											id="name"
											type="text"
											v-model="form.name"
											placeholder-value="Name"
											required
											autofocus
											autocomplete="name"
											tabindex="1"
										/>
										<InputError class="mt-2" :message="form.errors.name" />
									</div>
								</div>

								<div class="col-lg-6 col-12 mb-md-8 mb-6">
									<div class="form-row">
										<InputLabel for="email" value="Email" />
										<TextInput
											id="email"
											type="email"
											placeholder-value="Email"
											v-model="form.email"
											required
											autocomplete="username"
											tabindex="2"
										/>
										<InputError class="mt-2" :message="form.errors.email" />
									</div>
								</div>

								<div class="col-lg-6 col-12 mb-md-8 mb-6">
									<div class="form-row">
										<InputLabel for="password" value="Password" />
										<TextInput
											id="password"
											type="password"
											placeholder-value="Password"
											v-model="form.password"
											required
											autocomplete="new-password"
											tabindex="3"
										/>
										<InputError class="mt-2" :message="form.errors.password" />
									</div>
								</div>

								<div class="col-lg-6 col-12 mb-md-8 mb-6">
									<div class="form-row">
										<InputLabel for="password_confirmation" value="Confirm Password" />
										<TextInput
											id="password_confirmation"
											type="password"
											placeholder-value="Confirm Password"
											v-model="form.password_confirmation"
											required
											autocomplete="new-password"
											tabindex="4"
										/>
										<InputError class="mt-2" :message="form.errors.password_confirmation" />
									</div>
								</div>

								<div class="col-12 mb-md-8 mb-6">
									<div class="form-row">
										<InputLabel for="permission_level" value="User Permission Level"></InputLabel>
										<select name="permission_level" id="permission_level" v-model="form.permission_level" tabindex="5">
											<option selected default disabled class="placeholder-option" value="">
												Choose a permission level
											</option>
											<option v-for="role in props.roles.data" :value="role.name">
												{{ uppercase(role.name) }}
											</option>
										</select>
										<InputError class="mt-2" :message="form.errors.permission_level"></InputError>
									</div>
								</div>

								<div class="col-lg-6 col-12">
									<div class="d-flex flex-column align-items-end">
										<Link :href="route('admin.login')" class="mb-2 d-none">
											<button class="btn-underline-secondary">
												<span>Already registered with us?</span>
											</button>
										</Link>

										<PrimaryButton
											class="ml-4"
											id="register-submit"
											:class="{ 'disabled': form.processing }"
											:disabled="form.processing"
											tabindex="6"
										>
											Register
										</PrimaryButton>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script setup>
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import { Head, Link, useForm } from '@inertiajs/vue3';

const props = defineProps({
	roles: Object,
});

const form = useForm({
	name: '',
	email: '',
	password: '',
	password_confirmation: '',
	permission_level: '',
});

const back = () => {
	window.history.back();
};

const submit = () => {
	form.post(route('admin.register-user'), {
		onFinish: () => form.reset('password', 'password_confirmation', 'permission_level'),
	});
};

const uppercase = (text) => {
	const words = text.replace('_', ' ').split(' ');
	for (let i = 0; i < words.length; i++) {
		words[i] = words[i][0].toUpperCase() + words[i].substr(1);
	}

	let output = words.join(' ');
	output.trim();
	return output;
};
</script>
