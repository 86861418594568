<template>
	<Head title="Checkout"></Head>
	<section id="client-portal">
		<Navbar></Navbar>
		<Breadcrumbs></Breadcrumbs>

		<section id="content-panel" class="py-lg-16 py-10 px-xl-16 px-lg-8 px-4">
			<div class="row justify-content-center">
				<div class="col-12 d-xxl-none mb-md-10 mb-8">
					<h1 class="h2 text-center mb-0">Checkout</h1>
				</div>
				<div class="col-xxl-6 col-lg-9 col-md-10 col-12 order-xxl-1 order-2">
					<h1 class="h2 d-none d-xxl-block text-xl-start text-center mb-xl-12 mb-md-10 mb-8">Checkout</h1>

					<h4 class="text-xl-start text-center text-secondary fw-extra mb-2">Choose Delivery Address</h4>
					<div class="d-flex flex-lg-row flex-column align-items-center mt-lg-0 mt-4">
						<div class="checkbox-container">
							<input type="checkbox" id="add-address" name="add-address" v-model="newAddress"/>
							<label class="ps-10" for="add-address">Use a different delivery address.</label>
						</div>

						<span class="fw-bold address-toggle px-5 my-lg-0 mt-3 mb-2" :class="{'hidden': newAddress}">OR</span>

						<div class="d-flex flex-column flex-fill address-toggle " :class="{'hidden': newAddress}">
							<div class="select-wrapper mb-0">
								<select name="delivery-address" id="delivery-address" class="address-select p-8" v-model="form.delivery_address_id">
									<option value=null selected disabled>Please select a delivery address ...</option>
									<option :value="address.id" v-for="address in addresses">
										{{ address.address_1 }},
										<template v-if="address.address_2 !== ''">{{ address.address_2 }},</template>
										{{ address.city }},
										<template v-if="address.state !== ''">{{ address.state }},</template>
										{{ address.zip }}
									</option>
								</select>
							</div>
							<InputError class="mt-2" :message="form.errors.delivery_address_id" remove-margin="true"></InputError>
						</div>
					</div>

					<div class="border-bottom border-senary mt-lg-0 mt-6 pb-6" v-if="newAddress">
						<form action="" id="checkout-address-form">
							<div class="row mx-n2 mb-n4">
								<div class="col-md-6 col-12 mb-4 px-2 form-row">
									<InputLabel class="hidden" for="address_1" value="Site Contact Name" />
									<TextInput
											id="contact_name"
											type="text"
											v-model="address_form.contact_name"
											placeholder-value="Site Contact Name"
											required
											autofocus
											tabindex="1"
									/>
									<InputError class="mt-2" :message="address_form.errors.contact_name" remove-margin="true" />
								</div>
								<div class="col-md-6 col-12 mb-4 px-2 form-row">
									<InputLabel class="hidden" for="address_1" value="Site Contact Number" />
									<TextInput
											id="contact_number"
											type="text"
											v-model="address_form.contact_number"
											placeholder-value="Site Contact Number"
											required
											autofocus
											tabindex="1"
									/>
									<InputError class="mt-2" :message="address_form.errors.contact_number" remove-margin="true" />
								</div>
								<div class="col-md-6 col-12 mb-4 px-2 form-row">
									<InputLabel class="hidden" for="address_1" value="Building Number/Name" />
									<TextInput
											id="address_1"
											type="text"
											v-model="address_form.address_1"
											placeholder-value="Building Number/Name"
											required
											autofocus
											tabindex="1"
									/>
									<InputError class="mt-2" :message="address_form.errors.address_1" remove-margin="true" />
								</div>
								<div class="col-md-6 col-12 mb-4 px-2 form-row">
									<InputLabel class="hidden" for="address_2" value="Street" />
									<TextInput
											id="address_2"
											type="text"
											v-model="address_form.address_2"
											placeholder-value="Street"
											required
											tabindex="2"
									/>
									<InputError class="mt-2" :message="address_form.errors.address_2" remove-margin="true" />
								</div>
								<div class="col-md-6 col-12 mb-4 px-2 form-row">
									<InputLabel class="hidden" for="address_3" value="Town (Optional)" />
									<TextInput
											id="address_3"
											type="text"
											v-model="address_form.address_3"
											placeholder-value="Town (Optional)"
											tabindex="3"
									/>
									<InputError class="mt-2" :message="address_form.errors.address_3" remove-margin="true" />
								</div>
								<div class="col-md-6 col-12 mb-4 px-2 form-row">
									<InputLabel class="hidden" for="city" value="City" />
									<TextInput
											id="city"
											type="text"
											v-model="address_form.city"
											placeholder-value="City"
											required
											tabindex="4"
									/>
									<InputError class="mt-2" :message="address_form.errors.city" remove-margin="true" />
								</div>
								<div class="col-md-6 col-12 mb-4 px-2 form-row">
									<InputLabel class="hidden" for="state" value="County (Optional)" />
									<TextInput
											id="state"
											type="text"
											v-model="address_form.state"
											placeholder-value="County (Optional)"
											required
											tabindex="5"
									/>
									<InputError class="mt-2" :message="address_form.errors.state" remove-margin="true" />
								</div>
								<div class="col-md-6 col-12 mb-4 px-2 form-row">
									<InputLabel class="hidden" for="country" value="Country" />
									<TextInput
											id="country"
											type="text"
											v-model="address_form.country"
											placeholder-value="Country"
											required
											tabindex="6"
									/>
									<InputError class="mt-2" :message="address_form.errors.country" remove-margin="true" />
								</div>
								<div class="col-md-6 col-12 mb-4 px-2 form-row">
									<InputLabel class="hidden" for="zip" value="Postcode" />
									<TextInput
											id="zip"
											type="text"
											v-model="address_form.zip"
											placeholder-value="Postcode"
											required
											tabindex="7"
									/>
									<InputError class="mt-2" :message="address_form.errors.zip" remove-margin="true" />
								</div>
							</div>
						</form>
					</div>

					<div class="d-none border-bottom border-senary pb-6 mt-lg-12 mt-10">
						<h3 class="h5 text-lg-start text-center text-secondary fw-extra mb-4">Billing Address:</h3>
						<div class="checkbox-container">
							<input type="checkbox" name="address-match" id="address-match" v-model="sameBilling"/>
							<label class="fw-bold fs-5 ps-12" for="address-match">My billing address is the same as my delivery address.</label>
						</div>

						<div class="d-flex flex-column mt-4" v-if="!sameBilling">
							<div class="select-wrapper flex-fill me-md-2 mb-md-0 mb-4">
								<select name="billing-address" id="billing-address" class="address-select p-8" v-model="form.billing_address_id">
									<option value=null selected disabled>Please select a billing address ...</option>
									<option :value="address.id" v-for="address in billingAddresses">
										{{ address.address_1 }},
										<template v-if="address.address_2 !== ''">{{ address.address_2 }},</template>
										{{ address.city }},
										<template v-if="address.state !== ''">{{ address.state }},</template>
										{{ address.zip }}
									</option>
								</select>
							</div>
							<InputError class="mt-2" :message="form.errors.billing_address_id"></InputError>
						</div>
					</div>

					<div class="pt-6">
						<div class="payment-methods mb-md-12 mb-10">
							<div class="payment-method-container">
								<button tabindex="8" class="payment-method py-6 p-8" @click="setMethod('credit')" :class="{ 'active-payment-method': paymentMethod == 'credit' }">
									<i class="fa-duotone fa-briefcase theme-sertus me-3"></i>
									<span class="fs-5 mb-n1 fw-bold">Pay with account credit.</span>
								</button>
							</div>
							<div class="payment-method-container">
								<button tabindex="9" class="payment-method py-6 p-8" @click="setMethod('card')" :class="{ 'active-payment-method': paymentMethod == 'card' }">
									<i class="fa-duotone fa-credit-card theme-sertus me-3"></i>
									<span class="fs-5 mb-n1 fw-bold">Pay with credit/debit card.</span>
								</button>
							</div>
						</div>

						<div class="row">
							<div class="col-lg-6 col-12 mb-lg-0 mb-6">
								<div class="d-flex flex-column justify-content-start mb-4">
									<InputLabel class="text-secondary fw-bold mb-2" for="po_num" value="Have a PO Number for your order? Enter it in the box below ..."></InputLabel>
									<TextInput
											id="po_number"
											type="text"
											v-model="form.po_number"
											placeholder-value="PO Number"
											tabindex="10"></TextInput>
									<InputError class="mt-2" :message="form.errors.po_number" remove-margin="true"></InputError>
								</div>
							</div>
							<div class="col-lg-6 col-12">
								<div class="d-flex flex-column justify-content-start mb-4" id="del-date-container">
									<InputLabel class="text-secondary fw-bold mb-2" for="delivery_date" value="Request a delivery date that suits you ..."></InputLabel>
									<VueDatePicker v-model="form.delivery_date"
																 :enable-time-picker="false"
																 :disabled-week-days="[6, 0]"
																 :highlight-week-days="[1, 2, 3, 4, 5]"
																 :min-date="addDays(new Date(), 5)"
																 :format-locale="enGB"
																 format="dd/MM/yyyy"
																 auto-apply></VueDatePicker>
									<InputError class="mt-2" :message="form.errors.delivery_date" remove-margin="true"></InputError>
									<p class="fw-light fs-6 mt-2 mb-0 px-2">Please note that this is only a requested date. We cannot guarantee that your order will arrive by this date!</p>
								</div>
							</div>
						</div>

						<div class="payment-form" id="card-payment" v-if="paymentMethod === 'card'">
							<h5 class="text-lg-start text-center text-secondary mb-4" v-if="no_basket">You must add some products to your project before you can check out! </h5>
							<form @submit.prevent="sendCardPayment">
								<div>
									<button type="submit" id="card-payment-button" class="btn-secondary text-tertiary py-5 payment-button" :class="{'disabled':no_basket}">
										<span>Pay via Pay360 Portal</span>
									</button>
								</div>
							</form>
						</div>
						<div class="payment-form" id="credit-payment" v-if="paymentMethod === 'credit'">
							<form @submit.prevent="sendCreditPayment">
								<div class="d-none mb-8">
									<h3 class="text-lg-start text-center mb-2">Your Sertus Credit Account Number:</h3>
									<h5 class="text-lg-start text-center text-secondary mb-0">{{ credit_account_no }}</h5>
								</div>

								<h5 class="text-lg-start text-center text-secondary mb-4" v-if="no_basket">You must add some products to your project before you can check out! </h5>
								<button type="submit" class="btn-secondary text-tertiary py-5 payment-button" :class="{'disabled': no_basket||form.processing }"><span>Pay using Account Credit</span></button>
							</form>
						</div>
					</div>
				</div>

				<div class="col-xxl-6 col-lg-0 col-md-10 col-12 order-xxl-2 order-1 mb-xxl-0 mb-12">
					<div class="border border-primary px-6 py-lg-12 py-md-10 py-8">
						<div class="d-flex align-items-center mb-xl-10 mb-lg-8 mb-6 w-100">
							<h3 class="d-flex align-items-center justify-content-lg-start justify-content-center text-lg-start text-center mb-0">
								<i class="fa-duotone fa-list theme-sertus theme-invert fs-2 me-lg-6 me-4 pb-1"></i>
								Your Order Details
							</h3>

							<span class="h3 text-senary fw-normal ms-auto mb-0">{{ project.name }}</span>
						</div>

						<template v-if="no_basket">
							<div class="d-flex flex-column align-items-center justify-content-center px-8 py-10">
								<img class="trolley-icon small mb-6" src="/storage/images/trolley.svg" alt="Trolley icon" />
								<h3 class="text-center mb-lg-4 mb-2">Your project is empty</h3>
								<h6 class="text-center text-secondary mb-0">Add products from the <a href="" class="text-underline">catalogue</a> to get started</h6>
								<div class="d-flex w-100 justify-content-center mt-4">
									<Link :href="$route('catalogue')">
										<button class="btn-outline-secondary text-secondary">
											<span>Product Catalogue</span>
										</button>
									</Link>
								</div>
							</div>
						</template>
						<template v-else>
							<OrderSummary :data="project"></OrderSummary>
						</template>
					</div>
				</div>
			</div>
		</section>
	</section>
</template>

<script setup>
import Breadcrumbs from '@/Components/Breadcrumbs.vue';
import Navbar from '@/Components/Navbar.vue';
import OrderSummary from '@/Components/LineItemsSummary.vue';
import InputError from "@js/Components/InputError.vue";
import TextInput from "@js/Components/TextInput.vue";
import InputLabel from "@js/Components/InputLabel.vue";
import { Link, Head, useForm, router } from '@inertiajs/vue3';
import { ref } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { enGB } from 'date-fns/locale';
import addDays from 'date-fns/addDays';

const props = defineProps({
	'delivery_addresses' : Object,
	'basket_project' : Object,
	'credit_account_no' : String,
	'no_basket' : Boolean
});

/* ================= */
/*  Lifecycle hooks  */
/* ================= */
const form = useForm({
	delivery_address_id: null,
	basket_id: props.basket_project.data.id,
	po_number : '',
	delivery_date: null
});

const address_form = useForm({
	'contact_name': '',
	'contact_number': '',
	'address_1': '',
	'address_2': '',
	'address_3': '',
	'city': '',
	'state': '',
	'country': 'United Kingdom',
	'zip': ''
});

const addresses = ref(props.delivery_addresses);
const project = ref(props.basket_project.data);
const newAddress = ref(false);
const paymentMethod = ref('credit');

const sameBilling = ref(false);
const billingAddresses = ref([]);

const setMethod = (method) => {
	paymentMethod.value = method;
};

const sendCardPayment = async () => {
	if(newAddress.value) {
		try {
			// if we have added a new address on the fly then we use the one time ship to route to send the payment
			var response = await axios.post(route('ots-card-payment'), {
				'contact_name': address_form.contact_name,
				'contact_number': address_form.contact_number,
				'address_1': address_form.address_1,
				'address_2': address_form.address_2,
				'address_3': address_form.address_3,
				'city': address_form.city,
				'state': address_form.state,
				'country': address_form.country,
				'zip': address_form.zip,
				'basket_id' : form.basket_id,
				'po_number' : form.po_number,
				'delivery_date' : form.delivery_date
			});
		}
		catch(error) {
			if(error.response.data.errors) {
				let errors = error.response.data.errors;
				for(const [key, value] of Object.entries(errors)) {
					address_form.errors[key] = value[0];
				}

				Swal.fire({
					icon: 'error',
					title: 'Pay360 Payment failed',
					text: 'Your payment via the Pay360 portal failed! You have been redirected to the checkout page to try making your payment again.',
					showConfirmButton: true,
					confirmButtonText: 'Okay'
				});
			}
			else {
				Swal.fire({
					icon: 'error',
					title: 'Pay360 Payment failed',
					text: error.response.data.message,
					showConfirmButton: true,
					confirmButtonText: 'Okay'
				});
			}

			return;
		}
	}
	else {
		try {
			// otherwise we can use the route for existing addresses
			var response = await axios.post(route('card-payment'), {
				'delivery_address_id' : form.delivery_address_id,
				'basket_id' : form.basket_id,
				'po_number' : form.po_number,
				'delivery_date' : form.delivery_date
			});
		}
		catch(error) {
			if(error.response.data.errors) {
				let errors = error.response.data.errors;
				for(const [key, value] of Object.entries(errors)) {
					console.log(form);
					form.errors[key] = value[0];
				}

				Swal.fire({
					icon: 'error',
					title: 'Pay360 Payment failed',
					text: 'Your payment via the Pay360 portal failed! You have been redirected to the checkout page to try making your payment again.',
					showConfirmButton: true,
					confirmButtonText: 'Okay'
				});
			}
			else {
				Swal.fire({
					icon: 'error',
					title: 'Pay360 Payment failed',
					text: error.response.data.message,
					showConfirmButton: true,
					confirmButtonText: 'Okay'
				});
			}

			return;
		}
	}

	let data = response.data;
	form.delivery_address_id = null;
	Swal.fire({
		icon: 'info',
		title: 'Redirecting to Pay360 ...',
		text: 'Hold tight whilst we direct you to the pay360 payment portal to complete your purchase.',
		showConfirmButton: false,
		showDenyButton: false,
		allowEscapeKey: false,
		allowEnterKey: false,
		allowOutsideClick: false
	});
	window.location = data.payment_url;
	setTimeout(() => {
		Swal.close();
	}, 1000);
};

const sendCreditPayment = () => {
	Swal.fire({
		icon: 'info',
		title: 'Processing Payment',
		text: 'Please wait whilst we process the payment in our system.',
		showConfirmButton: false,
		showDenyButton: false,
		allowEscapeKey: false,
		allowOutsideClick: false,
		allowEnterKey: false
	});

	if(newAddress.value) {
		address_form.transform(data => ({
			...data,
			'basket_id' : form.basket_id,
			'po_number' : form.po_number,
			'delivery_date': form.delivery_date
		}))
		.post(route('ots-credit-payment'), {
			onSuccess: () => {
				Swal.close();
			},
			onError: () => {
				Swal.close();
				setTimeout(() => {
					Swal.fire({
						icon: 'error',
						title: 'Credit Payment Failed',
						text: 'Your payment using account credit has failed. Please try again or use a different payment method to complete your purchase.',
						showConfirmButton: true,
						confirmButtonText: 'Okay'
					});
				}, 750);
			}
		});
	}
	else {
		form.post(route('credit-payment'), {
			onSuccess: () => {
				Swal.close();
				form.delivery_address_id = null;
			},
			onError: () => {
				Swal.close();
				setTimeout(() => {
					Swal.fire({
						icon: 'error',
						title: 'Credit Payment Failed',
						text: 'Your payment using account credit has failed. Please try again or use a different payment method to complete your purchase.',
						showConfirmButton: true,
						confirmButtonText: 'Okay'
					});
				}, 750);
			}
		});
	}
};

</script>