import { ref } from 'vue';
import Dropzone from "dropzone";

export default function useDropzone(props, partImages, queueLength) {
	console.log(queueLength.value);

	const inlineDropzone = () => {
		// create a dropzone that only accepts zip files
		let dropzone = new Dropzone('.dropzone', {
			autoProcessQueue: true,
			addRemoveLinks: false,
			headers: {
				'X-CSRF-TOKEN': props.csrf_token,
			},
			maxFilesize: 2048, // a max 2MB upload size
			parallelUploads: 4, // this is not used when uploadMultiple has a value of false
			paramName: 'uploads',
			previewsContainer: '#preview-container',
			previewTemplate: document.querySelector('.preview-wrapper').innerHTML,
			uploadMultiple: false,

			addedFile: function(file) {
				file.previewTemplate = Dropzone.createElement(this.options.previewTemplate);
			},
		});

		return zoneFunctionsConfig(dropzone);
	}

	const zoneFunctionsConfig = (dropzone) => {
		dropzone.on('addedfiles', function () {
			queueLength.value = dropzone.getQueuedFiles().length;
		});
		dropzone.on('removedfile', function (file) {
			queueLength.value = dropzone.getQueuedFiles().length;
		});

		dropzone.on('complete', function(file) {
			if (file.status === 'success') {
				// after showing the alert, we remove the file from the queue
				this.removeFile(file);
				// force a short delay for the user's benefit
				setTimeout(() => {
					Swal.close();

					window.Toast.fire({
						icon: 'success',
						title: 'Images uploaded successfully!'
					});
				}, 1000);
			}
		});

		dropzone.on('success', function(file, response) {
			console.log(response);
			partImages.value.push(response.upload);

			console.log(partImages.value);
		});

		dropzone.on('error', function(file, error) {
			Swal.close();

			window.Toast.fire({
				icon: 'error',
				title: 'File upload failed',
				text: 'Sorry, one or more of your file uploads failed. Please check the dtails page for more info!',
				timer: 5000
			});

			this.removeFile(file);
			queueLength.value = dropzone.getQueuedFiles().length;
		});

		return dropzone; // retun the dropzone for use outsize of the init function via a Vue ref
	}

	return {
		inlineDropzone
	}
}