<template>
	<Head title="Login"></Head>
	<section id="client-login" class="form-page">
		<div class="bg-container">
			<video autoplay muted loop poster="/storage/images/pod-b-roll-1.jpg" src="/storage/videos/pod-b-roll-2.mp4"></video>
			<div class="bg-poster" style="background-image: url('/storage/images/pod-b-roll-1.jpg')"></div>
		</div>
		<div class="container">
			<div class="row justify-content-lg-start justify-content-center">
				<div class="col-xxl-4 col-xl-5 col-lg-6 col-md-8 col-sm-10 col-12">
					<div class="bg-tertiary py-xl-20 py-md-16 py-12 px-xl-16 px-md-12 px-8">
						<div class="d-flex justify-content-lg-start justify-content-center mb-lg-10 mb-8">
							<ApplicationLogo></ApplicationLogo>
						</div>

						<h1 class="h2 text-lg-start text-center mb-3">Portal</h1>
						<p class="fw-light text-lg-start text-center mb-lg-12 mb-10">Login to the portal to see your products and prices</p>

						<form @submit.prevent="submit">
							<div class="form-row mb-6">
								<InputLabel class="hidden" for="email" value="email" />
								<TextInput
									id="email"
									type="email"
									v-model="form.email"
									placeholder-value="Email"
									required
									autofocus
									autocomplete="email"
									tabindex="1"
								/>
								<InputError class="mt-2" :message="form.errors.email" />
							</div>

							<div class="form-row mb-6">
								<InputLabel class="hidden" for="password" value="password" />
								<TextInput
									id="password"
									type="password"
									v-model="form.password"
									placeholder-value="Password"
									required
									autofocus
									autocomplete="password"
									tabindex="2"
								/>
								<InputError class="mt-2" :message="form.errors.password" />
							</div>

							<div class="form-row d-flex flex-column align-items-end">
								<button class="login-button w-100" type="submit"></button>
								<Link :href="$route('password.request')">
									<button class="btn-underline-primary fw-light">Forgotten your <span class="text-secondary ms-1">password?</span></button>
								</Link>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script setup>
import ApplicationLogo from '@/Components/ApplicationLogo.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import TextInput from '@/Components/TextInput.vue';
import { Head, Link, useForm } from '@inertiajs/vue3';

defineProps({
	canResetPassword: Boolean,
	status: String,
});

const form = useForm({
	email: '',
	password: '',
	remember: false,
});

const submit = () => {
	form.post(route('login'), {
		onFinish: () => form.reset('password'),
	});
};
</script>
