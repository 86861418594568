<template>
	<AdminLayout>
		<section id="content-panel" class="py-lg-10 py-8 px-xl-16 px-lg-8 px-4">
			<div class="row mb-md-8 mb-6">
				<div class="col-12">
					<div class="dashboard-title-block">
						<h1 class="h3 text-lg-start text-center mb-lg-0 mb-2">Edit Product Details</h1>
						<p class="text-lg-start text-center ms-lg-auto mb-0">Edit products that are currently in the system.</p>
					</div>
				</div>
			</div>
			<div class="row mb-lg-8 mb-6">
				<div class="col-12">
					<div class="d-flex flex-xl-row flex-column align-items-xl-center">
						<h3 class="h5 fw-extra text-secondary text-lg-start text-center mb-lg-0 mb-2">Product List</h3>
						<p class="text-lg-start text-center mb-0 ms-xl-auto">
							To see variations, click the <i class="fa-duotone fa-eye theme-sertus px-2"></i> on a master product
						</p>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-auto ms-auto d-xxl-none d-block mb-lg-6 mb-4">
					<Bootstrap5Pagination :data="groups" @pagination-change-page="changePage" :limit="2"/>
				</div>

				<div class="col-12">
					<div class="table-head bg-primary py-3 px-6 pe-md-3">
						<div class="d-flex flex-md-row flex-column align-items-md-center">
							<span class="text-tertiary fs-6 mb-md-n1 mb-3">
								Showing <span class="text-quaternary">{{ groups.meta.from }}</span>
								to <span class="text-quaternary">{{ groups.meta.to }}</span>
								of <span class="text-quaternary">{{ groups.meta.total }}</span>
							</span>

							<div class="pagination-wrapper d-xxl-flex d-none align-items-center">
								<span class="text-tertiary mb-n1"> Page </span>
								<div class="ms-8">
									<Bootstrap5Pagination :data="groups" @pagination-change-page="getGroups" :limit="2"/>
								</div>
							</div>

							<form class="search-wrapper" @submit.prevent="findParts">
								<input type="text" name="product-search" id="product-search" v-model="form.search" placeholder="Search by name or SKU ..." />
								<i class="fa-duotone fa-magnifying-glass theme-sertus"></i>
							</form>
						</div>
					</div>

					<div class="table-container d-flex justify-content-center">
						<table id="part-list-table">
							<tbody>
								<template v-for="(group, groupIndex) in groups.data" :key="group.id">
									<tr :id="'master-' + group.id" class="master-row" :class="{'dark' : groupIndex % 2 === 0}">
										<td>
											<div class="d-flex align-items-center">
												<i class="fa-regular fa-circle-dot table-icon me-3"></i>
												<span class="row-tag mb-n1">Master</span>
											</div>
										</td>

										<td>
											<h5 class="text-start fw-normal mt-lg-1 mb-lg-1 mb-2">{{ group.name }}</h5>
											<div class="d-xxl-none d-flex align-items-center mb-xxl-1 mb-2">
												<span class="mb-n1"> SKU: </span>
												<span class="fw-bold text-secondary ms-xl-10 ms-lg-8 ms-4 mb-n1">{{ group.SKU }}</span>
											</div>
											<div class="d-xxl-none d-flex align-items-center justify-content-end">
												<span class="mb-n1">Variations:</span>
												<div class="d-flex align-items-center ms-4">
													<button class="expand-button me-2" @click="collapse" :id="'master-toggle-' + group.id">
														<i class="fa-duotone fa-eye theme-sertus"></i>
													</button>
													<span class="text-secondary mb-n1">{{ group.variations.length }}</span>
												</div>
											</div>
											<div class="d-xxl-none d-flex justify-content-end mt-4">
												<Link v-bind:href="$route('admin.prod-group-details', { 'group': group.id })">
													<button class="btn-outline-secondary"><span>Edit</span></button>
												</Link>
											</div>
										</td>

										<td class="d-xxl-table-cell d-none">
											<div class="d-flex">
												<span class="mb-n1 me-4"> SKU: </span>
												<span class="fw-bold text-secondary ms-auto mb-n1">{{ group.SKU }}</span>
											</div>
										</td>

										<td class="d-xxl-table-cell d-none">
											<div class="d-flex justify-content-end">
												<div class="d-flex align-items-center me-4">
													<span class="mb-n1 me-4">Variations:</span>
													<button class="expand-button me-2" @click="collapse" :id="'master-toggle-' + group.id">
														<i class="fa-duotone fa-eye theme-sertus"></i>
													</button>
													<span class="text-secondary mb-n1">{{ group.variations.length }}</span>
												</div>

												<template v-if="$can('edit prod groups')">
													<button class="enable-btn btn-quaternary text-tertiary me-4" v-show="group.disabled" @click="toggleGroup(group.id)">
														<span>Enable</span>
													</button>
													<button class="disable-btn btn-outline-primary text-secondary me-4" v-show="!group.disabled" @click="toggleGroup(group.id)">
														<span>Disable</span>
													</button>
												</template>

												<Link v-bind:href="$route('admin.prod-group-details', { 'group': group.id })">
													<button class="btn-outline-secondary">
														<span v-if="$can('edit prod groups')">Edit</span>
														<span v-else>View</span>
													</button>
												</Link>
											</div>
										</td>
									</tr>

									<tr
											:data-parent="'#master-' + group.id"
											class="variation-row"
											:class="{'last' : index === (group.variations.length - 1), 'dark' : index % 2 === 1}"
											v-for="(part, index) in group.variations" :key="part.id">
										<td>
											<div class="d-flex align-items-center">
												<i class="fa-regular fa-code-branch table-icon me-3"></i>
												<span class="row-tag mb-n1">Variation</span>
											</div>
										</td>

										<td class="mobile-last">
											<div class="py-xxl-4">
												<h5 class="text-start fw-normal mb-n1">{{ part.name }}</h5>
												<div class="d-xxl-none d-flex align-items-start mt-2">
													<span class="mb-n1"> Category: </span>
													<span class="text-secondary fw-bold ms-2 mb-n1">{{ part.category }}</span>
												</div>
												<div class="d-xxl-none d-flex align-items-start mt-2">
													<span class="mb-n1"> SKU: </span>
													<span class="fw-bold text-secondary ms-2 mb-n1">{{ part.SKU }}</span>
												</div>

												<div class="d-xxl-none d-flex justify-content-end mt-4">
													<Link v-bind:href="$route('admin.part-details', {'part': part.id})" class="d-block ms-md-auto">
														<button class="btn-outline-secondary"><span>Edit</span></button>
													</Link>
												</div>
											</div>
										</td>

										<td class="d-xxl-table-cell d-none">
											<div class="d-flex flex-xxxl-row flex-column align-items-xxxl-center align-items-start">
												<div class="me-xxxl-4">
													<span class="mb-n1"> Category: </span>
													<span class="text-secondary fw-bold ms-2 mb-n1">{{ part.category }}</span>
												</div>

												<div class="ms-xxxl-auto mt-2">
													<span class="mb-n1"> SKU: </span>
													<span class="fw-bold text-secondary ms-2 mb-n1">{{ part.SKU }}</span>
												</div>
											</div>
										</td>

										<td class="d-xxl-table-cell d-none">
											<div class="d-flex justify-content-end">
												<template v-if="$can('edit parts')">
													<button class="enable-btn btn-quaternary text-tertiary me-4" v-show="part.disabled" @click="togglePart(part.id, group.id)">
														<span>Enable</span>
													</button>
													<button class="disable-btn btn-outline-primary text-secondary me-4" v-show="!part.disabled" @click="togglePart(part.id, group.id)">
														<span>Disable</span>
													</button>
												</template>

												<Link v-bind:href="$route('admin.part-details', {'part': part.id})" class="d-block mt-md-0 mt-2">
													<button class="btn-outline-secondary">
														<span v-if="$can('edit parts')">Edit</span>
														<span v-else>View</span>
													</button>
												</Link>
											</div>
										</td>
									</tr>
								</template>
							</tbody>
						</table>
					</div>

				</div>

				<div class="col-auto ms-auto d-xxl-none d-block mt-lg-6 mt-4">
					<Bootstrap5Pagination :data="groups" @pagination-change-page="getGroups" :limit="2"/>
				</div>
			</div>
		</section>
	</AdminLayout>
</template>

<script setup>
import { Link, useForm } from '@inertiajs/vue3';
import AdminLayout from '@layouts/AdminLayout.vue';
import Bootstrap5Pagination from "laravel-vue-pagination/src/Bootstrap5Pagination.vue";
import { onBeforeMount, onMounted, ref } from 'vue';
import useGroups from "@js/Composables/groups";

const {groups, getGroups} = useGroups();
const rowOpen = ref(false);
const form = useForm({
	search: ""
});

onBeforeMount(() => {
	getGroups();
})

// const currentPage = ref(1)
// const from = ref(1)
// const to = ref(0)
// const totalGroups = ref(0)

// const changePage = async (page = 1) => {
// 	const response = await axios.get('/sertus-admin/paginate-groups?page='+ page);
//
// 	groups.value = response.data; // get the whole response data object (including  pagination meta data)
// 	from.value = response.data.meta.from;
// 	to.value = response.data.meta.to;
// 	totalGroups.value = response.data.meta.total;
// 	currentPage.value = page;
// }

const collapse = (event) => {
	const target_id = event.target.id;

	let target = document.getElementById(target_id);
	let closestRow = target.closest('tr.master-row');
	let siblings = getSiblings(closestRow, 'variation-row');

	for (let sibling of siblings) {
		let _item = sibling;
		let parentId = '#' + closestRow.id;
		if (_item.dataset.parent === parentId) {
			_item.classList.toggle('show');
		}
	}

	closestRow.classList.toggle('expanded');
	let icon = target.querySelector('i');
	icon.classList.toggle('fa-eye');
	icon.classList.toggle('fa-eye-slash');

	rowOpen.value = !rowOpen.value;
};

/**
 * Helper function for getting the siblings of an element by class using vanilla
 *
 * @param current the element to find siblings of
 * @param targetClass the class that we wish to find elements attached to
 * @return the array of sibling elements
 */
const getSiblings = (current, targetClass) => {
	let siblings = [];
	for (let sibling of current.parentNode.children) {
		if (sibling !== current && sibling.classList.contains(targetClass)) {
			siblings.push(sibling);
		}
	}

	return siblings;
};

const togglePart = (part_id, group_id) => {
	showUpdatingModal();

	axios.put(route('admin.toggle-part-visibility'), {
		'part_id': part_id
	})
	.then(response => response.data)
	.then(data => {
		let variation = data.part;
		let groupIndex = groups.value.data.findIndex(item => item.id === group_id);
		let variations = groups.value.data[groupIndex].variations;
		let varIndex = variations.findIndex(item => item.id === variation.id);
		groups.value.data[groupIndex].variations[varIndex] = variation;

		setTimeout(() => {
			Swal.close();
			window.Toast.fire({
				icon: 'success',
				title: 'Item updated successfully!'
			});
		}, 500);
	})
	.catch(error => {
		console.log(error);
	});
}

const toggleGroup = id => {
	showUpdatingModal();

	axios.put(route('admin.toggle-group-visibility'), {
		'id': id
	})
	.then(response => response.data)
	.then(data => {
		const group = data.group;
		let groupIndex = groups.value.data.findIndex(item => item.id === group.id);
		groups.value.data[groupIndex] = group;

		setTimeout(() => {
			Swal.close();
			window.Toast.fire({
				icon: 'success',
				title: 'Item updated successfully!'
			});
		}, 500);
	})
	.catch(error => {
		console.log(error);
	});
}

const showUpdatingModal = () => {
	Swal.fire({
		icon: 'info',
		title: 'Updating part ...',
		text: 'Please wait whilst we update the part for you!',
		showConfirmButton: false,
		showDenyButton: false,
		allowEscapeKey: false,
		allowOutsideClick: false,
		allowEnterKey: false
	});
}

const changePage = () => {
	console.log('page');
}

const findParts = () => {
	axios.post(route('admin.search-parts'), {
		search: form.search
	})
	.then(resposne => response.data)
	.then(data => {
		console.log(data);
		form.reset();
	})
	.catch(error => {
		form.reset();
		window.Toast.fire({
			icon: 'error',
			title: 'Error searching parts!',
			timer: 5000
		});
	});
}
</script>
