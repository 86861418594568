<template>
	<Head title="Your Orders"></Head>
	<ClientLayout>
		<Breadcrumbs></Breadcrumbs>
		<ImpersonationBanner :impersonating="is_impersonating"></ImpersonationBanner>

		<section id="content-panel" class="my-account py-lg-16 py-10 px-xl-16 px-lg-8 px-4" :class="{impersonating: is_impersonating}">
			<div class="row mb-lg-10 mb-8">
				<div class="col-12">
					<h1 class="h2 text-lg-start text-center mb-0">Your Orders</h1>
				</div>
			</div>

			<div class="row mb-n2" id="order-rows">
				<div class="col-12 order-row-container mb-2" v-for="order in orders.data">
					<div class="order-row px-4 pt-4 pb-3">
						<div class="row">
							<div class="col-xxxl-1 col-xxl-2 d-xxl-block d-none border-right">
								<div class="d-flex flex-column justify-content-center h-100">
									<h5 class="text-secondary mb-xxxl-0 mb-2">#{{ order.sertus_order_num }}</h5>
									<span class="d-xxxl-none d-inline-block">{{ order.order_date }}</span>
								</div>
							</div>

							<div class="col-1 d-xxxl-block d-none border-right">
								<div class="d-flex align-items-center justify-content-center h-100">
									<span>{{ order.order_date }}</span>
								</div>
							</div>

							<div class="col-6 col-lg d-xxl-block d-none border-right">
								<div class="d-flex align-items-center h-100">
									<div>
										<p class="mb-4 fw-bold">{{ order.project_name }}</p>
										<p class="fs-5 me-4 mb-0">{{ order.delivery_address }}</p>
									</div>

									<div class="ms-auto">
										<h6 class="mb-0 order-status complete">{{ order.status }}</h6>
									</div>

									<div class="ms-xxl-12 ms-lg-10 ms-8">
										<h6 class="text-quaternary mb-0">£{{ order.total }}</h6>
									</div>
								</div>
							</div>

							<div class="col-2 d-xxl-block d-none">
								<div class="d-flex align-items-center justify-content-end h-100">
									<Link :href="$route('account.single-order', {'order': order.id})">
										<button class="btn-underline-quaternary">
											<i class="fa-duotone fa-list theme-sertus me-3"></i>
											<span class="d-xxxl-inline-block d-none">View Order Details</span>
											<span class="d-xxxl-none d-inline-block">View Order</span>
										</button>
									</Link>
								</div>
							</div>

							<div class="col-7 d-xxl-none d-block border-right">
								<div class="d-flex flex-column align-items-start">
									<h5 class="text-primary mb-1">Project name: <span class="text-secondary">{{ order.project_name }}</span></h5>
									<h5 class="text-primary mb-1">Order #: <span class="text-secondary">{{ order.sertus_order_num }}</span></h5>
									<span class="d-block mb-4"><span class="fw-bold text-primary">Ordered on: </span>{{ order.order_date }}</span>
									<p class="fs-5 me-4 mb-0">{{ order.delivery_address }}</p>
								</div>
							</div>
							<div class="col-xl-3 border-right d-xxl-none d-xl-block d-none">
								<div class="d-flex flex-column justify-content-center align-items-end h-100">
									<h6 class="text-end text-quaternary fw-bold mb-4">£{{ order.total }}</h6>
									<h6 class="text-end order-status complete mb-0">{{ order.status }}</h6>
								</div>
							</div>
							<div class="col-5 col-xl-2 d-xxl-none d-block">
								<div class="d-flex flex-column justify-content-center align-items-end h-100">
									<h6 class="d-xl-none d-block text-quaternary fw-bold mb-2">£{{ order.total }}</h6>
									<h6 class="d-xl-none d-block text-end mb-2 order-status complete">{{ order.status }}</h6>
									<Link :href="$route('account.single-order', {'order': order.id})">
										<button class="btn-underline-quaternary">
											<i class="fa-duotone fa-list theme-sertus me-3"></i>
											<span>View order</span>
										</button>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-auto mt-lg-10 mt-8">
					<Link :href="$route('account.overview')">
						<button class="btn-underline-secondary">
							<i class="fa-regular fa-chevron-left text-primary mb-n1 me-3"></i>
							<span>Back</span>
						</button>
					</Link>
				</div>
			</div>
		</section>
	</ClientLayout>
</template>

<script setup>
import ClientLayout from '@layouts/ClientLayout.vue';
import Breadcrumbs from '@/Components/Breadcrumbs.vue';
import ImpersonationBanner from "@js/Components/ImpersonationBanner.vue";
import { Head, Link, usePage } from '@inertiajs/vue3';
import { computed, ref } from "vue";

const props = defineProps({
	'orders' : Object
});
const is_impersonating = computed(() => usePage().props.global.is_impersonating);
const quotes = ref(props.orders);
</script>
