<template>
	<section id="impersonation-banner" class="bg-quaternary py-2 px-xl-16 px-lg-8 px-4" v-if="props.impersonating">
		<div class="d-flex align-items-center">
			<h5 class="text-start text-tertiary mb-0 me-4">You are now impersonating <span class="fw-bold">{{ current_user }}</span></h5>
			<Link :href="route('impersonate.end')" class="d-block ms-auto">
				<button class="btn-tertiary text-primary pt-2 pb-1 px-lg-12 px-md-8">
					<span>
						Stop <span class="d-md-inline d-none">Impersonating</span> <span class="d-xl-inline d-none">{{ current_user }}</span>
					</span>
				</button>
			</Link>
		</div>
	</section>
</template>

<script setup>
import {computed} from  'vue';
import { usePage, Link } from "@inertiajs/vue3";

const props = defineProps({
	'impersonating': Boolean
});

const current_user = computed(() => {
	// fn gets the current user's name and strips the comma from the end for display purposes
	let user = usePage().props.global.current_user;
	user = user.substring(0, user.length - 1);
	console.log(user);
	return user;
});

</script>